import * as React from 'react';
import useData from '@/wpt-lib/pdf/useData';
import { Row, Table, ValueCell } from '@/wpt-lib/pdf/common/Table';
import { generateHistoryComment } from '@/wpt-lib/interfaces/Workplan';
import SubHeading from '@/wpt-lib/pdf/common/SubHeading';
import { formatName } from '@/wpt-lib/utils/formatName';

const StateHistory = () => {
    const { stateHistory, version } = useData();

    return (
        <Table cols={3}>
            <Row>
                <SubHeading level={2} text="Change History" />
            </Row>
            {stateHistory
                .filter((state) => state.version <= version.version)
                .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
                .map((state, i) => (
                    <Row key={i} widths={[15, 15, 70]}>
                        <ValueCell label="Date" value={state.createdAt} format="date" />
                        <ValueCell label="By" value={formatName(state.assignment?.user)} />
                        <ValueCell label="Change" value={generateHistoryComment(state)} />
                    </Row>
                ))}
        </Table>
    );
};

export default StateHistory;
