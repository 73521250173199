import React, { useLayoutEffect, useState } from 'react';
import { ProgressUpdateDialog } from '@/components/pages/workplan/workplanItemAction/ProgressUpdateDialog';
import { Box, Button, Text } from '@chakra-ui/react';
import { Action, Column, ListTable } from '@/components/pages/dashboard/tiles/bottomTile/bottomTileTables/ListTable';
import { TableTopToolBar } from '@/components/Table/TableTopToolBar';
import Icons from '@/components/icons/Icons';
import { PaginationToolbar, TableSkeletonLoading } from '@/components/Table';
import { defaultTableParams } from '@/utils/tableUtils';
import { useDeleteRequest, useGetRequest } from '@/utils/useStatefulRequests';
import { ListResponse } from '@/wpt-lib/interfaces/listResponse';
import { WorkplanItemProgressUpdate } from '@/wpt-lib/interfaces/WorkplanItemProgressUpdate';
import { DeleteRowAlert } from '@/components/Table/DeleteRowAlert';
import { SimpleAlert } from '@/components/SimpleAlert/SimpleAlert';
import { useAssignment } from '@/components/contexts/AssignmentContext/useAssignment';
import { progressUpdateStatus } from '@/wpt-lib/interfaces/ProgressUpdate';
import { Workplan } from '@/wpt-lib/interfaces/Workplan';

const columns: Column<WorkplanItemProgressUpdate>[] = [
    {
        name: 'Created',
        field: 'createdAt',
        nowrap: true,
        highlight: (item: WorkplanItemProgressUpdate) => item.changed,
        getData: (item: WorkplanItemProgressUpdate) => {
            return new Date(item.createdAt).toLocaleDateString('pt-PT');
        },
    },
    {
        name: 'Status',
        field: 'status',
        nowrap: true,
        getData: (item: WorkplanItemProgressUpdate) => progressUpdateStatus[item.status],
    },
    {
        name: 'Update',
        field: 'description',
        noOfLines: 2,
        getData: (item: WorkplanItemProgressUpdate) => item.description,
    },
];

interface ProgressUpdateTileProp {
    isReadonly: boolean;
    workplanItemId: string;
    workplan: Workplan;
    version?: number;
}

export const ProgressUpdateTile = ({ isReadonly, workplanItemId, version }: ProgressUpdateTileProp) => {
    const { currentAssignment } = useAssignment();
    const [createProgressUpdatePopup, setCreateProgressUpdatePopup] = useState(false);
    const [pageSize, setPageSize] = useState(defaultTableParams.pageSize);
    const [page, setPage] = useState(defaultTableParams.page);
    const [pages, setPages] = useState(1);
    const [deleteAlert, setDeleteAlert] = useState(false);
    const [rowToDelete, setDeletedRow] = useState<WorkplanItemProgressUpdate | undefined>(undefined);
    const [rowToEdit, setEditedRow] = useState<WorkplanItemProgressUpdate | undefined>(undefined);
    const [editProgressUpdatePopup, setEditProgressUpdatePopup] = useState(false);

    const [orderByColumn, setOrderByColumn] = useState<string>(defaultTableParams.orderByColumn);
    const [orderByOrientation, setOrderByOrientation] = useState<string>(defaultTableParams.orderByOrientation);

    const [focusPage, setFocusPage] = useState(false);

    const {
        makeRequest: makeGetRequest,
        error: listError,
        loading: isGettingProgressUpdates,
        responseData: listResponse,
    } = useGetRequest<ListResponse<WorkplanItemProgressUpdate>>();

    const {
        makeRequest: makeDeleteRequest,
        error: deleteError,
        loading: isDeleting,
    } = useDeleteRequest<WorkplanItemProgressUpdate>();

    const deleteProgressUpdate = async (progressUpdate: WorkplanItemProgressUpdate): Promise<boolean> => {
        try {
            await makeDeleteRequest(`/progress-update/${progressUpdate.id}`, {
                params: {
                    assignment: currentAssignment?.id,
                },
            });
            await getProgressUpdate();
            return true;
        } catch (e) {
            //handled by hook
            return false;
        }
    };

    const setDeleteConfirmation = (row: WorkplanItemProgressUpdate) => {
        setDeletedRow(row);
        setDeleteAlert(true);
    };

    const getProgressUpdateName = (row: WorkplanItemProgressUpdate | undefined) => {
        if (row) {
            return `Progress update`;
        }
        return undefined;
    };
    const setEditPopup = (row: WorkplanItemProgressUpdate) => {
        setEditedRow(row);
        setEditProgressUpdatePopup(true);
    };
    const getProgressUpdate = async (newPage?: number, newOrderByColumn?: string) => {
        try {
            const params = {
                version: version,
                page: newPage ?? page,
                orderByColumn: newOrderByColumn ?? orderByColumn,
                workplanItem: workplanItemId,
                orderByOrientation: orderByOrientation,
                pageSize: pageSize,
            };
            const res = await makeGetRequest(`/progress-update`, { params });
            setPages(res.data.info.pages);
            setPage(res.data.info.page);
        } catch (e) {
            //handled by hook
        }
    };

    useLayoutEffect(() => {
        getProgressUpdate();
    }, [pageSize, page, orderByOrientation, orderByColumn]);

    const convertStringToDate = (createdDate: string) => {
        const formatDate = new Date(createdDate);
        const day = formatDate.toLocaleString('default', { day: 'numeric' });
        const month = formatDate.toLocaleString('default', {
            month: 'long',
        });
        const year = formatDate.toLocaleString('default', {
            year: 'numeric',
        });
        return `${day} ${month} ${year}`;
    };

    const getActions = (item: WorkplanItemProgressUpdate): Action<WorkplanItemProgressUpdate>[] => {
        if (isReadonly || item.approved) {
            return [];
        }
        return [
            {
                id: 'edit',
                event: setEditPopup,
                icon: <Icons.EditIcon />,
                label: 'Edit',
                ariaLabel: `Edit ${convertStringToDate(item.createdAt)} progress update`,
            },
            {
                id: 'delete',
                event: setDeleteConfirmation,
                icon: <Icons.DeleteIcon />,
                label: 'Delete',
                ariaLabel: `Delete ${convertStringToDate(item.createdAt)} progress update`,
            },
        ];
    };

    if (isGettingProgressUpdates) {
        return <TableSkeletonLoading noOfCols={3} noOfRows={5} />;
    }

    if (listError) {
        return (
            <SimpleAlert variant={'left-accent'}>
                <Text>Cannot load recent progress update, please refresh your page to try again</Text>
            </SimpleAlert>
        );
    }

    return (
        <Box>
            <ProgressUpdateDialog
                dialogDefaultState={'edit'}
                selectedProgressUpdate={rowToEdit}
                workplanItemId={workplanItemId}
                getProgressUpdate={getProgressUpdate}
                isOpen={editProgressUpdatePopup}
                setIsOpen={setEditProgressUpdatePopup}
                currentAssignment={currentAssignment?.id}
            />
            <ProgressUpdateDialog
                dialogDefaultState={'create'}
                selectedProgressUpdate={rowToEdit}
                workplanItemId={workplanItemId}
                getProgressUpdate={getProgressUpdate}
                isOpen={createProgressUpdatePopup}
                setIsOpen={setCreateProgressUpdatePopup}
                currentAssignment={currentAssignment?.id}
            />
            <DeleteRowAlert
                deleteAction={deleteProgressUpdate}
                deleteError={deleteError}
                isOpen={deleteAlert}
                isDeleting={isDeleting}
                rowToDelete={rowToDelete}
                onClose={() => setDeleteAlert(false)}
                recordName={getProgressUpdateName(rowToDelete)}
            />
            <Box>
                <TableTopToolBar
                    listResponse={listResponse}
                    loading={isGettingProgressUpdates}
                    columns={columns}
                    orderByColumn={orderByColumn}
                    orderByOrientation={orderByOrientation}
                    setOrderByColumn={setOrderByColumn}
                    setOrderByOrientation={setOrderByOrientation}
                >
                    {!isReadonly && (
                        <Button
                            h={12}
                            whiteSpace={'normal'}
                            colorScheme={'blue'}
                            onClick={() => setCreateProgressUpdatePopup(true)}
                            display={'flex'}
                            gridGap={2}
                        >
                            <Text display={{ base: 'none', md: 'block' }}>Add Progress Update</Text>
                        </Button>
                    )}
                </TableTopToolBar>
                <ListTable
                    tableCaption={`List of progress updates (page ${page + 1} of ${pages === 0 ? 1 : pages})`}
                    columns={columns}
                    data={listResponse?.items}
                    getActions={getActions}
                />
                <PaginationToolbar
                    setPageSize={setPageSize}
                    setNewPage={setPage}
                    pageSize={pageSize}
                    pages={pages}
                    page={page}
                    focusPage={focusPage}
                    setFocusPage={setFocusPage}
                />
            </Box>
        </Box>
    );
};
