import * as React from 'react';
import { Row, ValueCell } from '@/wpt-lib/pdf/common/Table';
import { useLabel } from '@/wpt-lib/pdf/useData';

const PepConsideration = ({
    pepStartDate,
    pepEndDate,
    pepConfirmed,
    pepOverview,
}: Partial<{
    pepStartDate: string;
    pepEndDate: string;
    pepConfirmed: string;
    pepOverview: string;
}>) => (
    <>
        <Row>
            <ValueCell label="Start Date" value={pepStartDate} />
            <ValueCell label="End Date" value={pepEndDate} />
            <ValueCell label="Confirmed" value={useLabel('pepConfirmed', pepConfirmed ?? 'n/a')} />
        </Row>
        <Row>
            <ValueCell label="Overview" value={pepOverview} />
        </Row>
    </>
);

export default PepConsideration;
