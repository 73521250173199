import React from 'react';
import { HorizontalLayout, LayoutProps, RankedTester, rankWith, uiTypeIs } from '@jsonforms/core';
import { ChakraLayoutRenderer, ChakraLayoutRendererProps } from './ChakraLayout';
import configuredAreEqual from '../utils/configuredAreEqual';
import { withNamedJsonFormsLayoutProps } from '@/forms-renderers/chakra-ui/utils/withNamedJsonFormsHOC';
import { namedMemo } from '@/utils/namedMemo';

export const ChakraHorizontalLayoutRenderer = namedMemo(
    'ChakraHorizontalLayoutRenderer',
    ({ uischema, schema, path, enabled, visible, renderers, cells }: LayoutProps) => {
        const layout = uischema as HorizontalLayout;
        const uischemaOption = uischema.options;
        const childProps: ChakraLayoutRendererProps = {
            elements: layout.elements,
            schema,
            path,
            columns: uischemaOption?.columns ?? layout.elements.length,
            rows: uischemaOption?.rows ?? 1,
            autoRows: uischemaOption?.autoRows,
            width: uischemaOption?.w,
            gap: uischemaOption?.gap,
            enabled,
            visible,
        };

        return <ChakraLayoutRenderer {...childProps} renderers={renderers} cells={cells} />;
    },
    configuredAreEqual({ ignore: ['data'] }),
);

export const chakraHorizontalLayoutTester: RankedTester = rankWith(2, uiTypeIs('HorizontalLayout'));

export default withNamedJsonFormsLayoutProps(ChakraHorizontalLayoutRenderer);
