import { createEvent, EventAttributes } from 'ics';
import React from 'react';
import { IconButton, Tooltip } from '@chakra-ui/react';
import { DownloadIcon } from '@chakra-ui/icons';

interface CalenderDownloadProps {
    dateValue: string;
    title: string;
    description: string;
    duration?: { hours: number; minutes: number };
}
export const CalendarDownload: React.FC<CalenderDownloadProps> = (props: CalenderDownloadProps) => {
    async function downloadMeetingDateToICSFile() {
        const event: EventAttributes = {
            start: [
                parseInt(props.dateValue.substring(0, 4)),
                parseInt(props.dateValue.substring(5, 7)),
                parseInt(props.dateValue.substring(8, 10)),
            ],
            title: props.title,
            description: props.description,
            duration: props.duration ? props.duration : { hours: 24 },
        };

        async function handleDownload() {
            const filename = `${props.title}.ics`;
            const file = await new Promise<File>((resolve, reject) => {
                createEvent(event, (error, value) => {
                    if (error) {
                        reject(error);
                    }
                    resolve(new File([value], filename, { type: 'text/calendar' }));
                });
            });
            const url = URL.createObjectURL(file);

            // trying to assign the file URL to a window could cause cross-site
            // issues so this is a workaround using HTML5
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = filename;

            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);

            URL.revokeObjectURL(url);
        }
        await handleDownload();
    }

    return (
        <Tooltip label="Download Meeting">
            <IconButton
                variant="solid"
                aria-label="Download Meeting"
                fontSize="15px"
                icon={<DownloadIcon />}
                onClick={() => downloadMeetingDateToICSFile()}
            />
        </Tooltip>
    );
};
