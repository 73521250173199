import React from 'react';
import { CellProps, WithClassname } from '@jsonforms/core';
import merge from 'lodash/merge';
import { TextareaProps } from '@chakra-ui/react';
import { namedMemo } from '@/utils/namedMemo';
import AutoExpandTextArea from '@/components/pages/workplan/comment/AutoExpandTextArea';

export const ChakraTextarea = namedMemo('ChakraTextarea', (props: CellProps & WithClassname) => {
    const { data, config, className, id, enabled, uischema, isValid, path, handleChange, schema } = props;
    const { maxLength, minLength } = schema;
    const appliedUiSchemaOptions = merge({}, config, uischema.options);
    let textareaProps: TextareaProps;
    if (appliedUiSchemaOptions.restrict) {
        textareaProps = { minLength, maxLength };
    } else {
        textareaProps = {};
    }

    return (
        <AutoExpandTextArea
            data={data}
            {...textareaProps}
            id={id}
            isReadOnly={appliedUiSchemaOptions.readonly || !enabled}
            value={data ?? ''}
            autoFocus={appliedUiSchemaOptions.focus}
            className={className}
            isInvalid={!isValid}
            onChange={(v: string) => {
                if (enabled) handleChange(path, v || undefined);
            }}
        />
    );
});
