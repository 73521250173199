import { VStack, FormHelperText } from '@chakra-ui/react';
import { ControlElement, UISchemaElement } from '@jsonforms/core';
import { JsonFormsDispatch } from '@jsonforms/react';
import React from 'react';

interface FormHelperTextProps {
    uischema: ControlElement;
}
const FormHelpText = ({ uischema }: FormHelperTextProps) => {
    const descriptionElements = uischema.options?.descriptionElements;
    const showDescription = descriptionElements && descriptionElements.length !== 0;
    return (
        <FormHelperText hidden={!showDescription} m={0}>
            <VStack spacing={2} alignItems={'flex-start'}>
                {Array.isArray(descriptionElements) &&
                    descriptionElements.map((desc: UISchemaElement, i: number) => {
                        return <JsonFormsDispatch uischema={desc} key={i} />;
                    })}
            </VStack>
        </FormHelperText>
    );
};

export default FormHelpText;
