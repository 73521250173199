import * as React from 'react';
import Page from '@/wpt-lib/pdf/common/Page';
import Heading from '@/wpt-lib/pdf/common/Heading';
import useData, { useConsideration } from '@/wpt-lib/pdf/useData';
import { ConsiderationType } from '@/wpt-lib/interfaces/Consideration';
import PepConsideration from '@/wpt-lib/pdf/considerations/PepConsideration';
import OpportunityConsideration from '@/wpt-lib/pdf/considerations/OpportunityConsiderations';
import OutsideWorkConsideration from '@/wpt-lib/pdf/considerations/OutsideWorkConsiderations';
import DisclosureOfInterestConsideration from '@/wpt-lib/pdf/considerations/DisclosureOfInterestConsidration';
import OtherConsideration from '@/wpt-lib/pdf/considerations/OtherConsiderations';
import { StyleSheet, View } from '@react-pdf/renderer';
import ProgressUpdates from '@/wpt-lib/pdf/ProgressUpdates';
import { Row, Table, ValueCell } from '@/wpt-lib/pdf/common/Table';
import Comments from '@/wpt-lib/pdf/Comments';
import { getFormTypeKeys } from '@/wpt-lib/utils/formData';
import SectionComments from '@/wpt-lib/pdf/SectionComments';
import { WorkplanCommentSection } from '@/wpt-lib/interfaces/WorkplanComment';
import SubHeading from '@/wpt-lib/pdf/common/SubHeading';

const ConsiderationTitles: { [type in ConsiderationType]: string } = {
    [ConsiderationType.PEP]: 'PEP Planning',
    [ConsiderationType.OPPORTUNITY]: 'Subject To Opportunity',
    [ConsiderationType.OUTSIDE_WORK]: 'Outside Work',
    [ConsiderationType.CONFLICTS_OF_INTEREST_DECLARATION]: 'Conflicts of Interest Declaration',
    [ConsiderationType.OTHER]: 'Other',
};

// eslint-disable-next-line
const ConsiderationComponents: { [type in ConsiderationType]: React.ComponentType<any> } = {
    [ConsiderationType.PEP]: PepConsideration,
    [ConsiderationType.OPPORTUNITY]: OpportunityConsideration,
    [ConsiderationType.OUTSIDE_WORK]: OutsideWorkConsideration,
    [ConsiderationType.CONFLICTS_OF_INTEREST_DECLARATION]: DisclosureOfInterestConsideration,
    [ConsiderationType.OTHER]: OtherConsideration,
};
const Considerations = () => {
    const considerations = useConsideration().sort(
        (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
    );

    const comments = useData().comments.filter((c) => c.section === WorkplanCommentSection.CONSIDERATION);

    return (
        <>
            {considerations.map((consideration, k) => (
                <Page key={k}>
                    {k === 0 && <Heading text="Other Considerations" />}
                    <View style={styles.type}>
                        <Table cols={2}>
                            <Row>
                                <ValueCell
                                    label={`Consideration ${k + 1}`}
                                    value={'' + consideration.data.considerationTitle}
                                    fontSize={12}
                                    bg="#565656"
                                    color="white"
                                />
                            </Row>
                            {getFormTypeKeys(consideration.data.considerationType).map((type, j) => (
                                <View key={j}>
                                    <Row>
                                        <SubHeading level={1} text={ConsiderationTitles[type as ConsiderationType]} />
                                    </Row>

                                    {type in ConsiderationComponents &&
                                        React.createElement(ConsiderationComponents[type as ConsiderationType], {
                                            index: k + 1,
                                            ...consideration.data,
                                        })}
                                </View>
                            ))}
                            <ProgressUpdates item={consideration.workplanItem} />

                            <Comments comments={consideration.workplanItem.comments} />
                        </Table>
                    </View>
                </Page>
            ))}
            {comments.length > 0 && (
                <Page>
                    <SectionComments heading="Other Considerations Comments" comments={comments} />
                </Page>
            )}
        </>
    );
};

const styles = StyleSheet.create({
    type: {
        marginBottom: 20,
    },
});

export default Considerations;
