import { Row, Table, ValueCell } from '@/wpt-lib/pdf/common/Table';
import * as React from 'react';
import { WorkplanVersion } from '@/wpt-lib/interfaces/Workplan';
import SubHeading from '@/wpt-lib/pdf/common/SubHeading';

const Declarations = ({
    plannedLeaveDeclaration,
    conflictOfInterestDeclaration,
}: Pick<WorkplanVersion, 'plannedLeaveDeclaration' | 'conflictOfInterestDeclaration'>) => {
    return (
        <Table cols={1}>
            <Row>
                <SubHeading level={2} text="Declarations" />
            </Row>
            <Row>
                <ValueCell
                    label="Planned Leave"
                    value={
                        plannedLeaveDeclaration
                            ? 'I have discussed my leave plans with my supervisor for this workplanning year.'
                            : 'I have not discussed my leave plans with my supervisor for this workplanning year.'
                    }
                />
            </Row>
            {conflictOfInterestDeclaration && (
                <Row>
                    <ValueCell
                        label={'Conflict of Interest Declaration'}
                        value={
                            conflictOfInterestDeclaration
                                ? 'I understand and have declared my conflicts of interest via the Declaration of Interest form from the Speak Up website.'
                                : 'I understand and have no interest to declare'
                        }
                    />
                </Row>
            )}
        </Table>
    );
};

export default Declarations;
