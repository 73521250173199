import React, { useEffect, useRef, useState } from 'react';
import { Box, Text, TextProps } from '@chakra-ui/react';
import { useSize } from '@chakra-ui/react-use-size';

interface ExpandableTextInterface {
    text?: string | number;
    noOfLines: TextProps['noOfLines'];
}

export const ExpandableText = ({ text, noOfLines }: ExpandableTextInterface) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [, setNumOfLines] = useState(noOfLines);
    const fullSizeTextRef = useRef<HTMLParagraphElement | null>(null);
    const truncatedTextRef = useRef<HTMLParagraphElement | null>(null);
    const fullSizeTextRefDimension = useSize(fullSizeTextRef);
    const truncatedTextRefDimension = useSize(truncatedTextRef);
    const [offscreen, setOffscreen] = useState(0);

    useEffect(() => {
        if (truncatedTextRef.current?.children) {
            const contain = truncatedTextRef.current?.getBoundingClientRect();
            setOffscreen(
                Array.from(truncatedTextRef.current.children).findIndex((span) => {
                    const bounds = span.getBoundingClientRect();
                    return bounds.top > contain.bottom;
                }),
            );
        }
    }, [truncatedTextRefDimension]);

    const expandText = (isExpanded: boolean) => {
        setIsExpanded(isExpanded);
        setNumOfLines(isExpanded ? undefined : noOfLines);
    };

    return (
        <Box position={'relative'}>
            <Box position="relative">
                <Text>
                    {isExpanded ? (
                        text
                    ) : (
                        <>
                            {text &&
                            truncatedTextRefDimension &&
                            fullSizeTextRefDimension &&
                            truncatedTextRefDimension.height !== fullSizeTextRefDimension.height ? (
                                <>{`${text}`.slice(0, offscreen - 3)}...</>
                            ) : (
                                <>{text}</>
                            )}
                        </>
                    )}
                    &nbsp;
                    {text &&
                        truncatedTextRefDimension &&
                        fullSizeTextRefDimension &&
                        truncatedTextRefDimension.height !== fullSizeTextRefDimension.height && (
                            <Text
                                as={'span'}
                                fontWeight="semibold"
                                color="blue.500"
                                cursor="pointer"
                                onClick={() => expandText(!isExpanded)}
                            >
                                {isExpanded ? 'LESS' : 'MORE'}
                            </Text>
                        )}
                </Text>
            </Box>

            <Text position="absolute" top="0" visibility={'hidden'} noOfLines={noOfLines} ref={truncatedTextRef}>
                <Text as={'span'} fontWeight="semibold" color="blue.500" cursor="pointer" paddingRight={10}>
                    {isExpanded ? 'LESS' : 'MORE'}
                </Text>
                {`${text}`.split('').map((s, i) => (
                    <span key={i}>{s}</span>
                ))}
            </Text>
            <Text position="absolute" visibility={'hidden'} ref={fullSizeTextRef} top="0">
                <Text as={'span'} fontWeight="semibold" color="blue.500" cursor="pointer" paddingRight={10}>
                    {isExpanded ? 'LESS' : 'MORE'}
                </Text>
                {text}
            </Text>
        </Box>
    );
};
