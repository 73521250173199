import { Row, ValueCell } from '@/wpt-lib/pdf/common/Table';
import * as React from 'react';

const General = ({
    keyPerformanceIndicatorsGeneral,
}: Partial<{
    keyPerformanceIndicatorsGeneral: string;
}>) => (
    <>
        {keyPerformanceIndicatorsGeneral && (
            <Row>
                <ValueCell label="Key Performance Indicators (KPI's)" value={keyPerformanceIndicatorsGeneral} />
            </Row>
        )}
    </>
);

export default General;
