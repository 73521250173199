import React from 'react';
import { IconButton, Menu, MenuButton, MenuItem, Tooltip, MenuList, Box } from '@chakra-ui/react';
import Icons from '@/components/icons/Icons';
import { Comment } from '@/wpt-lib/interfaces/Comment';

interface SingleCommentOptionsProps<T> {
    comment: T;
    onDelete?: (comment: T) => void;
    onEdit?: (comment: T) => void;
}

export const SingleCommentOptions = <T extends Comment>({
    comment,
    onDelete,
    onEdit,
}: SingleCommentOptionsProps<T>) => {
    return (
        // wrap menu in Box to prevent popper.js issue
        // https://github.com/chakra-ui/chakra-ui/issues/3173#issuecomment-981403435
        <Box>
            <Menu>
                <Tooltip label={'Options'}>
                    <MenuButton
                        aria-label={`Comment Options`}
                        as={IconButton}
                        bg={'transparent'}
                        icon={<Icons.MoreVerticalIcon />}
                        marginTop={-20}
                        marginBottom={-20}
                    />
                </Tooltip>
                <MenuList>
                    {onEdit && <MenuItem onClick={() => onEdit(comment)}>Edit</MenuItem>}
                    {onDelete && <MenuItem onClick={() => onDelete(comment)}>Delete</MenuItem>}
                </MenuList>
            </Menu>
        </Box>
    );
};
