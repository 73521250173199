import { Flex, Spinner } from '@chakra-ui/react';
import React from 'react';
interface LoadingSpinnerProps {
    fullScreen?: boolean;
}
export const LoadingSpinner = (props: LoadingSpinnerProps) => {
    return (
        <Flex h={props.fullScreen ? '85vh' : ''} w="full" justifyContent="center" alignItems="center">
            <Spinner size={'xl'} />
        </Flex>
    );
};
