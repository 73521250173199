import React from 'react';
import { LayoutProps, RankedTester, rankWith, uiTypeIs } from '@jsonforms/core';
import { Heading, TextProps, TypographyProps } from '@chakra-ui/react';
import { LabelElement } from '@jsonforms/core/src/models/uischema';
import { withNamedJsonFormsLayoutProps } from '@/forms-renderers/chakra-ui/utils/withNamedJsonFormsHOC';
import merge from 'lodash/merge';
import { namedMemo } from '@/utils/namedMemo';

interface LabelOptions extends TypographyProps, TextProps {}

export const SectionHeadingRenderer = namedMemo('ChakraLabelRenderer', ({ uischema, visible, config }: LayoutProps) => {
    const labelElement = uischema as LabelElement;

    const defaultOptions: LabelOptions = {
        fontSize: 'lg',
        fontStyle: 'normal',
        fontWeight: undefined,
        textAlign: 'left',
        backgroundColor: '#dedede',
        width: '100%',
        padding: '2',
    };
    const appliedUiSchemaOptions: LabelOptions = merge(defaultOptions, config, uischema.options);

    if (visible) {
        return (
            <Heading
                as="h3"
                fontSize={appliedUiSchemaOptions.fontSize}
                fontWeight={appliedUiSchemaOptions.fontWeight}
                fontStyle={appliedUiSchemaOptions.fontStyle}
                textAlign={appliedUiSchemaOptions.textAlign}
                color={appliedUiSchemaOptions.color}
                backgroundColor={appliedUiSchemaOptions.backgroundColor}
                width={appliedUiSchemaOptions.width}
                padding={appliedUiSchemaOptions.padding}
            >
                {labelElement.text}
            </Heading>
        );
    } else {
        return null;
    }
});

export const sectionHeadingRendererTester: RankedTester = rankWith(1, uiTypeIs('SectionHeading'));

export default withNamedJsonFormsLayoutProps(SectionHeadingRenderer);
