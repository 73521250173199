import React from 'react';
import {
    and,
    ControlProps,
    isBooleanControl,
    isOneOfEnumControl,
    optionIs,
    OwnPropsOfEnum,
    RankedTester,
    rankWith,
} from '@jsonforms/core';
import { withNamedJsonFormsOneOfEnumProps } from '@//forms-renderers/chakra-ui/utils/withNamedJsonFormsHOC';
import { ChakraOneOfRadioGroupControl } from '@/forms-renderers/chakra-ui/controls/ChakraOneOfRadioGroupControl';

export const ChakraOneOfBooleanRadioGroupControl = (props: ControlProps & OwnPropsOfEnum) => {
    return (
        <ChakraOneOfRadioGroupControl
            {...props}
            handleChange={(path, value) => {
                props.handleChange(path, value === 'true');
            }}
        />
    );
};

export const chakraOneOfBooleanRadioGroupControlTester: RankedTester = rankWith(
    7,
    and(isOneOfEnumControl, optionIs('format', 'radio'), isBooleanControl),
);

export default withNamedJsonFormsOneOfEnumProps(ChakraOneOfBooleanRadioGroupControl);
