import { isNullish } from '@/wpt-lib/utils/isNullish';
import { namedMemo } from '@/utils/namedMemo';
import { Select } from '@chakra-ui/react';
import { EnumCellProps, WithClassname } from '@jsonforms/core';
import merge from 'lodash/merge';
import React, { useLayoutEffect, useMemo } from 'react';
import { ChakraInputText } from '../chakra-controls';

export const ChakraSelect = namedMemo('ChakraSelect', (props: EnumCellProps & WithClassname) => {
    const { data, config, className, id, enabled, uischema, isValid, path, handleChange, options } = props;
    const appliedUiSchemaOptions = merge({}, config, uischema.options);

    if (appliedUiSchemaOptions.readonly) {
        const selectedOption = options?.find((option) => option.value === data);
        return <ChakraInputText {...props} data={selectedOption?.label ?? 'None'} />;
    }

    useLayoutEffect(() => {
        if (uischema.options && uischema.options?.default && !appliedUiSchemaOptions.readonly) {
            if (isNullish(data) && options?.find((option) => option.value === uischema.options?.default)) {
                handleChange(path, uischema.options.default);
            }
        }
    }, [path]);

    const filteredOptions = useMemo(() => {
        if (appliedUiSchemaOptions?.dependencyValue && appliedUiSchemaOptions?.optionsByDependency) {
            const dependencyValue = appliedUiSchemaOptions.dependencyValue;
            const optionsByDependency = appliedUiSchemaOptions.optionsByDependency;

            if (optionsByDependency[dependencyValue]) {
                return options?.filter((option) => optionsByDependency[dependencyValue].includes(option.value));
            }
        }

        return options;
    }, [appliedUiSchemaOptions?.dependencyValue, appliedUiSchemaOptions?.optionsByDependency, options]);

    return (
        <Select
            id={id}
            value={data ?? ''}
            isReadOnly={!enabled || appliedUiSchemaOptions.readonly}
            autoFocus={appliedUiSchemaOptions.focus}
            placeholder={appliedUiSchemaOptions.placeholder}
            className={className}
            isInvalid={!isValid}
            onChange={(ev) => handleChange(path, ev.target.value)}
            role="listbox"
        >
            {filteredOptions?.map((option) => (
                <option value={option.value} key={option.value} style={{ background: 'unset' }}>
                    {option.label}
                </option>
            ))}
        </Select>
    );
});
