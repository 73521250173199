import React, { ChangeEventHandler, Ref, useCallback } from 'react';
import { CellProps, WithClassname } from '@jsonforms/core';
import merge from 'lodash/merge';
import { Input, InputProps } from '@chakra-ui/react';
import { namedMemo } from '@/utils/namedMemo';

interface ChakraInputTextProps extends CellProps, WithClassname {
    inputProps?: InputProps & { ref?: Ref<HTMLInputElement> };
}

export const ChakraInputText = namedMemo('ChakraInputText', (props: ChakraInputTextProps) => {
    const { data, config, className, id, enabled, uischema, isValid, path, handleChange, schema, inputProps } = props;
    const { maxLength, minLength } = schema;
    const appliedUiSchemaOptions = merge({}, config, uischema.options);
    const additionalInputProps: InputProps = {};
    if (appliedUiSchemaOptions.restrict) {
        additionalInputProps.minLength = minLength;
        additionalInputProps.maxLength = maxLength;
    }

    const renderInputType = (type: typeof appliedUiSchemaOptions.format) => {
        switch (type) {
            case 'password':
                return 'password';
            case 'date':
                return 'date';
            default:
                return 'text';
        }
    };
    const onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
        (ev) => handleChange(path, ev.target.value || undefined),
        [handleChange, path],
    );

    return (
        <Input
            onChange={onChange}
            {...inputProps}
            {...additionalInputProps}
            id={id}
            value={data ?? ''}
            type={renderInputType(appliedUiSchemaOptions.format)}
            placeholder={appliedUiSchemaOptions.placeholder}
            autoFocus={appliedUiSchemaOptions.focus}
            isReadOnly={appliedUiSchemaOptions.readonly || !enabled}
            className={className}
            isInvalid={!isValid}
        />
    );
});
