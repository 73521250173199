import React from 'react';
import { GroupLayout, LayoutProps, RankedTester, rankWith, uiTypeIs } from '@jsonforms/core';
import { ChakraLayoutRenderer, ChakraLayoutRendererProps } from './ChakraLayout';
import { Box, VStack } from '@chakra-ui/react';
import { withNamedJsonFormsLayoutProps } from '@/forms-renderers/chakra-ui/utils/withNamedJsonFormsHOC';
import { namedMemo } from '@/utils/namedMemo';
import configuredAreEqual from '../utils/configuredAreEqual';
import { ChakraHeading } from '@/forms-renderers/chakra-ui/additional/Labels/ChakraHeadingRenderer';

export const ChakraGroupLayoutRenderer = namedMemo(
    'ChakraGroupLayoutRenderer',
    ({ uischema, schema, path, enabled, visible, renderers, cells }: LayoutProps) => {
        const layout = uischema as GroupLayout;
        const uischemaOption = uischema.options;

        const childProps: ChakraLayoutRendererProps = {
            elements: layout.elements,
            width: uischemaOption?.w,
            columns: uischemaOption?.columns,
            rows: uischemaOption?.rows,
            autoRows: uischemaOption?.autoRows,
            gap: uischemaOption?.gap,
            schema,
            path,
            enabled,
            visible,
        };
        if (visible) {
            const Wrapper = uischemaOption?.noGrow ? Box : React.Fragment;
            return (
                <Wrapper>
                    <VStack
                        alignItems="flex-start"
                        justifyContent="center"
                        h={uischemaOption?.noGrow ? undefined : '100%'}
                        padding={uischemaOption?.padding ?? 0}
                    >
                        {layout.label && (
                            <ChakraHeading level={uischemaOption?.headingLevel || 2}>{layout.label}</ChakraHeading>
                        )}
                        <ChakraLayoutRenderer {...childProps} renderers={renderers} cells={cells} />
                    </VStack>
                </Wrapper>
            );
        } else {
            return null;
        }
    },
    configuredAreEqual({ ignore: ['data'] }),
);

export const chakraGroupLayoutTester: RankedTester = rankWith(1, uiTypeIs('Group'));

export default withNamedJsonFormsLayoutProps(ChakraGroupLayoutRenderer);
