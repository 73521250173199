import { JsonFormsCellRendererRegistryEntry, JsonFormsRendererRegistryEntry } from '@jsonforms/core';
import {
    ChakraBooleanControl,
    chakraBooleanControlTester,
    ChakraTextControl,
    chakraTextControlTester,
    ChakraTextareaControl,
    chakraTextareaControlTester,
    ChakraDateControl,
    chakraDateControlTester,
    ChakraEnumControl,
    chakraEnumControlTester,
    ChakraOneOfEnumControl,
    chakraOneOfEnumControlTester,
    chakraRadioGroupControlTester,
    ChakraRadioGroupControl,
    chakraOneOfRadioGroupControlTester,
    ChakraOneOfRadioGroupControl,
    chakraOneOfBooleanRadioGroupControlTester,
    ChakraOneOfBooleanRadioGroupControl,
    chakraBooleanToggleControlTester,
    ChakraBooleanToggleControl,
    chakraDecimalControlTester,
    ChakraDecimalControl,
    chakraIntegerControlTester,
    ChakraIntegerControl,
    chakraTableControlTester,
    ChakraTableControl,
    ChakraResearchMasterAutoCompleteControl,
    chakraResearchMasterAutoCompletedTester,
    chakraStaffListingAutoCompleteTester,
    chakraOpenCourseAutoCompleteTester,
    ChakraOpenCourseAutoCompletionControl,
    chakraSubjectAutoCompleteTester,
    ChakraSubjectAutoCompletionControl,
} from './controls';
import {
    ChakraBooleanCell,
    chakraBooleanCellTester,
    ChakraBooleanToggleCell,
    chakraBooleanToggleCellTester,
    ChakraDecimalCell,
    chakraDecimalCellTester,
    ChakraIntegerCell,
    chakraIntegerCellTester,
    ChakraEnumCell,
    chakraEnumCellTester,
    ChakraTextCell,
    chakraTextCellTester,
} from './cells';
import {
    ChakraHorizontalLayout,
    chakraHorizontalLayoutTester,
    ChakraVerticalLayout,
    chakraVerticalLayoutTester,
    ChakraGroupLayout,
    chakraGroupLayoutTester,
    chakraCategorisationLayoutTester,
    ChakraCategorisationLayout,
} from './layouts';
import {
    ChakraLabelRenderer,
    chakraLabelRendererTester,
    ChakraTooltipRenderer,
    chakraTooltipRendererTester,
    ChakraHeadingRenderer,
    chakraHeadingRendererTester,
    ChakraDataReplacementRenderer,
    chakraDataReplacementRendererTester,
    ChakraListWithDetailRenderer,
    chakraListWithDetailTester,
    ChakraRuleGroup,
    chakraRuleGroupTester,
    ChakraIconRenderer,
    chakraIconRendererTester,
    SectionHeadingRenderer,
    sectionHeadingRendererTester,
    CurrentYearSelectRenderer,
    currentYearSelectTester,
    chakraLeaveDiffRendererTester,
    ChakraLeaveDiffRenderer,
} from './additional';

import chakraStaffAutoCompleteControl from '@/forms-renderers/chakra-ui/controls/ChakraStaffAutoCompleteControl';

export const chakraRenderers: JsonFormsRendererRegistryEntry[] = [
    // controls
    { tester: chakraBooleanControlTester, renderer: ChakraBooleanControl },
    { tester: chakraTextareaControlTester, renderer: ChakraTextareaControl },
    { tester: chakraTextControlTester, renderer: ChakraTextControl },
    { tester: chakraEnumControlTester, renderer: ChakraEnumControl },
    { tester: chakraOneOfEnumControlTester, renderer: ChakraOneOfEnumControl },
    {
        tester: chakraDateControlTester,
        renderer: ChakraDateControl,
    },
    {
        tester: chakraRadioGroupControlTester,
        renderer: ChakraRadioGroupControl,
    },
    {
        tester: chakraOneOfRadioGroupControlTester,
        renderer: ChakraOneOfRadioGroupControl,
    },
    {
        tester: chakraOneOfBooleanRadioGroupControlTester,
        renderer: ChakraOneOfBooleanRadioGroupControl,
    },
    {
        tester: chakraBooleanToggleControlTester,
        renderer: ChakraBooleanToggleControl,
    },
    {
        tester: chakraDecimalControlTester,
        renderer: ChakraDecimalControl,
    },
    {
        tester: chakraIntegerControlTester,
        renderer: ChakraIntegerControl,
    },
    {
        tester: chakraTableControlTester,
        renderer: ChakraTableControl,
    },
    //layouts
    {
        tester: chakraHorizontalLayoutTester,
        renderer: ChakraHorizontalLayout,
    },
    { tester: chakraVerticalLayoutTester, renderer: ChakraVerticalLayout },
    { tester: chakraGroupLayoutTester, renderer: ChakraGroupLayout },
    {
        tester: chakraCategorisationLayoutTester,
        renderer: ChakraCategorisationLayout,
    },
    // additional
    { tester: chakraLabelRendererTester, renderer: ChakraLabelRenderer },
    { tester: chakraTooltipRendererTester, renderer: ChakraTooltipRenderer },
    { tester: chakraHeadingRendererTester, renderer: ChakraHeadingRenderer },
    {
        tester: chakraDataReplacementRendererTester,
        renderer: ChakraDataReplacementRenderer,
    },
    {
        tester: chakraListWithDetailTester,
        renderer: ChakraListWithDetailRenderer,
    },
    {
        tester: chakraLeaveDiffRendererTester,
        renderer: ChakraLeaveDiffRenderer,
    },
    {
        tester: chakraRuleGroupTester,
        renderer: ChakraRuleGroup,
    },
    {
        tester: chakraIconRendererTester,
        renderer: ChakraIconRenderer,
    },
    {
        tester: chakraResearchMasterAutoCompletedTester,
        renderer: ChakraResearchMasterAutoCompleteControl,
    },
    {
        tester: chakraStaffListingAutoCompleteTester,
        renderer: chakraStaffAutoCompleteControl,
    },
    {
        tester: chakraOpenCourseAutoCompleteTester,
        renderer: ChakraOpenCourseAutoCompletionControl,
    },
    {
        tester: chakraSubjectAutoCompleteTester,
        renderer: ChakraSubjectAutoCompletionControl,
    },
    {
        tester: sectionHeadingRendererTester,
        renderer: SectionHeadingRenderer,
    },
    {
        tester: currentYearSelectTester,
        renderer: CurrentYearSelectRenderer,
    },
];

export const chakraCells: JsonFormsCellRendererRegistryEntry[] = [
    { tester: chakraBooleanCellTester, cell: ChakraBooleanCell },
    { tester: chakraBooleanToggleCellTester, cell: ChakraBooleanToggleCell },
    { tester: chakraTextCellTester, cell: ChakraTextCell },
    { tester: chakraIntegerCellTester, cell: ChakraIntegerCell },
    { tester: chakraDecimalCellTester, cell: ChakraDecimalCell },
    { tester: chakraEnumCellTester, cell: ChakraEnumCell },
];
