import {
    Box,
    Button,
    Flex,
    Input,
    InputGroup,
    InputProps,
    InputRightElement,
    Spinner,
    Text,
    Tooltip,
    VStack,
} from '@chakra-ui/react';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { CellProps, WithClassname } from '@jsonforms/core';
import merge from 'lodash/merge';
import { SimpleAlert } from '@/components/SimpleAlert/SimpleAlert';
import Icons from '@/components/icons/Icons';

interface ChakraAutoCompleteProps<T> {
    loading: boolean;
    error: unknown;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    matchedRecords?: T[];
    autoCompleteType: string;
    listItemAction?: (record: T) => void;
    renderSuggestion: (record: T) => ReactNode;
}

export const ChakraAutoComplete = <T,>(props: CellProps & WithClassname & InputProps & ChakraAutoCompleteProps<T>) => {
    const {
        error,
        data,
        className,
        id,
        enabled,
        isValid,
        config,
        uischema,
        onChange,
        loading,
        matchedRecords,
        autoCompleteType,
        renderSuggestion,
        listItemAction,
    } = props;
    const appliedUiSchemaOptions = merge({}, config, uischema.options);
    const [popUp, setPopup] = useState<boolean>(false);
    const focusRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const onSelectSuggestion = (record: T) => {
        listItemAction && listItemAction(record);
        setPopup(false);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Escape') {
            if (inputRef.current) {
                inputRef.current.focus();
            }
            setPopup(false);
            e.stopPropagation();
        }
    };

    useEffect(() => {
        if (!loading && data) {
            setPopup(true);
        }
    }, [loading]);

    return (
        <Box ref={focusRef} onKeyDown={handleKeyDown}>
            <InputGroup>
                <Input
                    onChange={handleInputChange}
                    id={id}
                    value={data ?? ''}
                    placeholder={appliedUiSchemaOptions.placeholder}
                    autoFocus={appliedUiSchemaOptions.focus}
                    isReadOnly={appliedUiSchemaOptions.readonly}
                    className={className}
                    isInvalid={!isValid}
                    isDisabled={!enabled}
                />
                <InputRightElement>{loading && <Spinner color={'blue'} size={'sm'} />}</InputRightElement>
            </InputGroup>
            {!!error && (
                <Box mt={1} w={'full'}>
                    <SimpleAlert variant={'left-accent'}> Cannot get list of {autoCompleteType}</SimpleAlert>
                </Box>
            )}
            {popUp && data && !loading && matchedRecords && !error && (
                <Flex position={'relative'} w={'full'}>
                    <Tooltip label="Close">
                        <Button
                            onClick={() => setPopup(false)}
                            position={'absolute'}
                            top={-1}
                            right={-3}
                            zIndex={3}
                            fontSize={30}
                            rounded={'full'}
                            bg={'transparent'}
                            p={0}
                        >
                            <Icons.CloseIcon color={'blue.500'} />
                        </Button>
                    </Tooltip>
                    <VStack
                        position={'absolute'}
                        spacing={2}
                        p={2}
                        mt={2}
                        w={'full'}
                        background={'offWhite'}
                        borderWidth={1}
                        borderColor="gray.200"
                        borderStyle="solid"
                        shadow={'lg'}
                        rounded={'lg'}
                        zIndex={2}
                        maxHeight={300}
                        overflowY="auto"
                        overflowX="hidden"
                        tabIndex={-1}
                    >
                        {matchedRecords?.length === 0 ? (
                            <Text p={3}>No {autoCompleteType} found</Text>
                        ) : (
                            matchedRecords?.map((record, i) => (
                                <VStack
                                    key={i}
                                    as={Button}
                                    w={'full'}
                                    h={'full'}
                                    alignItems={'flex-start'}
                                    spacing={0}
                                    fontWeight="normal"
                                    _focus={{
                                        background: 'gray.200',
                                    }}
                                    p={3}
                                    onClick={() => onSelectSuggestion(record)}
                                >
                                    {renderSuggestion(record)}
                                </VStack>
                            ))
                        )}
                    </VStack>
                </Flex>
            )}
        </Box>
    );
};
