import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { Alert, AlertIcon, Box, Button, Divider, Flex, HStack, Text, useToast, VStack } from '@chakra-ui/react';
import { useGetRequest, usePostRequest } from '@/utils/useStatefulRequests';
import { ListResponse } from '@/wpt-lib/interfaces/listResponse';
import { Assignment } from '@/wpt-lib/interfaces/Assignment';
import { defaultTableParams, mapOrderToValue } from '@/utils/tableUtils';
import { Action, ListTable } from '@/components/pages/dashboard/tiles/bottomTile/bottomTileTables/ListTable';
import { PaginationToolbar, TableSkeletonLoading } from '@/components/Table';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import Icons from '@/components/icons/Icons';
import { SimpleAlert } from '@/components/SimpleAlert/SimpleAlert';
import {
    BulkActionResponse,
    mapWorkplanStageToString,
    mapWorkplanStateToString,
    UpdateMultipleWorkplansDto,
    Workplan,
    WorkplanStage,
    WorkplanState,
} from '@/wpt-lib/interfaces/Workplan';
import FocusHeader from '@/components/FocusHeader/FocusHeader';
import { Column, SupervisionToolbar } from './SupervisionToolbar';
import { useAssignment } from '@/components/contexts/AssignmentContext/useAssignment';
import { Faculty } from '@/wpt-lib/interfaces/Faculty';
import { Organisation } from '@/wpt-lib/interfaces/Organisation';
import { User } from '@/wpt-lib/interfaces/User';
import { downloadUserSupervisionStaffWorkplans } from '@/components/Excel/downloadUserSupervisionStaffWorkplans';
import SearchBar from '@/components/pages/Admin/SearchBar';
import useNavigateOptions from '@/components/contexts/NaviateContext/useNavigateOptions';
import { formatFirstName, formatName } from '@/wpt-lib/utils/formatName';
import { Dialog } from '@/components/Dialog';
import BulkActionMessage from '@/components/BulkActions/BulkActionMessage';
import SupervisorProgressOptions from '@/components/pages/dashboard/tiles/ButtonTile/SupervisorProgressOptions';

type QueryOptions = {
    search?: string;
    access?: string;
    faculty?: string;
    organisation?: string;
    supervisor?: string;
    reports?: string;
    assignState?: string;
    probation?: string;
    year?: string;
    state?: string;
    stage?: string;
    sort: string;
    order: string;
};

type WorkplanWithAssignmentId = {
    workplan: Workplan;
    assignmentId: Assignment['id'];
};

const UserSupervision = () => {
    const currentYear = '' + new Date().getFullYear();

    const toast = useToast();
    const [search, setSearch] = useSearchParams();

    const [pageSize, setPageSize] = useState(50);
    const [page, setPage] = useState(defaultTableParams.page);
    const [pages, setPages] = useState(1);
    const [bulkApprovePopup, setBulkApprovePopup] = useState(false);
    const [changeTo, setChangeTo] = useState<[WorkplanState, WorkplanStage?] | undefined>(undefined);
    const [selectedWorkplans, setSelectedWorkplans] = useState<WorkplanWithAssignmentId[]>([]);

    const { navigatePathCustom, supervisor, admin } = useNavigateOptions();

    const [query, setQuery] = useState<QueryOptions>({
        search: search.get('search') ?? '',
        access: search.get('access') ?? 'All',
        faculty: search.get('faculty') ?? 'All',
        organisation: search.get('organisation') ?? 'All',
        supervisor: search.get('supervisor') ?? 'All',
        reports: search.get('reports') ?? 'All',
        assignState: search.get('assignState') ?? 'ACTIVE',
        probation: search.get('probation') ?? 'All',
        year: search.get('year') ?? '' + new Date().getFullYear(),
        state: search.get('status') ?? 'All',
        sort: search.get('sort') ?? 'Preferred Name',
        order: search.get('order') ?? 'A-Z',
    });

    const setQueryParam = <Param extends keyof QueryOptions>(param: Param, value: QueryOptions[Param]) => {
        setQueryParams({ [param]: value });
    };

    const setQueryParams = (update: Partial<QueryOptions>) =>
        setQuery((query) => {
            /* Deferring to not trigger render during state update. */
            setTimeout(() => {
                const params = Object.fromEntries(
                    Object.entries({
                        ...query,
                        ...update,
                        tab: 'supervision',
                    }).filter(([, v]) => v && v !== 'All'),
                );
                setSearch(params);
            });

            return { ...query, ...update };
        });

    const { currentAssignment, user } = useAssignment();
    const { makeRequest: makeExcelRequest, loading: excelLoading, error: excelError } = useGetRequest<Blob>();
    const [focusPage, setFocusPage] = useState(false);

    const {
        makeRequest: makePostRequest,
        responseData: updatedResponse,
        loading: isUpdatingWorkplans,
        error: updatingError,
    } = usePostRequest<BulkActionResponse, UpdateMultipleWorkplansDto[]>();

    const {
        makeRequest: makeGetRequest,
        responseData: listResponse,
        loading: isGettingUsers,
        error: usersLoadingError,
    } = useGetRequest<ListResponse<Assignment>>();

    const {
        makeRequest: makeFacultyRequest,
        responseData: facultyResponse,
        loading: isGettingFaculties,
        error: facultyLoadingError,
    } = useGetRequest<ListResponse<Faculty>>();

    const {
        makeRequest: makeOrgRequest,
        responseData: orgResponse,
        loading: isGettingOrgs,
        error: orgLoadingError,
    } = useGetRequest<ListResponse<Organisation>>();

    const {
        makeRequest: makeSupervisorRequest,
        responseData: supervisorResponse,
        loading: isGettingSupervisors,
        error: supervisorLoadingError,
    } = useGetRequest<User[]>();

    const {
        makeRequest: makeYearsRequest,
        responseData: yearsResponse,
        error: yearsLoadingError,
    } = useGetRequest<{ maxYear: number; minYear: number }[]>();

    const bulkActionWorkplans = async () => {
        if (!changeTo) return;
        try {
            const resp = await makePostRequest(
                `workplan/bulk`,
                [
                    ...selectedWorkplans.map((wa) => ({
                        id: wa.workplan.id,
                        assignmentId: wa.assignmentId,
                        update: { state: changeTo[0], stage: changeTo[1] },
                    })),
                ],
                {
                    params: {
                        assignment: currentAssignment?.id,
                    },
                },
            );
            setBulkApprovePopup(false);
            setChangeTo(undefined);
            setSelectedWorkplans([]);

            if (resp) {
                if (resp.data.updatedWorkplans.length > 0) {
                    toast({
                        title: 'Workplans Approved',
                        description: `${resp.data.updatedWorkplans.length} workplan${
                            resp.data.updatedWorkplans.length !== 1 ? 's' : ''
                        } Approved successfully.`,
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                    });
                }

                if (resp.data.rejectedWorkplans.length > 0) {
                    toast({
                        title: 'Workplans not Approved',
                        description: `${resp.data.rejectedWorkplans.length} workplan${
                            resp.data.rejectedWorkplans.length !== 1 ? 's' : ''
                        } could not be approved`,
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    });
                }
            }

            await getUsers();
        } catch {
            //handled by hook
        }
    };

    useEffect(() => {
        getUsers();
    }, [pageSize, page, pages, query]);

    useLayoutEffect(() => {
        setPage(0);
    }, [query]);

    useLayoutEffect(() => {
        getFacultyList();
        getWorkplanYears();
    }, []);

    useLayoutEffect(() => {
        getOrgList();
    }, [query.faculty]);

    useLayoutEffect(() => {
        getSupervisorList();
    }, [query.organisation]);

    const getUsers = async () => {
        try {
            setBulkApprovePopup(false);
            setChangeTo(undefined);
            setSelectedWorkplans([]);

            const params = {
                page: page,
                pageSize: pageSize,
                ...query,
                sortedOption: {
                    orderByColumn: query.sort,
                    orderByOrientation: mapOrderToValue[query.order],
                },
                assignment: currentAssignment?.id,
            };
            const res = await makeGetRequest(`assignment/filter`, {
                params,
            });
            setPages(res.data.info.pages);
            setPage(res.data.info.page);
        } catch {
            //handled by hook
        }
    };

    const getFacultyList = async () => {
        try {
            await makeFacultyRequest(`assignment/faculty`, {
                params: { assignment: currentAssignment?.id, all: true },
            });
        } catch {
            //handled by hook
        }
    };

    const getOrgList = async () => {
        try {
            if (query.faculty !== 'All')
                await makeOrgRequest(`assignment/faculty/${query.faculty}/organisations`, {
                    params: { assignment: currentAssignment?.id, all: true },
                });
        } catch {
            //handled by hook
        }
    };

    const getSupervisorList = async () => {
        try {
            if (query.organisation !== 'All')
                await makeSupervisorRequest(`organisation/${query.organisation}/supervisor`);
        } catch {
            //handled by hook
        }
    };

    const getWorkplanYears = async () => {
        try {
            await makeYearsRequest('workplan/workplanYears');
        } catch {
            // handled by hook
        }
    };

    const filterColumns = useMemo(
        (): (Column & { admin?: boolean })[] =>
            [
                {
                    admin: true,
                    label: 'Access',
                    optionList: [
                        { name: 'University Administrator' },
                        { name: 'Local Administrator', id: 'Faculty/Division Administrator' },
                        { name: 'Supervisor' },
                    ],
                    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => setQueryParam('access', e.target.value),
                    selectedValue: query.access,
                },
                {
                    label: 'Faculty/Division',
                    optionList: facultyResponse
                        ? facultyResponse.items.sort((a, b) => a.name.localeCompare(b.name))
                        : undefined,
                    disabled: isGettingFaculties,
                    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => {
                        setQueryParams({
                            faculty: e.target.value,
                            organisation: 'All',
                            supervisor: 'All',
                            reports: 'All',
                        });
                    },
                    selectedValue: query.faculty,
                },
                {
                    label: 'Org Unit',
                    optionList:
                        query.faculty !== 'All'
                            ? orgResponse?.items.sort((a, b) => a.name.localeCompare(b.name))
                            : undefined,
                    disabled: query.faculty === 'All' || isGettingOrgs,
                    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => {
                        setQueryParams({
                            organisation: e.target.value,
                            supervisor: 'All',
                            reports: 'All',
                        });
                    },
                    selectedValue: query.organisation,
                },
                {
                    label: 'Supervisor',
                    optionList:
                        query.organisation !== 'All'
                            ? supervisorResponse
                                  ?.map((s) => ({
                                      id: s.id,
                                      name: `${formatName(s)}`,
                                  }))
                                  .sort((a, b) => a.name.localeCompare(b.name))
                            : undefined,
                    disabled: query.organisation === 'All' || isGettingSupervisors,
                    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => {
                        const supervisor = e.target.value;
                        setQueryParam('supervisor', supervisor);
                        if (supervisor === 'All') setQueryParam('reports', 'All');
                        else if (!query.reports || query.reports === 'All') setQueryParam('reports', 'Direct');
                    },
                    selectedValue: query.supervisor,
                },
                {
                    label: 'Reports',
                    optionList: query.supervisor !== 'All' ? [{ name: 'Direct' }, { name: 'Indirect' }] : [],
                    disabled: query.supervisor === 'All' || isGettingSupervisors,
                    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => setQueryParam('reports', e.target.value),
                    selectedValue: query.reports,
                    noAll: query.supervisor !== 'All',
                },
                {
                    label: 'Assignment',
                    optionList: [
                        { name: 'Active', id: 'ACTIVE' },
                        { name: 'Inactive', id: 'INACTIVE' },
                    ],
                    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => setQueryParam('assignState', e.target.value),
                    selectedValue: query.assignState,
                },
                {
                    label: 'WP Year',
                    optionList:
                        yearsResponse && yearsResponse?.length > 0
                            ? new Array(yearsResponse[0].maxYear - yearsResponse[0].minYear + 1)
                                  .fill(1)
                                  .map((_, i) => ({
                                      name: '' + (yearsResponse[0].minYear + i),
                                      id: '' + (yearsResponse[0].minYear + i),
                                  }))
                            : [{ name: currentYear, id: currentYear }],
                    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => setQueryParam('year', e.target.value),
                    selectedValue: query.year,
                    noAll: true,
                },
                {
                    label: 'WP Type',
                    optionList: [
                        { name: 'Probation Plan', id: 'Yes' },
                        { name: 'Annual Workplan', id: 'No' },
                    ],
                    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => setQueryParam('probation', e.target.value),
                    selectedValue: query.probation,
                },
                {
                    label: 'WP Status',
                    optionList: [
                        { name: 'Not Started', id: 'NOT_STARTED' },
                        { name: 'Draft', id: WorkplanState.DRAFT },
                        { name: 'Submitted', id: WorkplanState.SUBMITTED },
                        { name: 'In Progress', id: WorkplanState.IN_PROGRESS },
                        { name: 'Review', id: WorkplanState.REVIEW },
                        { name: 'Information Requested', id: WorkplanState.INFORMATION_REQUESTED },
                        { name: 'Moderation', id: WorkplanState.MODERATION },
                        { name: 'Endorsed', id: WorkplanState.ENDORSED },
                        { name: 'Historical', id: WorkplanState.HISTORICAL },
                    ],
                    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => setQueryParam('state', e.target.value),
                    selectedValue: query.state,
                },
                {
                    label: 'WP Stage',
                    optionList: [
                        { name: 'Planning', id: WorkplanStage.PLANNING },
                        { name: 'Part Year', id: WorkplanStage.PART_YEAR },
                        { name: 'End Year', id: WorkplanStage.END_YEAR },
                        { name: 'Closed', id: WorkplanStage.CLOSED },
                    ],
                    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => setQueryParam('stage', e.target.value),
                    selectedValue: query.stage,
                },
            ].filter((c) => !c.admin || (c.admin && admin)) as Column[],
        [query, facultyResponse, orgResponse, supervisorResponse, yearsResponse, admin],
    );

    const columns = useMemo(
        () => [
            {
                name: 'ID',
                getData: (item: Assignment) => `${item.user?.staffId}`,
            },
            {
                name: 'Preferred Name',
                getData: (item: Assignment) => formatFirstName(item.user),
            },
            {
                name: 'Last Name',
                getData: (item: Assignment) => `${item.user?.lastName}`,
            },
            {
                name: 'Assignment Role',
                getData: (item: Assignment) => `${item.name}${item.inactive ? ' - Inactive' : ''}`,
            },
            {
                name: 'Faculty/Division',
                getData: (item: Assignment) => `${item.organisation?.faculty?.name}`,
            },
            {
                name: 'Org Unit',
                getData: (item: Assignment) => `${item.organisation?.name}`,
            },
            {
                name: 'Supervisor',
                getData: (item: Assignment) => item.supervisors?.map((s) => `${formatName(s.user)}`).join('\n'),
            },
            {
                name: 'WP Year',
                getData: (item: Assignment) => {
                    const workplans = item.workplans?.sort(
                        (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
                    );

                    if (workplans && workplans.length > 0) {
                        return workplans[0].year;
                    } else {
                        return query.year === 'All' ? '-' : query.year;
                    }
                },
            },
            {
                name: 'WP Type',
                getData: (item: Assignment) => {
                    const workplans = item.workplans?.sort(
                        (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
                    );

                    if (workplans && workplans.length > 0) {
                        return workplans[0].currentVersion.probationPlan ? 'Probation Plan' : 'Annual Workplan';
                    } else {
                        return ' - ';
                    }
                },
            },
            {
                name: 'WP Status',
                getData: (item: Assignment) => {
                    const workplans = item.workplans?.sort(
                        (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
                    );

                    if (workplans && workplans.length > 0) {
                        return mapWorkplanStateToString[workplans[0].state];
                    } else {
                        return 'Not Started';
                    }
                },
            },
            {
                name: 'WP Stage',
                getData: (item: Assignment) => {
                    const workplans = item.workplans?.sort(
                        (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
                    );

                    if (workplans && workplans.length > 0) {
                        return mapWorkplanStageToString[workplans[0].currentVersion.stage];
                    } else {
                        return '-';
                    }
                },
            },
        ],
        [query.year],
    );

    const sortColumns: Column[] = useMemo(
        () => [
            {
                label: 'Sort by',
                optionList: [
                    { name: 'Preferred Name' },
                    { name: 'Last Name' },
                    { name: 'Assignment Role' },
                    { name: 'Faculty' },
                    { name: 'Org Unit' },
                    { name: 'Supervisor' },
                ],
                noAll: true,
                onChange: (e: React.ChangeEvent<HTMLSelectElement>) => setQueryParam('sort', e.target.value),
                selectedValue: query.sort,
            },
            {
                label: 'Order',
                optionList: [{ name: 'A-Z' }, { name: 'Z-A' }],
                noAll: true,
                onChange: (e: React.ChangeEvent<HTMLSelectElement>) => setQueryParam('order', e.target.value),
                selectedValue: query.order,
            },
        ],
        [query],
    );

    const viewProfile = (item: Assignment) => {
        const searchParams = createSearchParams();
        for (const [p, v] of Object.entries(query)) {
            if (v && v !== 'All') searchParams.append(p, v);
        }

        navigatePathCustom({
            path: [`${item.user?.firstName}-${item.user?.lastName}-${item.id};`, item.id],
            search: searchParams,
        });
    };

    const viewWorkplan = (item: Assignment) => {
        const workplan = item.workplans?.[0];
        if (!workplan) return;

        let version = workplan.currentVersion.version;
        if (
            ![
                WorkplanState.SUBMITTED,
                WorkplanState.REVIEW,
                WorkplanState.MODERATION,
                WorkplanState.ENDORSED,
                WorkplanState.HISTORICAL,
            ].includes(workplan.state)
        )
            version = Math.max(version - 1, 1);

        navigatePathCustom({
            path: [workplan.id, version],
            search: '',
        });
    };

    const getActions = (assignment: Assignment): Action<Assignment>[] => {
        return [
            {
                id: 'select',
                label: 'Select',
                ariaLabel: `Select ${formatName(assignment.user)}'s workplan`,
                onSelectEvent: (assignments: Assignment[]) => {
                    const workplanWithAssignmentId = assignments
                        .map(
                            (a) =>
                                a.workplans &&
                                a.workplans.length > 0 && { workplan: a.workplans[0], assignmentId: a.id },
                        )
                        .filter(Boolean) as WorkplanWithAssignmentId[];
                    setSelectedWorkplans([...workplanWithAssignmentId]);
                },
                isDisabled: (assignment: Assignment) =>
                    !(
                        assignment.workplans &&
                        assignment.workplans.length > 0 &&
                        [WorkplanState.SUBMITTED, WorkplanState.REVIEW, WorkplanState.MODERATION].includes(
                            assignment.workplans[0].state,
                        )
                    ),
            },
            {
                id: 'view',
                event: supervisor ? viewWorkplan : viewProfile,
                label: 'View',
                ariaLabel: `View ${formatName(assignment.user)}'s ${supervisor ? 'workplan' : 'profile'}`,
                icon: supervisor ? <Icons.EyeIcon /> : <Icons.PointerRightIcon w={8} h={8} />,
                isDisabled: (assignment: Assignment) =>
                    supervisor &&
                    (!assignment.workplans ||
                        assignment.workplans.length === 0 ||
                        (assignment.workplans[0].currentVersion.version === 1 &&
                            assignment.workplans[0].currentVersion.state === WorkplanState.DRAFT)),
            },
        ];
    };

    const data = useMemo(
        () =>
            listResponse &&
            listResponse.items.flatMap((a) =>
                a.workplans && a.workplans.length > 1 ? a.workplans.map((w) => ({ ...a, workplans: [w] })) : a,
            ),
        [listResponse],
    );

    if (isGettingUsers) {
        return (
            <Box w={'full'} p={4}>
                <Box bg={'offWhite'} p={4} rounded={'md'}>
                    <TableSkeletonLoading noOfCols={columns.length} noOfRows={10} />
                </Box>
            </Box>
        );
    }

    if (usersLoadingError || facultyLoadingError || orgLoadingError || supervisorLoadingError || yearsLoadingError) {
        return (
            <Flex justifyContent="center">
                <SimpleAlert title="Error" variant="subtle">
                    {`Could not load list of assignments`}
                </SimpleAlert>
            </Flex>
        );
    }

    const downloadAdminUserSupervision = async () => {
        const urlPath = `downloadUserSupervisionTable`;
        const staffId = user?.staffId;
        const fileName = 'User_Supervision_Staff_Workplans';
        const params = {
            page: page,
            pageSize: pageSize,
            search: query['search'] ?? '',
            access: query['access'] ?? 'All',
            faculty: query['faculty'] ?? 'All',
            organisation: query['organisation'] ?? 'All',
            supervisor: query['supervisor'] ?? 'All',
            reports: query['reports'] ?? 'Indirect',
            assignState: query['assignState'] ?? 'All',
            probation: query['probation'] ?? 'All',
            year: query['year'] ?? 'All',
            state: query['state'] ?? 'All',
            stage: query['stage'] ?? 'All',
            sortedOption: {
                orderByColumn: query.sort,
                orderByOrientation: mapOrderToValue[query.order],
            },
            assignment: currentAssignment?.id,
        };

        await downloadUserSupervisionStaffWorkplans({
            urlPath,
            staffId,
            fileName,
            makeExcelRequest,
            params,
        });
    };

    return (
        <Flex w="full" p={6} gap={6} flexDirection="column">
            <Flex justifyContent={'space-between'}>
                <FocusHeader size={'small'} textColor={'blue.500'} pt={6}>
                    Staff
                </FocusHeader>
                {listResponse && listResponse.info.total > 0 && (
                    <HStack>
                        {!!excelError && (
                            <Alert status="error" rounded={6}>
                                <AlertIcon />
                                Could not download table, please refresh page.
                            </Alert>
                        )}

                        <Button
                            h={12}
                            whiteSpace={'normal'}
                            colorScheme={'blue'}
                            display={'flex'}
                            gridGap={2}
                            isLoading={excelLoading}
                            onClick={downloadAdminUserSupervision}
                        >
                            <Text display={{ base: 'none', md: 'block' }}>Download Table</Text>
                        </Button>
                    </HStack>
                )}
            </Flex>
            <VStack>
                <SearchBar search={query.search ?? ''} doSearch={(v) => setQueryParam('search', v)} />
                <Divider />
                <SupervisionToolbar label={'Filters'} columns={filterColumns} stackProps={{ w: 'full' }} />
                <Divider />
                <SupervisionToolbar label={'Sort'} columns={sortColumns} stackProps={{ w: 'full' }} />
                <Divider />
            </VStack>
            <Flex alignItems="center" justifyContent="space-between" gap={5}>
                <Text whiteSpace={'nowrap'} aria-label="Total workplans">
                    Total: {listResponse?.info.total}
                </Text>
                <SupervisionToolbar
                    label={''}
                    columns={[]}
                    textProps={{ fontWeight: 'normal' }}
                    showApproveButton
                    buttonProps={{
                        chakraProps: {
                            onClick: () => setBulkApprovePopup(true),
                            colorScheme: 'blue',
                            textAlign: 'center',
                            isDisabled: !(
                                selectedWorkplans.length > 0 &&
                                selectedWorkplans.some((wa) =>
                                    [WorkplanState.SUBMITTED, WorkplanState.REVIEW, WorkplanState.MODERATION].includes(
                                        wa.workplan.state,
                                    ),
                                )
                            ),
                            'aria-label': 'Bulk Approve',
                        },
                        label: 'Bulk Approve',
                    }}
                />
            </Flex>
            <Box overflowX={'auto'}>
                <ListTable
                    tableCaption={`List of staff workplans (page ${page + 1} of ${pages === 0 ? 1 : pages})`}
                    getActions={getActions}
                    columns={columns}
                    data={data}
                    hideOption={true}
                    errorRows={updatedResponse?.rejectedWorkplans.reduce((acc, rejected) => {
                        acc[`${rejected.id}`] = rejected.reason;
                        return acc;
                    }, {})}
                    idKey="id"
                    message={updatedResponse ? <BulkActionMessage updatedResponse={updatedResponse} /> : undefined}
                />
            </Box>
            <Box overflowX={'auto'}>
                <PaginationToolbar
                    setPageSize={setPageSize}
                    setNewPage={setPage}
                    pageSize={pageSize}
                    pages={pages}
                    page={page}
                    focusPage={focusPage}
                    setFocusPage={setFocusPage}
                />
            </Box>
            <Dialog
                dialogHeader="Bulk Approve Workplans"
                dialogBody="Choose stage to progress:"
                isOpen={bulkApprovePopup}
                isLoading={isUpdatingWorkplans}
                onClose={() => setBulkApprovePopup(false)}
                errorMsg={(updatingError as Error)?.message}
                dialogAction={() => bulkActionWorkplans()}
                confirmBtnProps={{ isDisabled: !changeTo }}
            >
                <SupervisorProgressOptions onSelect={setChangeTo} />
            </Dialog>
        </Flex>
    );
};

export default UserSupervision;
