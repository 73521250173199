import { PersonalGoalFormResponse } from '@/wpt-lib/interfaces/PersonalGoal';
import WorkplanItemAction, { Params, useParam, useWorkplanItemData } from '../WorkplanItemAction';
import React from 'react';
import { useParams } from 'react-router-dom';
import WorkplanItemHandler from '@/components/pages/workplanItemHandler/WorkplanItemHandler';

const GoalWorkplanItem = () => {
    const { workplanId, assignmentId, versionId, userDetails } = useParams<{
        workplanId: string;
        assignmentId: string;
        versionId: string;
        userDetails: string;
    }>();
    const breadCrumbData = {
        workplanId,
        assignmentId,
        versionId,
        userDetails,
    };
    const type = 'career-goal';
    const { parsedData: parsedGoal } = useWorkplanItemData<PersonalGoalFormResponse, PersonalGoalFormResponse>(type);
    const goalName = parsedGoal ? parsedGoal.goalName : 'Goal';
    return (
        <WorkplanItemAction
            {...breadCrumbData}
            renderForm={() => <WorkplanItemHandler readonly={true} formType="goal" />}
            workplanItemType={type}
            typeLabel={'Goal'}
            params={useParam() as Params}
            name={goalName}
        />
    );
};

export default GoalWorkplanItem;
