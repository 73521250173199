import { useAssignment } from '@/components/contexts/AssignmentContext/useAssignment';
import { defaultTableParams } from '@/utils/tableUtils';
import { WorkplanState, WorkplanVersion } from '@/wpt-lib/interfaces/Workplan';
import { ListResponse } from '@/wpt-lib/interfaces/listResponse';
import ExportWorkplan, { ExportWorkplanRef } from '@/components/export/ExportWorkplan';
import Icons from '@/components/icons/Icons';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Assignment } from '@/wpt-lib/interfaces/Assignment';
import { useAsyncEffect } from '@/utils/hooks/useAsyncEffect';
import { useGetRequest } from '@/utils/useStatefulRequests';
import { SimpleAlert } from '@/components/SimpleAlert/SimpleAlert';
import { Button, Flex, Text } from '@chakra-ui/react';
import { TableTopToolBar } from '@/components/Table/TableTopToolBar';
import { PaginationToolbar } from '@/components/Table';
import { Tile } from '@/components/Tile';
import { workplanColumns } from '@/components/pages/Admin/UserSupervision/Column/UserSupervisionColumn';
import { Action, ListTable } from '@/components/pages/dashboard/tiles/bottomTile/bottomTileTables/ListTable';
import { downloadExcelTables } from '@/components/Excel/downloadExcelTables';
import StateDisplay from '@/components/StateDisplay/StateDisplay';
import { useUserSupervisionFilters } from '@/components/contexts/UserSupervisionFiltersContext/useUserSupervisionFilters';
import useNavigateOptions from '@/components/contexts/NaviateContext/useNavigateOptions';

export const WorkplansTable = (props: { assignmentId?: string; assignment?: Assignment }) => {
    const { assignmentId, assignment } = props;
    const { currentAssignment } = useAssignment();

    //Workplan
    const [pageSize, setPageSize] = useState(defaultTableParams.pageSize);
    const [pages, setPages] = useState(1);
    const [page, setPage] = useState(defaultTableParams.page);
    const [orderByColumn, setOrderByColumn] = useState<string>(workplanColumns[0].field);
    const [orderByOrientation, setOrderByOrientation] = useState<string>(defaultTableParams.orderByOrientation);
    const [focusPage, setFocusPage] = useState(false);

    const { navigatePathCustom, supervisor } = useNavigateOptions();
    const search = useUserSupervisionFilters().params;

    const { makeRequest: makeExcelRequest, loading, error } = useGetRequest<Blob>();

    const {
        makeRequest: makeGetRequest,
        responseData: workplanListResponse,
        loading: isGettingWorkplans,
        error: workplansLoadingError,
    } = useGetRequest<ListResponse<WorkplanVersion>>();

    const getListOfWorkplan = useCallback(async () => {
        try {
            const params = {
                page: page,
                orderByColumn: orderByColumn,
                orderByOrientation: orderByOrientation,
                pageSize: pageSize,
            };
            const workplan = await makeGetRequest(`workplan/versions/${assignmentId}`, {
                params,
            });

            setPages(workplan.data.info.pages);
            setPage(workplan.data.info.page);
        } catch {
            //handled by hook
        }
    }, [assignmentId, pageSize, page, pages, orderByColumn, orderByOrientation]);

    useAsyncEffect(async () => {
        try {
            await getListOfWorkplan();
        } catch (error) {
            // handled by hook
        }
    }, [getListOfWorkplan]);

    useEffect(() => {
        setPage(defaultTableParams.page);
    }, [assignmentId]);
    const exportRef = useRef<ExportWorkplanRef | null>(null);
    const exportWorkplan = (item: WorkplanVersion) => {
        if (assignment && currentAssignment) {
            exportRef.current?.export(
                {
                    ...item.workplan,
                    assignment,
                },
                item.version,
                currentAssignment,
            );
        }
    };

    const viewWorkplan = (item: WorkplanVersion) => {
        if (assignment)
            navigatePathCustom({
                path: ['workplan', item.workplan.id, item.version],
                search,
            });
    };

    const getWorkplanActions = (item: WorkplanVersion): Action<WorkplanVersion>[] => {
        if (
            supervisor &&
            item.version === item.workplan.currentVersion.version &&
            [WorkplanState.DRAFT, WorkplanState.IN_PROGRESS, WorkplanState.INFORMATION_REQUESTED].includes(item.state)
        )
            return [];
        else return defaultWorkplanAction(item);
    };

    const defaultWorkplanAction = (workplanVersion: WorkplanVersion) => {
        return [
            {
                id: 'view',
                event: viewWorkplan,
                label: 'View',
                ariaLabel: `View ${workplanVersion.workplan.year} workplan`,
                icon: <Icons.EyeIcon />,
            },
            {
                id: 'export',
                event: exportWorkplan,
                label: 'Export',
                ariaLabel: `Export ${workplanVersion.workplan.year} workplan`,
                icon: <Icons.DownloadIcon />,
            },
        ];
    };

    const hasWorkplan = workplanListResponse && workplanListResponse.info.total > 0;

    const downloadUserSupervisionWorkplans = async () => {
        if (assignment) {
            const urlPath = `downloadUserSupervisionWorkplans/${assignmentId}`;
            const staffId = `${assignment.user?.staffId}`;
            const fileName = 'User_Supervision_Workplans';
            await downloadExcelTables({
                page,
                orderByColumn,
                orderByOrientation,
                pageSize,
                urlPath,
                staffId,
                fileName,
                makeExcelRequest,
            });
        }
    };

    return (
        <>
            <Tile overflowX={'auto'} colspan={2} collapsable heading={'Workplans'} defaultOpen>
                <StateDisplay
                    data={workplanListResponse}
                    loading={isGettingWorkplans}
                    error={!!workplansLoadingError}
                    errorRender={() => (
                        <Flex justifyContent="center" width={'100%'}>
                            <SimpleAlert title="Error" variant="subtle">
                                {`Could not load user supervision workplans`}
                            </SimpleAlert>
                        </Flex>
                    )}
                    render={() => (
                        <>
                            <Flex justifyContent={'space-between'}>
                                <TableTopToolBar
                                    listResponse={workplanListResponse}
                                    loading={isGettingWorkplans}
                                    columns={workplanColumns}
                                    orderByColumn={orderByColumn}
                                    orderByOrientation={orderByOrientation}
                                    setOrderByColumn={setOrderByColumn}
                                    setOrderByOrientation={setOrderByOrientation}
                                />
                                {hasWorkplan && (
                                    <>
                                        {error && (
                                            <SimpleAlert title="Error" variant="subtle" maxWidth={'50%'}>
                                                {`Could not download user supervision workplans table`}
                                            </SimpleAlert>
                                        )}
                                        <Button
                                            h={12}
                                            whiteSpace={'normal'}
                                            colorScheme={'blue'}
                                            display={'flex'}
                                            gridGap={2}
                                            isLoading={loading}
                                            onClick={downloadUserSupervisionWorkplans}
                                        >
                                            <Text
                                                display={{
                                                    base: 'none',
                                                    md: 'block',
                                                }}
                                            >
                                                Download Table
                                            </Text>
                                        </Button>
                                    </>
                                )}
                            </Flex>
                            <ListTable
                                tableCaption={`List of workplans (page ${page + 1} of ${pages === 0 ? 1 : pages})`}
                                getActions={getWorkplanActions}
                                columns={workplanColumns}
                                data={workplanListResponse?.items}
                                hideOption={false}
                            />
                            <PaginationToolbar
                                setPageSize={setPageSize}
                                setNewPage={setPage}
                                pageSize={pageSize}
                                pages={pages}
                                page={page}
                                focusPage={focusPage}
                                setFocusPage={setFocusPage}
                            />
                        </>
                    )}
                />
            </Tile>
            <ExportWorkplan ref={exportRef} />
        </>
    );
};

export default WorkplansTable;
