import React from 'react';
import { isBooleanControl, RankedTester, rankWith, ControlProps, and, optionIs } from '@jsonforms/core';
import { ChakraSwitch } from '../chakra-controls';
import { ChakraFormInput } from '../chakra-controls/ChakraFormInput';
import { withNamedJsonFormsControlProps } from '@/forms-renderers/chakra-ui/utils/withNamedJsonFormsHOC';

export const ChakraBooleanToggleControl = (props: ControlProps) => {
    return (
        <ChakraFormInput
            {...props}
            input={ChakraSwitch}
            formControlProps={{
                display: 'flex',
                alignItems: 'center',
                alignSelf: 'start',
            }}
            labelProps={{ mb: 0 }}
        />
    );
};

export const chakraBooleanToggleControlTester: RankedTester = rankWith(
    3,
    and(isBooleanControl, optionIs('toggle', true)),
);
export default withNamedJsonFormsControlProps(ChakraBooleanToggleControl);
