import React from 'react';
import { CellProps, ControlProps, WithClassname } from '@jsonforms/core';
import merge from 'lodash/merge';
import { Checkbox } from '@chakra-ui/react';
import { namedMemo } from '@/utils/namedMemo';
import { WorkplanItemFormConfig } from '@/components/WorkplanItemForm/WorkplanItemForm';
import TextHighlighting from '@/components/TextHighlighting/TextHighlighting';

export const ChakraCheckbox = namedMemo(
    'ChakraCheckbox',
    (props: CellProps & WithClassname & Partial<Pick<ControlProps, 'label' | 'required'>>) => {
        const { data, className, id, enabled, uischema, path, handleChange, config, label, required } = props;
        const appliedUiSchemaOptions = merge({}, config, uischema.options);
        const inputProps = { autoFocus: !!appliedUiSchemaOptions.focus };
        const checked = !!data;
        const newConfig = config as WorkplanItemFormConfig;
        const hasChanges = newConfig.formChanges[path];
        return (
            <Checkbox
                isChecked={checked}
                className={className}
                id={id}
                onChange={(ev) => {
                    if (enabled) handleChange(path, ev.target.checked);
                }}
                isReadOnly={appliedUiSchemaOptions.readonly || !enabled}
                required={required}
                {...inputProps}
            >
                <TextHighlighting version={1} query={(hasChanges && label) || ''}>
                    {label || ''}
                </TextHighlighting>
            </Checkbox>
        );
    },
);
