import { useWorkplanLabels } from '@/components/contexts/LabelsContext/useLabels';
import { LeaveFormResponse } from '@/wpt-lib/interfaces/Leave';
import WorkplanItemAction, { Params, useParam, useWorkplanItemData } from '../WorkplanItemAction';
import React from 'react';
import { useParams } from 'react-router-dom';
import WorkplanItemHandler from '@/components/pages/workplanItemHandler/WorkplanItemHandler';

const LeaveWorkplanItem = () => {
    const { workplanId, assignmentId, versionId, userDetails } = useParams<{
        workplanId: string;
        assignmentId: string;
        versionId: string;
        userDetails: string;
    }>();
    const breadCrumbData = {
        workplanId,
        assignmentId,
        versionId,
        userDetails,
    };
    const type = 'leave';
    const { parsedData: parsedLeave, workplanItem } = useWorkplanItemData<LeaveFormResponse, LeaveFormResponse>(type);
    const leaveTypes = useWorkplanLabels(workplanItem?.workplanItem?.workplanVersion?.workplan, 'LEAVE', 'absenceType');

    const leaveName =
        parsedLeave && parsedLeave.absenceType && parsedLeave.absenceType in leaveTypes
            ? leaveTypes[parsedLeave.absenceType as keyof typeof leaveTypes]
            : 'Leave';
    return (
        <WorkplanItemAction
            {...breadCrumbData}
            renderForm={() => <WorkplanItemHandler readonly={true} formType="planned-leave" />}
            workplanItemType={type}
            typeLabel={'Plan Leave'}
            params={useParam() as Params}
            name={leaveName}
        />
    );
};
export default LeaveWorkplanItem;
