import React from 'react';
import { and, CellProps, isBooleanControl, optionIs, RankedTester, rankWith, WithClassname } from '@jsonforms/core';
import { ChakraSwitch } from '../chakra-controls';
import { withNamedJsonFormsCellProps } from '@/forms-renderers/chakra-ui/utils/withNamedJsonFormsHOC';

export const ChakraBooleanToggleCell = (props: CellProps & WithClassname) => {
    return <ChakraSwitch {...props} />;
};

export const chakraBooleanToggleCellTester: RankedTester = rankWith(3, and(isBooleanControl, optionIs('toggle', true)));

export default withNamedJsonFormsCellProps(ChakraBooleanToggleCell);
