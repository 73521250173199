import React, { PropsWithChildren } from 'react';
import { Route, Routes as ReactRouterRoutes } from 'react-router-dom';
import { CustomRoute } from '@/routes/routes';
import ProtectedRoute from './ProtectedRoute';

interface RouterProps {
    routes: CustomRoute[];
}

const Routes: React.FC<PropsWithChildren<RouterProps>> = ({ routes }) => (
    <ReactRouterRoutes>
        {routes.map(({ key, path, element, authRequired }) => {
            const props = {
                path,
                element,
            };
            let elem = element;
            if (authRequired) {
                elem = <ProtectedRoute {...props} />;
            }
            return <Route key={key} {...props} element={elem} />;
        })}
    </ReactRouterRoutes>
);

export default Routes;
