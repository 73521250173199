import React from 'react';
import { HealthAndSafetyTrainingFormResponse } from '@/wpt-lib/interfaces/HealthAndSafetyTraining';
import { FormActionTable } from '@/components/pages/dashboard/tiles/bottomTile/bottomTileTables/FormActionTable';
import { FormTableProps } from '@/components/pages/dashboard/tiles/bottomTile/bottomTileTables/FormTableInterfaces';
import { createdColumn } from '@/components/pages/dashboard/tiles/bottomTile/bottomTileTables/PersonalGoalTable';

type HealthAndSafetyTrainingTableProps = FormTableProps<HealthAndSafetyTrainingFormResponse>;

const columns = [
    {
        name: 'Training Name',
        field: 'name',
        highlight: (item: HealthAndSafetyTrainingFormResponse) => item.changed,
        getData: (item: HealthAndSafetyTrainingFormResponse) => item.name,
    },
    {
        name: 'Created',
        field: 'createdAt',
        getData: createdColumn,
    },
];

export const getHealthAndSafetyName = (row: HealthAndSafetyTrainingFormResponse) => row.name;

export const HealthAndSafetyTrainingTable = (props: HealthAndSafetyTrainingTableProps) => {
    return (
        <FormActionTable
            getColumns={props.getColumns}
            endpoint={'health-and-safety-training'}
            typeOfForm={'Training'}
            urlLabel={'training'}
            tableCaptions={'Training'}
            columns={columns}
            data={props.data}
            fetchFormList={props.fetchFormList}
            onChange={props.onChange}
            getActions={(row, defaultActions, typeOfForm) => {
                return props.getActions ? props.getActions(row, defaultActions, typeOfForm) : defaultActions;
            }}
            workplan={props.workplan}
            getName={getHealthAndSafetyName}
            page={props.page}
            pages={props.pages}
            orderByOrientation={props.orderByOrientation}
            orderByColumn={props.orderByColumn}
        />
    );
};
