import React from 'react';
import { ControlProps, isIntegerControl, RankedTester, rankWith } from '@jsonforms/core';
import { ChakraInputInteger } from '../chakra-controls';
import { ChakraFormInput } from '../chakra-controls/ChakraFormInput';
import { withNamedJsonFormsControlProps } from '@/forms-renderers/chakra-ui/utils/withNamedJsonFormsHOC';

export const ChakraIntegerControl = (props: ControlProps) => <ChakraFormInput {...props} input={ChakraInputInteger} />;

export const chakraIntegerControlTester: RankedTester = rankWith(2, isIntegerControl);

export default withNamedJsonFormsControlProps(ChakraIntegerControl);
