import React, { useEffect, useRef, useState } from 'react';
import { Box, Textarea, TextareaProps } from '@chakra-ui/react';

type AutoExpandTextArea = {
    data: string;
    onChange?: (e: string) => void;
    onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
} & TextareaProps;

export const AutoExpandTextArea = ({ data, onChange, onKeyDown, ...props }: AutoExpandTextArea) => {
    const textRef = useRef<HTMLInputElement>(null);
    const [textBoxHeight, setTextBoxHeight] = useState<number>(144);

    useEffect(() => {
        if (textRef.current && data) {
            const height = textRef.current.offsetHeight;

            setTextBoxHeight(height);
        }
    }, [textRef, data?.length]);

    return (
        <Box
            style={{
                position: 'relative',
                width: '100%',
                overflowY: 'clip',
                paddingTop: 1,
                paddingBottom: 1,
            }}
        >
            <Box
                style={{
                    width: '100%',
                    position: 'absolute',
                    border: '1px solid transparent',
                    zIndex: -100,
                    color: 'transparent',
                    whiteSpace: 'pre-wrap',
                    padding: '8',
                }}
                aria-hidden={true}
                ref={textRef}
            >
                `${data}`
            </Box>

            <div style={{ width: '100%', height: '100%' }}>
                <Textarea
                    {...props}
                    minHeight={'144px'}
                    height={textBoxHeight}
                    value={data}
                    onChange={(e) => (onChange ? onChange(e.target.value) : null)}
                    onKeyDown={(e) => (onKeyDown ? onKeyDown(e) : null)}
                />
            </div>
        </Box>
    );
};

export default AutoExpandTextArea;
