import { useAssignment } from '@/components/contexts/AssignmentContext/useAssignment';
import Icons from '@/components/icons/Icons';
import { Action, Column, ListTable } from '@/components/pages/dashboard/tiles/bottomTile/bottomTileTables/ListTable';
import { ProgressUpdateDialog } from '@/components/pages/workplan/workplanItemAction/ProgressUpdateDialog';
import { SimpleAlert } from '@/components/SimpleAlert/SimpleAlert';
import { DeleteRowAlert } from '@/components/Table/DeleteRowAlert';
import { BaseFormRequest } from '@/wpt-lib/interfaces/baseFormRequest';
import { BaseFormResponse } from '@/wpt-lib/interfaces/baseFormResponse';
import { ListResponse } from '@/wpt-lib/interfaces/listResponse';
import { Workplan } from '@/wpt-lib/interfaces/Workplan';
import { useDeleteRequest, usePostRequest } from '@/utils/useStatefulRequests';
import React, { useEffect, useState } from 'react';
import useWorkplanVersion from '@/components/contexts/VersionContext/useWorkplanVersion';
import { serialiseFormData } from '@/wpt-lib/utils/formData';
import { useUserSupervisionFilters } from '@/components/contexts/UserSupervisionFiltersContext/useUserSupervisionFilters';
import useNavigateOptions from '@/components/contexts/NaviateContext/useNavigateOptions';

export interface StandardListOfFormsProps<Response extends BaseFormResponse> {
    workplan: Workplan;
    endpoint: string;
    typeOfForm: string;
    columns: Column<Response>[];
    data?: ListResponse<Response>;
    getColumns: (columns: Column<unknown>[]) => void;
    fetchFormList: () => void;
    onChange?: () => void;
    getActions?: (
        rowName: BaseFormResponse,
        defaultActions: Action<BaseFormResponse>[],
        typeOfForm: string,
    ) => Action<BaseFormResponse>[];
    getName: (row: Response) => string;
    tableCaptions: string;
    urlLabel: string;
    page?: number;
    pages?: number;
    orderByOrientation: string;
    orderByColumn: string;
}
export const FormActionTable = <Response extends BaseFormResponse>(props: StandardListOfFormsProps<Response>) => {
    const {
        endpoint,
        columns,
        data,
        typeOfForm,
        fetchFormList,
        getColumns,
        onChange,
        workplan,
        tableCaptions,
        urlLabel,
        page,
        pages,
        orderByColumn,
        orderByOrientation,
    } = props;

    const [deleteAlert, setDeleteAlert] = useState(false);
    const [rowToDelete, setDeletedRow] = useState<BaseFormResponse | undefined>(undefined);
    const { currentAssignment } = useAssignment();

    const { navigatePathCustom } = useNavigateOptions();
    const search = useUserSupervisionFilters().params;

    const { makeRequest: makeDeleteRequest, loading: isDeleting, error: deleteError } = useDeleteRequest<Response>();
    const {
        makeRequest: makePostRequest,
        loading: isCopying,
        error: copyError,
    } = usePostRequest<Response, BaseFormRequest>();

    const setDeleteConfirmation = (row: BaseFormResponse) => {
        setDeletedRow(row);
        setDeleteAlert(true);
    };
    const [addProgressUpdatePopup, setAddProgressUpdatePopup] = useState(false);
    const [getRow, setRow] = useState<BaseFormResponse>();

    const { version } = useWorkplanVersion();

    const deleteRow = async (item: BaseFormResponse) => {
        try {
            await makeDeleteRequest(`${endpoint}/${item.id}`, {
                params: { assignment: currentAssignment?.id },
            });
            if (onChange) onChange();
            await fetchFormList();
            return true;
        } catch (e) {
            //handled by hook
            return false;
        }
    };

    const editRow = (item: BaseFormResponse) => {
        navigatePathCustom({ path: [workplan.id, workplan.currentVersion.version, urlLabel, item.id, 'edit'], search });
    };

    const copyRow = async (item: BaseFormResponse) => {
        try {
            await makePostRequest(
                `${endpoint}`,
                {
                    ...item.workplanItem,
                    formData: serialiseFormData(item.workplanItem.formData),
                },
                {
                    params: { workplan: workplan.id, assignment: currentAssignment?.id },
                },
            );
            if (onChange) onChange();
            await fetchFormList();
        } catch (e) {
            //handled by hook
        }
    };

    const showAddProgressUpdatePopup = (item: BaseFormResponse) => {
        setRow(item);
        setAddProgressUpdatePopup(true);
    };

    const viewProgressUpdateComment = (item: BaseFormResponse) => {
        navigatePathCustom({
            path: [workplan.id, version ?? workplan.currentVersion.version, urlLabel, item.id, item.workplanItem.id],
            search: `${search}&orderByOrientation=${orderByOrientation}&orderByColumn=${orderByColumn}`,
        });
    };

    const defaultActionList: Action<BaseFormResponse>[] = [
        {
            id: 'view',
            event: viewProgressUpdateComment,
            label: 'View Progress Updates & Comments',
            icon: <Icons.EyeIcon />,
        },
        {
            id: 'copy',
            event: copyRow,
            label: 'Duplicate',
            isLoading: isCopying,
            error: copyError,
            icon: <Icons.CopyIcon />,
        },
        {
            id: 'edit',
            event: editRow,
            label: 'Edit',
            icon: <Icons.EditIcon />,
        },
        {
            id: 'delete',
            event: setDeleteConfirmation,
            label: 'Delete',
            icon: <Icons.DeleteIcon />,
        },
        {
            id: 'add',
            event: showAddProgressUpdatePopup,
            label: 'Add Progress Update',
            icon: <Icons.AddIcon />,
        },
    ];
    const passColumnsData = () => {
        getColumns(columns as Column<unknown>[]);
    };

    const getRowActions = (row: Response) => {
        const newActionList = defaultActionList.map((action) => {
            if (!action.ariaLabel) {
                const newAction = { ...action };
                newAction.ariaLabel = `${action.label} ${typeOfForm}${props.getName ? ': ' + props.getName(row) : ''}`;
                return newAction;
            }
            return action;
        });
        return props.getActions ? props.getActions(row, newActionList, typeOfForm) : newActionList;
    };

    const getWorkplanItemName = (row: BaseFormResponse | undefined) => {
        if (rowToDelete) {
            return `${typeOfForm}: ${props.getName(row as Response)}`;
        } else {
            return undefined;
        }
    };

    useEffect(() => {
        passColumnsData();
    }, []);
    return (
        <>
            {copyError && (
                <SimpleAlert variant="left-accent">
                    {`Cannot copy this ${typeOfForm.toLowerCase()}, please refresh the page to
                    try again`}
                </SimpleAlert>
            )}
            <DeleteRowAlert
                deleteAction={deleteRow}
                recordName={getWorkplanItemName(rowToDelete)}
                deleteError={deleteError}
                isOpen={deleteAlert}
                isDeleting={isDeleting}
                rowToDelete={rowToDelete}
                onClose={() => setDeleteAlert(false)}
            />
            <ListTable
                tableCaption={`List of ${tableCaptions}` + ` (page ${page} of ${pages === 0 ? 1 : pages})`}
                columns={columns}
                data={data?.items}
                getActions={getRowActions}
            />
            <ProgressUpdateDialog
                dialogDefaultState={'create'}
                workplanItemId={getRow && getRow.workplanItem.id}
                isOpen={addProgressUpdatePopup}
                setIsOpen={setAddProgressUpdatePopup}
                currentAssignment={currentAssignment?.id}
            />
        </>
    );
};
