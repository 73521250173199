import { AxiosRequestConfig, AxiosResponse } from 'axios';
import saveAs from 'file-saver';

interface StaffWorkplansProps {
    urlPath: string;
    staffId?: string;
    fileName: string;
    makeExcelRequest: (url: string, config?: AxiosRequestConfig) => Promise<AxiosResponse<Blob>>;
    params: {
        page: number;
        pageSize: number;
        access: string;
        faculty: string;
        organisation: string;
        supervisor: string;
        sortedOption: { orderByColumn: string; orderByOrientation: string };
        assignment?: number;
    };
}

export const downloadUserSupervisionStaffWorkplans = async (props: StaffWorkplansProps) => {
    const { urlPath, staffId, fileName, makeExcelRequest, params } = props;

    const currentDate = new Date();
    const formatDate = currentDate.toISOString().split('T')[0];
    const formatTime = currentDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }).replace(':', '-');
    try {
        const workbook = await makeExcelRequest(`excel/${urlPath}`, {
            params,
            responseType: 'blob',
            timeout: 120_000,
        });

        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        const fileExtension = '.xlsx';
        const blob = new Blob([workbook.data], { type: fileType });
        saveAs(blob, `${formatDate}_${formatTime}_${fileName}_${staffId}` + fileExtension);
    } catch (err) {
        console.log(err);
    }
};
