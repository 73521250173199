import React, { ComponentType } from 'react';
import { withDisplayName } from '@/utils/withDisplayName';
import { Resolve } from '@jsonforms/core';
import { useJsonForms } from '@jsonforms/react';

export const withJsonFormsRowCount = <T extends { path: string; count: number }>(Component: ComponentType<T>) =>
    withDisplayName((props: Omit<T, 'count'>) => {
        const { path } = props;
        const ctx = useJsonForms();
        const data = Resolve.data(ctx.core?.data, path);

        const newProps = {
            ...props,
            count: (data || []).length,
        } as T;
        return <Component {...newProps} />;
    }, 'withJsonFormsRowCount');
