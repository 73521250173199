import { StandardListOfFormsProps } from '@/components/pages/dashboard/tiles/bottomTile/bottomTileTables/FormActionTable';
import { PaginationToolbar } from '@/components/Table';
import { TableTopToolBar } from '@/components/Table/TableTopToolBar';
import { ActivityFormResponse } from '@/wpt-lib/interfaces/Activity';
import { BaseFormResponse } from '@/wpt-lib/interfaces/baseFormResponse';
import { FormResponse } from '@/wpt-lib/interfaces/FormResponse';
import { HealthAndSafetyTrainingFormResponse } from '@/wpt-lib/interfaces/HealthAndSafetyTraining';
import { LeaveFormResponse } from '@/wpt-lib/interfaces/Leave';
import { ListResponse } from '@/wpt-lib/interfaces/listResponse';
import { PersonalGoalFormResponse } from '@/wpt-lib/interfaces/PersonalGoal';
import { Workplan } from '@/wpt-lib/interfaces/Workplan';
import { useAsyncLayoutEffect } from '@/utils/hooks/useAsyncEffect';
import { defaultTableParams } from '@/utils/tableUtils';
import { useGetRequest } from '@/utils/useStatefulRequests';
import { Box, Button, Text, usePrevious } from '@chakra-ui/react';
import React, { useState } from 'react';
import {
    ActivityTable,
    ConsiderationTable,
    HealthAndSafetyTrainingTable,
    PersonalGoalTable,
    PlannedLeaveTable,
} from './bottomTileTables';
import { Column } from './bottomTileTables/ListTable';
import StateDisplay from '@/components/StateDisplay/StateDisplay';
import { SimpleAlert } from '@/components/SimpleAlert/SimpleAlert';
import { ConsiderationFormResponse } from '@/wpt-lib/interfaces/Consideration';
import { Category } from '@/components/contexts/WorkplanContext/useWorkplanCategories';

interface ButtonAction {
    label: string;
    icon?: React.ReactElement;
    onClick: () => void;
}

interface BottomTileProps {
    category: Category;
    getActions?: StandardListOfFormsProps<BaseFormResponse>['getActions'];
    workplan: Workplan;
    version?: number;
    buttonAction?: ButtonAction;
    onChange?: () => void;
    userDetails?: string;
    assignmentId?: string;
}

export const BottomTile = (props: BottomTileProps) => {
    const views = {
        userDetails: props.userDetails,
        assignmentId: props.assignmentId,
    };

    const {
        makeRequest: makeGetRequest,
        error: listError,
        loading: isGettingForms,
        responseData: listResponse,
    } = useGetRequest<ListResponse<FormResponse>>();

    const prevActiveCategory = usePrevious(props.category);
    const prevWorkplan = usePrevious(props.workplan);

    const [pageSize, setPageSize] = useState(defaultTableParams.pageSize);
    const [page, setPage] = useState(defaultTableParams.page);
    const [pages, setPages] = useState(1);

    const [orderByColumn, setOrderByColumn] = useState<string>(defaultTableParams.orderByColumn);
    const [orderByOrientation, setOrderByOrientation] = useState<string>(defaultTableParams.orderByOrientation);

    const [columns, setColumns] = useState<Column<BaseFormResponse>[]>([]);

    const [focusPage, setFocusPage] = useState(false);

    const fetchListOfFormData = async (newPage?: number, newOrderByColumn?: string) => {
        try {
            const params = {
                page: newPage ?? page,
                orderByColumn: newOrderByColumn ?? orderByColumn,
                orderByOrientation: orderByOrientation,
                pageSize: pageSize,
                workplan: props.workplan.id,
                version: props.version,
            };
            const res = await makeGetRequest(`${props.category.endpoint}`, {
                params,
            });
            setPages(res.data.info.pages);
            setPage(res.data.info.page);
        } catch (e) {
            //handled by hook
        }
    };

    const getNumberOfColumns = (columns: Column<unknown>[]) => {
        setColumns(columns);
    };

    const tableProps = {
        fetchFormList: fetchListOfFormData,
        getColumns: getNumberOfColumns,
        onChange: props.onChange,
        workplan: props.workplan,
        getActions: props.getActions,
        page: page + 1,
        pages: pages,
        views: views,
        orderByColumn: orderByColumn,
        orderByOrientation: orderByOrientation,
    };

    const renderBottomTileContent = (category: Category) => {
        switch (category.id) {
            case 0:
                return (
                    <PersonalGoalTable {...tableProps} data={listResponse as ListResponse<PersonalGoalFormResponse>} />
                );
            case 1:
                return <ActivityTable {...tableProps} data={listResponse as ListResponse<ActivityFormResponse>} />;
            case 2:
                return <PlannedLeaveTable {...tableProps} data={listResponse as ListResponse<LeaveFormResponse>} />;
            case 3:
                return (
                    <HealthAndSafetyTrainingTable
                        {...tableProps}
                        data={listResponse as ListResponse<HealthAndSafetyTrainingFormResponse>}
                    />
                );
            case 4:
                return (
                    <ConsiderationTable
                        {...tableProps}
                        data={listResponse as ListResponse<ConsiderationFormResponse>}
                    />
                );

            default:
                return null;
        }
    };

    useAsyncLayoutEffect(async () => {
        if (props.workplan !== prevWorkplan || prevActiveCategory !== props.category) {
            await fetchListOfFormData(defaultTableParams.page, defaultTableParams.orderByColumn); // default params when the active category changed
        } else {
            await fetchListOfFormData();
        }
    }, [props.category, props.workplan, props.version, pageSize, page, orderByOrientation, orderByColumn]);
    return (
        <StateDisplay
            loading={isGettingForms}
            error={!!listError}
            data={listResponse}
            errorRender={() => (
                <SimpleAlert variant="subtle">
                    {`Could not load ${props.category.label.toLowerCase()} data`}
                </SimpleAlert>
            )}
            render={(data) => (
                <Box>
                    <TableTopToolBar
                        listResponse={data}
                        loading={isGettingForms}
                        columns={columns}
                        orderByColumn={orderByColumn}
                        orderByOrientation={orderByOrientation}
                        setOrderByColumn={setOrderByColumn}
                        setOrderByOrientation={setOrderByOrientation}
                    >
                        {props.buttonAction && (
                            <Button
                                h={12}
                                whiteSpace={'normal'}
                                colorScheme={'blue'}
                                onClick={props.buttonAction.onClick}
                                display={'flex'}
                                gridGap={2}
                                minW={40}
                            >
                                {props.buttonAction.icon}
                                <Text display={{ base: 'none', md: 'block' }}>{props.buttonAction.label}</Text>
                            </Button>
                        )}
                    </TableTopToolBar>
                    {renderBottomTileContent(props.category)}
                    <PaginationToolbar
                        setPageSize={setPageSize}
                        setNewPage={setPage}
                        pageSize={pageSize}
                        pages={pages}
                        page={page}
                        focusPage={focusPage}
                        setFocusPage={setFocusPage}
                    />
                </Box>
            )}
        />
    );
};
