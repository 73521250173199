import React, { useState } from 'react';
import { Badge, Button, HStack, Text, VStack } from '@chakra-ui/react';
import { Comment } from '@/wpt-lib/interfaces/Comment';
import { SingleCommentOptions } from '@/components/pages/workplan/comment/SingleCommentOptions';
import { SimpleAlert } from '@/components/SimpleAlert/SimpleAlert';
import { SelectedCommentAction } from '@/components/pages/workplan/comment/CommentsTile';
import { Assignment } from '@/wpt-lib/interfaces/Assignment';
import TextHighlighting from '@/components/TextHighlighting/TextHighlighting';
import { formatName } from '@/wpt-lib/utils/formatName';
import AutoExpandTextArea from '@/components/pages/workplan/comment/AutoExpandTextArea';
import { WorkplanComment, WorkplanCommentSection } from '@/wpt-lib/interfaces/WorkplanComment';

interface CommentProps<T> {
    comment: T & { system?: boolean };
    selectedComment?: SelectedCommentAction<T>;
    setSelectedComment?: (comment: SelectedCommentAction<T> | undefined) => void;
    editError?: unknown;
    onDelete?: (arg: T) => void;
    onEdit?: (arg: T, newComment: string) => void;
    isChanged: boolean;
    currentAssignment?: Assignment;
}

const SingleComment = <T extends Comment>({
    selectedComment,
    setSelectedComment,
    comment,
    onDelete,
    onEdit,
    isChanged,
    editError,
}: CommentProps<T>) => {
    const [editComment, setEditComment] = useState('');
    const triggerEditMode = () => {
        setEditComment(comment.comment);
        setSelectedComment &&
            setSelectedComment({
                comment: comment,
                type: 'edit',
            });
    };

    const handleEdit = async () => {
        await (onEdit && onEdit(comment, editComment));
    };

    const cancelEdit = () => {
        setSelectedComment && setSelectedComment(undefined);
    };

    const generateCommentAuthorString = (comment: T): string =>
        `${new Date(comment.updatedAt).toLocaleDateString('pt-PT')} ${formatName(comment.author?.user)}: `;

    return (
        <HStack justifyContent={'space-between'} w={'full'} pr={3}>
            <HStack justifyContent={'flex-start'} w={'full'}>
                {selectedComment?.comment?.id === comment.id &&
                (selectedComment?.type === 'edit' || selectedComment?.type === 'editError') ? (
                    <VStack alignItems={'center'} w={'full'} borderWidth={1} rounded={'md'}>
                        <AutoExpandTextArea
                            data={editComment}
                            border="none"
                            placeholder="Edit your comment"
                            aria-label="Edit Workplan Comment"
                            onChange={setEditComment}
                            autoFocus={true}
                        />
                        <HStack p={2} w={'full'} alignItems={'center'} justifyContent={'flex-end'}>
                            {!!editError && selectedComment?.type === 'editError' && (
                                <SimpleAlert variant={'left-accent'}>
                                    <Text>Cannot edit this comment, please refresh the page and try again</Text>
                                </SimpleAlert>
                            )}
                            <Button colorScheme="blue" onClick={() => handleEdit()}>
                                Save
                            </Button>
                            <Button onClick={() => cancelEdit()}>Cancel</Button>
                        </HStack>
                    </VStack>
                ) : (
                    <HStack alignItems={'flex-start'}>
                        <Text fontWeight={'semibold'}>
                            <TextHighlighting query={isChanged ? generateCommentAuthorString(comment) : ''} version={1}>
                                {generateCommentAuthorString(comment)}
                            </TextHighlighting>
                            {(comment as unknown as WorkplanComment).section === WorkplanCommentSection.END_YEAR && (
                                <Badge colorScheme="blue" fontSize="1em" marginRight="1" textTransform="none">
                                    End of year comment
                                </Badge>
                            )}
                            {(comment as unknown as WorkplanComment).section ===
                                WorkplanCommentSection.END_YEAR_SUPERSEDED && (
                                <Badge colorScheme="orange" fontSize="1em" marginRight="1" textTransform="none">
                                    Superseded end of year comment
                                </Badge>
                            )}
                            <Text as={'span'} fontWeight={'normal'} fontStyle={comment.system ? 'italic' : 'normal'}>
                                {comment.comment}
                            </Text>
                        </Text>
                    </HStack>
                )}
            </HStack>
            {(onEdit || onDelete) &&
                (selectedComment?.comment?.id !== comment.id ||
                    (selectedComment?.type !== 'edit' && selectedComment?.type !== 'editError')) && (
                    <SingleCommentOptions comment={comment} onEdit={() => triggerEditMode()} onDelete={onDelete} />
                )}
        </HStack>
    );
};

export default SingleComment;
