import { Circle, Polygon, Rect } from '@react-pdf/renderer';
import * as React from 'react';

export function crossPattern(x: number, y: number, widthPattern: number, barHeight: number) {
    const rows = [];
    for (let k = 0; k < barHeight / 10; k++) {
        for (let j = 0; j < widthPattern / 10; j++) {
            const pointsPoly1 = [x + j * 15, y + 7 + k * 15, x + j * 15 + 7, y + k * 15];
            const pointsPoly2 = [x + j * 15 + 7, y + 7 + k * 15, x + j * 15, y + k * 15];
            rows.push(
                <Rect key={j} stroke="none" x={x} y={y} height="10" width="10" fill="none">
                    <Polygon
                        points={`${pointsPoly1[0]},${pointsPoly1[1]} ${pointsPoly1[2]},${pointsPoly1[3]}`}
                        fill="none"
                        stroke="white"
                        strokeWidth={1.5}
                    />
                    <Polygon
                        points={`${pointsPoly2[0]},${pointsPoly2[1]} ${pointsPoly2[2]},${pointsPoly2[3]}`}
                        fill="none"
                        stroke="white"
                        strokeWidth={1.5}
                    />
                </Rect>,
            );
        }
    }

    return (
        <Rect x={x} y={y} height={barHeight} width={widthPattern} fill="none">
            {rows}
        </Rect>
    );
}

export function circlePattern(x: number, y: number, widthPattern: number, barHeight: number) {
    const rows = [];
    for (let j = 0; j < widthPattern / 10; j++) {
        rows.push(<Circle key={j} cx={x + 5 + j * 10} cy={y + 5} r={3} fill="white" />);
        for (let k = 0; k < barHeight / 10; k++) {
            rows.push(<Circle key={`${j}-${k}`} cx={x + 5 + j * 10} cy={y + 5 + k * 10} r={3} fill="white" />);
        }
    }

    return (
        <Rect stroke="none" x={x} y={y} height={barHeight} width={widthPattern} fill="none">
            {rows}
        </Rect>
    );
}

export function checkersPattern(x: number, y: number, widthPattern: number, barHeight: number) {
    const rows = [];
    for (let j = 0; j < widthPattern / 10; j++) {
        rows.push(
            <Rect key={j} x={x + j * 10} y={y} height={10} width={10} fill="none">
                <Rect x={x + j * 10} y={y} height="5" width="5" fill="white" />
                <Rect height="5" width="5" x={x + 5 + j * 10} y={y + 5} fill="white" />
            </Rect>,
        );
        for (let k = 0; k < barHeight / 10; k++) {
            rows.push(
                <Rect key={`${j}-${k}`} x={x + j * 10} y={y + k * 10} height={10} width={10} fill="none">
                    <Rect x={x + j * 10} y={y + k * 10} height="5" width="5" fill="white" />
                    <Rect height="5" width="5" x={x + 5 + j * 10} y={y + 5 + k * 10} fill="white" />
                </Rect>,
            );
        }
    }
    return (
        <Rect x={x} y={y} height={barHeight} width={widthPattern} fill="none">
            {rows}
        </Rect>
    );
}

export function plusPattern(x: number, y: number, widthPattern: number, barHeight: number) {
    const rows = [];
    for (let k = 0; k < barHeight / 10; k++) {
        for (let j = 0; j < widthPattern / 10; j++) {
            const pointsPoly1 = [x + j * 15, y + 7 + k * 15, x + j * 15 + 7, y + 7 + k * 15];
            const pointsPoly2 = [x + j * 15 + 3.5, y + 3.5 + k * 15, x + j * 15 + 3.5, y + 10.5 + k * 15];
            rows.push(
                <Rect key={j} stroke="none" x={x} y={y} height="10" width="10" fill="none">
                    <Polygon
                        points={`${pointsPoly1[0]},${pointsPoly1[1]} ${pointsPoly1[2]},${pointsPoly1[3]}`}
                        fill="none"
                        stroke="white"
                        strokeWidth={1.5}
                    />
                    <Polygon
                        points={`${pointsPoly2[0]},${pointsPoly2[1]} ${pointsPoly2[2]},${pointsPoly2[3]}`}
                        fill="none"
                        stroke="white"
                        strokeWidth={1.5}
                    />
                </Rect>,
            );
        }
    }

    return (
        <Rect x={x} y={y} height={barHeight} width={widthPattern} fill="none">
            {rows}
        </Rect>
    );
}

export function wavePattern(x: number, y: number, widthPattern: number, barHeight: number) {
    const rows = [];
    for (let k = 0; k < barHeight / 10; k++) {
        for (let j = 0; j < widthPattern / 6; j++) {
            const pointsPoly1 = [x + j * 6, y + 7 + k * 10, x + j * 6 + 7, y + k * 10];
            const pointsPoly2 = [x + j * 6 + 7, y + 7 + k * 10, x + j * 6, y + k * 10];
            rows.push(
                <Rect key={j} stroke="none" x={x} y={y} height="10" width="10" fill="none">
                    {j % 2 === 0 && (
                        <Polygon
                            points={`${pointsPoly1[0]},${pointsPoly1[1]} ${pointsPoly1[2]},${pointsPoly1[3]}`}
                            fill="none"
                            stroke="white"
                            strokeWidth={1.5}
                        />
                    )}
                    {j % 2 !== 0 && (
                        <Polygon
                            points={`${pointsPoly2[0]},${pointsPoly2[1]} ${pointsPoly2[2]},${pointsPoly2[3]}`}
                            fill="none"
                            stroke="white"
                            strokeWidth={1.5}
                        />
                    )}
                </Rect>,
            );
        }
    }

    return (
        <Rect x={x} y={y} height={barHeight} width={widthPattern} fill="none">
            {rows}
        </Rect>
    );
}
