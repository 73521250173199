import { mapWorkplanStateToString, Workplan } from '@/wpt-lib/interfaces/Workplan';
import { Flex, HStack, Text } from '@chakra-ui/react';
import React from 'react';
import WorkplanStateDisplay from '../contexts/WorkplanContext/WorkplanStateDisplay';
import { Tile } from '../Tile';
import useWorkplanVersion from '@/components/contexts/VersionContext/useWorkplanVersion';
import useNavigateOptions from '@/components/contexts/NaviateContext/useNavigateOptions';
import { formatName } from '@/wpt-lib/utils/formatName';

const CurrentWorkplanHeader = () => {
    const { version, current } = useWorkplanVersion();
    const { supervisor } = useNavigateOptions();

    const renderBasedonYear = (workplan: Workplan) => {
        const currentYear = new Date().getFullYear();
        const workplanYear = workplan.year;
        const workplanVersion = 'V' + (version ?? workplan.currentVersion.version);

        const workplanState = current ? mapWorkplanStateToString[workplan.state] : 'Superseded';
        const workplanStatus = `${workplanYear} - ${workplanVersion} -  ${workplanState}`;

        if (currentYear > workplanYear) {
            return `Past Workplan: ${workplanStatus}`;
        }
        if (currentYear < workplanYear) {
            return `Future Workplan: ${workplanStatus}`;
        }
        return `Current Workplan: ${workplanStatus}`;
    };

    const renderWorkplanHeaderContent = (workplan: Workplan) => {
        if (supervisor) {
            return formatName(workplan.assignment?.user);
        } else {
            return `${renderBasedonYear(workplan)}`;
        }
    };

    return (
        <Tile colspan={2}>
            <WorkplanStateDisplay
                render={(workplan) => {
                    return (
                        <>
                            <Flex justifyContent={'space-between'} alignItems={'center'}></Flex>
                            <Flex justifyContent={'space-between'} alignItems={'center'}>
                                <HStack>
                                    <Text fontSize={'lg'} fontWeight={'semibold'} textColor={'black'}>
                                        {renderWorkplanHeaderContent(workplan)}
                                    </Text>
                                    {supervisor && <Text>{workplan.assignment?.name}</Text>}
                                </HStack>
                                <HStack spacing={6}></HStack>
                            </Flex>
                        </>
                    );
                }}
            />
        </Tile>
    );
};

export { CurrentWorkplanHeader };
