import { Heading, HeadingProps } from '@chakra-ui/react';
import React, { useRef, useLayoutEffect } from 'react';

const FocusHeader = ({ children, ...headerProps }: HeadingProps) => {
    const headerRef = useRef<HTMLHeadingElement>(null);
    useLayoutEffect(() => {
        if (headerRef.current) {
            headerRef.current.focus();
        }
    }, []);
    return (
        <Heading ref={headerRef} tabIndex={-1} {...headerProps} outline={'none'}>
            {children}
        </Heading>
    );
};

export default FocusHeader;
