import React from 'react';
import { ControlProps, isOneOfEnumControl, OwnPropsOfEnum, RankedTester, rankWith } from '@jsonforms/core';
import { ChakraFormInput } from '../chakra-controls/ChakraFormInput';
import { ChakraSelect } from '../chakra-controls';
import { withNamedJsonFormsOneOfEnumProps } from '@//forms-renderers/chakra-ui/utils/withNamedJsonFormsHOC';

export const ChakraOneOfEnumControl = (props: ControlProps & OwnPropsOfEnum) => {
    return <ChakraFormInput {...props} input={ChakraSelect} />;
};

export const chakraOneOfEnumControlTester: RankedTester = rankWith(5, isOneOfEnumControl);

export default withNamedJsonFormsOneOfEnumProps(ChakraOneOfEnumControl);
