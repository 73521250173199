import InlineHelpBubble from '@/components/InlineHelpBubble';
import { namedMemo } from '@/utils/namedMemo';
import { Flex, Radio, RadioGroup, RadioProps, Stack } from '@chakra-ui/react';
import { EnumCellProps, WithClassname } from '@jsonforms/core';
import merge from 'lodash/merge';
import React from 'react';
import TextHighlighting from '@/components/TextHighlighting/TextHighlighting';
import { WorkplanItemFormConfig } from '@/components/WorkplanItemForm/WorkplanItemForm';

export const ChakraRadioGroup = namedMemo('ChakraRadioGroup', (props: EnumCellProps & WithClassname) => {
    const { data, config, className, id, enabled, uischema, isValid, path, handleChange, options } = props;
    const appliedUiSchemaOptions = merge({}, config, uischema.options);
    const newConfig = config as WorkplanItemFormConfig;
    const hasChanges = newConfig.formChanges[path];
    const tooltips = appliedUiSchemaOptions?.tooltips as
        | {
              [key: string]: string;
          }
        | undefined;

    return (
        <RadioGroup
            id={id}
            value={data}
            onChange={(newValue) => {
                if (enabled) handleChange(path, newValue);
            }}
            className={className}
        >
            <Stack direction={appliedUiSchemaOptions.direction === 'row' ? 'row' : 'column'}>
                {options?.map((option, i) => {
                    const radioProps: RadioProps = {
                        id: `${id}-option-${i}`,
                        value: option.value,
                        isReadOnly: appliedUiSchemaOptions.readonly || !enabled,
                        isInvalid: !isValid,
                    };
                    const tooltip = tooltips?.[option.value];
                    if (!tooltip) {
                        return (
                            <Radio key={option.value} {...radioProps}>
                                <TextHighlighting
                                    version={1}
                                    query={data === option.value && hasChanges ? option.label : ''}
                                >
                                    {option.label}
                                </TextHighlighting>
                            </Radio>
                        );
                    } else {
                        const tooltipId = `${id}-option-${i}-tooltip`;
                        return (
                            <Flex key={option.value}>
                                <Radio {...radioProps}>
                                    <TextHighlighting
                                        version={1}
                                        query={data === option.value && hasChanges ? option.label : ''}
                                    >
                                        {option.label}
                                    </TextHighlighting>
                                </Radio>
                                <InlineHelpBubble label={tooltip} id={tooltipId} />
                            </Flex>
                        );
                    }
                })}
            </Stack>
        </RadioGroup>
    );
});
