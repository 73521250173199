import { FormActionTable } from '@/components/pages/dashboard/tiles/bottomTile/bottomTileTables/FormActionTable';
import { FormTableProps } from '@/components/pages/dashboard/tiles/bottomTile/bottomTileTables/FormTableInterfaces';

import React from 'react';
import { ConsiderationFormResponse } from '@/wpt-lib/interfaces/Consideration';
import { useWorkplanLabels } from '@/components/contexts/LabelsContext/useLabels';
import { createdColumn } from '@/components/pages/dashboard/tiles/bottomTile/bottomTileTables/PersonalGoalTable';
import { optionalLabel } from '@/wpt-lib/utils/label';

export const ConsiderationTable = (props: FormTableProps<ConsiderationFormResponse>) => {
    const types = useWorkplanLabels(props.workplan, 'CONSIDERATION', 'considerationType');

    const columns = [
        {
            name: 'Title',
            field: 'considerationTitle',
            highlight: (item: ConsiderationFormResponse) => item.changed,
            getData: (item: ConsiderationFormResponse) => item.considerationTitle,
        },
        {
            name: 'Consideration Type',
            field: 'considerationType',
            getData: (item: ConsiderationFormResponse) =>
                (item.considerationType ?? '')
                    .split(',')
                    .map((c) => optionalLabel(types, c))
                    .join(', '),
        },
        {
            name: 'Created',
            field: 'createdAt',
            getData: createdColumn,
        },
    ];

    return (
        <FormActionTable
            getColumns={props.getColumns}
            endpoint={'consideration'}
            typeOfForm={'Consideration'}
            urlLabel={'consideration'}
            tableCaptions={'Considerations'}
            columns={columns}
            data={props.data}
            fetchFormList={props.fetchFormList}
            onChange={props.onChange}
            getActions={props.getActions}
            workplan={props.workplan}
            getName={(item: ConsiderationFormResponse) => item.considerationTitle ?? ''}
            page={props.page}
            pages={props.pages}
            orderByOrientation={props.orderByOrientation}
            orderByColumn={props.orderByColumn}
        />
    );
};
