import React from 'react';
import {
    and,
    ControlProps,
    isOneOfEnumControl,
    optionIs,
    OwnPropsOfEnum,
    RankedTester,
    rankWith,
} from '@jsonforms/core';
import { ChakraFormInput } from '../chakra-controls/ChakraFormInput';
import { ChakraRadioGroup } from '../chakra-controls';
import { withNamedJsonFormsOneOfEnumProps } from '@//forms-renderers/chakra-ui/utils/withNamedJsonFormsHOC';

export const ChakraOneOfRadioGroupControl = (props: ControlProps & OwnPropsOfEnum) => {
    return (
        <ChakraFormInput
            {...props}
            input={ChakraRadioGroup}
            formControlProps={{ as: 'fieldset' }}
            labelProps={{ as: 'legend' }}
        />
    );
};

export const chakraOneOfRadioGroupControlTester: RankedTester = rankWith(
    6,
    and(isOneOfEnumControl, optionIs('format', 'radio')),
);

export default withNamedJsonFormsOneOfEnumProps(ChakraOneOfRadioGroupControl);
