import { Box, Link, Text } from '@chakra-ui/react';
import * as React from 'react';

const Footer = () => {
    return (
        <Box as="footer" h={10} bgColor={'gray.200'}>
            <Text fontWeight={'medium'} textAlign={'center'}>
                {`Read UTS's`}{' '}
                <Link
                    href="https://www.uts.edu.au/about/uts-governance/policies/uts-policy/privacy-policy"
                    isExternal={true}
                    textDecoration={'underline'}
                >
                    Privacy Statement
                </Link>
            </Text>
        </Box>
    );
};
export default Footer;
