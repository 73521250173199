import React from 'react';
import { Highlight } from '@chakra-ui/react';

export interface HighlightingProps {
    version: number;
    query: string;
    children: string;
}

const TextHighlighting = (props: HighlightingProps) => {
    const getVersionHighlightStyle = () => {
        switch (props.version) {
            case 1:
                return { bg: 'yellow.500', color: 'black' };
            case 2:
                return { bg: 'purple.600', color: 'white' };
            case 3:
                return { bg: 'orange.500', color: 'black' };
            case 4:
                return { bg: 'green.500', color: 'black' };
            default:
                return { bg: 'yellow.200', color: 'black' };
        }
    };
    return (
        <Highlight
            query={props.query}
            styles={{
                p: 2,
                fontWeight: 'semibold',
                rounded: 'md',
                ...getVersionHighlightStyle(),
            }}
        >
            {props.children}
        </Highlight>
    );
};
export default TextHighlighting;
