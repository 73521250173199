import { useWorkplanLabels } from '@/components/contexts/LabelsContext/useLabels';
import { FormActionTable } from '@/components/pages/dashboard/tiles/bottomTile/bottomTileTables/FormActionTable';
import { FormTableProps } from '@/components/pages/dashboard/tiles/bottomTile/bottomTileTables/FormTableInterfaces';
import { PersonalGoalFormResponse } from '@/wpt-lib/interfaces/PersonalGoal';
import React from 'react';
import { optionalLabel } from '@/wpt-lib/utils/label';
import { BaseFormResponse } from '@/wpt-lib/interfaces/baseFormResponse';

export function createdColumn(item: BaseFormResponse): string {
    const createdDate = new Date(item.createdAt).toLocaleDateString();
    return `${createdDate} ${item.workplanItem.approved ? '(approved)' : ''}`;
}

type PersonalGoalTableProps = FormTableProps<PersonalGoalFormResponse>;

export const PersonalGoalTable = (props: PersonalGoalTableProps) => {
    const goalTypes = useWorkplanLabels(props.workplan, 'PERSONAL_GOAL', 'goalType');

    const columns = [
        {
            name: 'Name',
            field: 'goalName',
            highlight: (item: PersonalGoalFormResponse) => item.changed,
            getData: (item: PersonalGoalFormResponse) => item.goalName,
        },
        {
            name: 'Goal Type',
            field: 'goalType',
            getData: (row: PersonalGoalFormResponse) =>
                (row.goalType ?? '')
                    .split(',')
                    .map((t) => optionalLabel(goalTypes, t))
                    .join(', '),
        },
        {
            name: 'Created',
            field: 'createdAt',
            getData: createdColumn,
        },
    ];

    return (
        <FormActionTable
            getColumns={props.getColumns}
            endpoint={'career-goal'}
            typeOfForm={'Goal'}
            urlLabel={'goal'}
            tableCaptions={'Goals'}
            columns={columns}
            data={props.data}
            fetchFormList={props.fetchFormList}
            onChange={props.onChange}
            getActions={props.getActions}
            workplan={props.workplan}
            getName={(item) => item.goalName ?? ''}
            page={props.page}
            pages={props.pages}
            orderByOrientation={props.orderByOrientation}
            orderByColumn={props.orderByColumn}
        />
    );
};
