import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { usePrevious } from '@/utils/hooks/usePrevious';

export default function ScrollToTop() {
    const location = useLocation();
    const prevLocation = usePrevious(location);

    useEffect(() => {
        if (
            prevLocation !== undefined &&
            location.pathname + location.search !== prevLocation.pathname + prevLocation.search
        ) {
            window.scrollTo(0, 0);
        }
    }, [location]);

    return null;
}
