import { useAssignment } from '@/components/contexts/AssignmentContext/useAssignment';
import { withCurrentAssignment } from '@/components/contexts/AssignmentContext/withCurrentAssignment';
import { WorkplanIdProvider } from '@/components/contexts/WorkplanContext/providers';
import WorkplanStateDisplay from '@/components/contexts/WorkplanContext/WorkplanStateDisplay';
import FocusHeader from '@/components/FocusHeader/FocusHeader';
import { WorkplanItemHeader } from '@/components/Header/WorkplanItemHeader';
import { CommentsTile } from '@/components/pages/workplan/comment/CommentsTile';
import { ProgressUpdateTile } from '@/components/pages/workplan/workplanItemAction/ProgressUpdateTile';
import { Tile } from '@/components/Tile';
import { ActivityFormResponse } from '@/wpt-lib/interfaces/Activity';
import { Assignment } from '@/wpt-lib/interfaces/Assignment';
import { HealthAndSafetyTrainingFormResponse } from '@/wpt-lib/interfaces/HealthAndSafetyTraining';
import { LeaveFormResponse } from '@/wpt-lib/interfaces/Leave';
import { PersonalGoalFormResponse } from '@/wpt-lib/interfaces/PersonalGoal';
import { useAsyncEffect } from '@/utils/hooks/useAsyncEffect';
import { isWorkplanCompleted, isWorkplanReadonly } from '@/utils/isWorkplanReadonly';
import { joinPaths } from '@/utils/joinPaths';
import { useGetRequest } from '@/utils/useStatefulRequests';
import { Box, Button, Grid, GridItem, HStack } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { WorkplanVersionParamProvider } from '@/components/contexts/VersionContext/withWorkplanVersionParam';
import useWorkplanVersion from '@/components/contexts/VersionContext/useWorkplanVersion';
import { parseFormData } from '@/wpt-lib/utils/formData';
import { useUserSupervisionFilters } from '@/components/contexts/UserSupervisionFiltersContext/useUserSupervisionFilters';
import { DashboardHeaderTile } from '@/components/Header/DashboardHeaderTile';
import { ConsiderationFormResponse } from '@/wpt-lib/interfaces/Consideration';
import useNavigateOptions from '@/components/contexts/NaviateContext/useNavigateOptions';
import useUserSupervisionBreadcrumbs from '@/components/pages/Admin/UserSupervision/useUserSupervisionBreadcrumbs';
import { useSearchParams } from 'react-router-dom';
import { ListResponse } from '@/wpt-lib/interfaces/listResponse';
import { FormResponse } from '@/wpt-lib/interfaces/FormResponse';
export const useParam = () => {
    const { workplanId, workplanItemId, formId, version } = useParams<{
        workplanId: string;
        workplanItemId: string;
        formId: string;
        version: string;
    }>();
    return { workplanId, workplanItemId, formId, version };
};

export const getWorkplanItem = <T,>(
    workplanItemType: string,
    currentAssignment: Assignment | undefined,
    formId?: string,
) => {
    const { makeRequest: makeGetRequest, responseData: workplanItemData } = useGetRequest<T>();

    useAsyncEffect(async () => {
        try {
            if (currentAssignment)
                await makeGetRequest(joinPaths(workplanItemType, `${formId}`), {
                    params: {
                        assignment: currentAssignment.id,
                    },
                });
        } catch (e) {
            // handled by hook
        }
    }, [formId, currentAssignment]);

    return workplanItemData;
};

type WorkplanItemActionType =
    | ActivityFormResponse
    | PersonalGoalFormResponse
    | LeaveFormResponse
    | HealthAndSafetyTrainingFormResponse
    | ConsiderationFormResponse
    | undefined;

const parseWorkplanItem = <T,>(workplanItemData: WorkplanItemActionType) => {
    if (workplanItemData) {
        const parsedWorkplanItem = parseFormData(workplanItemData.workplanItem.formData);
        return parsedWorkplanItem as T;
    }
};

export const useWorkplanItemData = <FormResponse extends WorkplanItemActionType, WorkplanItemAction>(type: string) => {
    const { currentAssignment } = useAssignment();
    const params = useParam();
    const data = getWorkplanItem<FormResponse>(type, currentAssignment, params.formId);

    const parsedData = parseWorkplanItem<WorkplanItemAction>(data);
    return {
        parsedData,
        workplanItem: data,
    };
};

export interface Params {
    workplanId: string;
    workplanItemId: string;
    formId: string;
    version: string;
}

interface WorkplanItemActionProps {
    renderForm: () => JSX.Element;
    workplanItemType: string;
    currentAssignment: Assignment;
    params: Params;
    name?: string;
    typeLabel: string;
    workplanId?: string;
    assignmentId?: string;
    versionId?: string;
    userDetails?: string;
}

const WorkplanItemAction = ({
    workplanItemType,
    currentAssignment,
    params,
    name,
    typeLabel,
    renderForm,
}: WorkplanItemActionProps) => {
    const search = useUserSupervisionFilters().params;
    const { supervisor, admin, makePathCustom } = useNavigateOptions();
    const [listData, setListData] = useState<ListResponse<FormResponse>>();
    const userBreadcrumbs = useUserSupervisionBreadcrumbs(2);
    const parameters = params;
    const [searchParams] = useSearchParams();
    const orderByColumn = searchParams.getAll('orderByColumn')[0];
    const orderByOrientation = searchParams.getAll('orderByOrientation')[0];
    const { makeRequest: makeGetRequest } = useGetRequest<ListResponse<FormResponse>>();
    const fetchListOfFormData = async () => {
        try {
            const params = {
                orderByColumn: orderByColumn,
                orderByOrientation: orderByOrientation,
                workplan: parameters.workplanId,
                version: parameters.version,
            };
            const res = await makeGetRequest(`${workplanItemType}`, {
                params,
            });
            setListData(res.data);
        } catch (e) {
            //handled by hook
        }
    };

    useAsyncEffect(async () => {
        await fetchListOfFormData();
    }, []);

    const { navigatePathCustom } = useNavigateOptions();

    const [nextWorkplanIdFromSequence, setNextWorkplanIdFromSequence] = useState<string>();
    function navigatePrevious() {
        listData?.items.map((item, i) => {
            if (item.workplanItem.id.toString() === parameters.workplanItemId && i > 0) {
                setNextWorkplanIdFromSequence(listData?.items[i - 1].workplanItem.id.toString());
                navigatePathCustom({
                    path: [
                        parameters.workplanId,
                        item.workplanItem.workplanVersion.version,
                        typeLabel.toLowerCase(),
                        listData?.items[i - 1].id,
                        listData?.items[i - 1].workplanItem.id,
                    ],
                    search: `${search}&orderByOrientation=${orderByOrientation}&orderByColumn=${orderByColumn}`,
                });
            }
        });
    }

    function navigateNext() {
        listData?.items.map((item, i) => {
            if (item.workplanItem.id.toString() === parameters.workplanItemId && i < listData?.items.length - 1) {
                setNextWorkplanIdFromSequence(listData?.items[i + 1].workplanItem.id.toString());
                navigatePathCustom({
                    path: [
                        parameters.workplanId,
                        item.workplanItem.workplanVersion.version,
                        typeLabel.toLowerCase(),
                        listData?.items[i + 1].id,
                        listData?.items[i + 1].workplanItem.id,
                    ],
                    search: `${search}&orderByOrientation=${orderByOrientation}&orderByColumn=${orderByColumn}`,
                });
            }
        });
    }
    return (
        <WorkplanIdProvider id={parseInt(params.workplanId)}>
            <WorkplanVersionParamProvider>
                <Box w={'100%'} p={6} pb={0}>
                    <DashboardHeaderTile readonly={true} />
                </Box>
                <WorkplanStateDisplay
                    render={(workplan) => {
                        const { version, workplanVersion, current } = useWorkplanVersion();
                        const getBreadcrumbItems = () => {
                            const items = [];
                            if (supervisor) {
                                items.push({
                                    label: 'Supervising Workplans',
                                    path: `/supervisor`,
                                    isCurrent: false,
                                });
                            } else if (admin) {
                                items.push({
                                    label: 'Staff Workplans',
                                    path: `/admin?tab=supervision&${search}`,
                                    isCurrent: false,
                                });
                            }

                            items.push(...userBreadcrumbs);
                            items.push({
                                label: `Workplan ${workplan.year} - Version ${workplanVersion?.version ?? ''}`,
                                path: makePathCustom({
                                    path: [workplan.id, workplanVersion?.version ?? ''],
                                    search,
                                }),
                                isCurrent: false,
                            });
                            items.push({
                                label: `View ${typeLabel}`,
                                path: '#',
                                isCurrent: true,
                            });
                            return items;
                        };
                        const readOnly = !current || isWorkplanCompleted(workplan) || supervisor || admin;
                        const index = listData?.items.findIndex(
                            (item) => item.workplanItem.id.toString() === params.workplanItemId,
                        );

                        return (
                            <span style={{ width: '100%' }} key={nextWorkplanIdFromSequence}>
                                <Grid
                                    w={'100%'}
                                    templateColumns={{
                                        sm: 'repeat(1, 1fr)',
                                        lg: 'repeat(2, 1fr)',
                                    }}
                                    gap={6}
                                    p={6}
                                >
                                    <Tile colspan={2}>
                                        <Grid templateColumns="repeat(2, 1fr)">
                                            <GridItem w="100%">
                                                <WorkplanItemHeader
                                                    breadCrumbItems={getBreadcrumbItems()}
                                                    workplan={workplan}
                                                    name={name}
                                                    workplanItemType={typeLabel}
                                                />
                                            </GridItem>
                                            <GridItem w="100%">
                                                <HStack gap={4} justifyContent={'end'} wrap={'wrap'}>
                                                    <Button
                                                        colorScheme={'blue'}
                                                        onClick={() => navigatePrevious()}
                                                        isDisabled={!listData || index === 0}
                                                    >
                                                        Previous {typeLabel}
                                                    </Button>
                                                    <Button
                                                        colorScheme={'blue'}
                                                        onClick={() => navigateNext()}
                                                        isDisabled={!listData || index === listData.items.length - 1}
                                                    >
                                                        Next {typeLabel}
                                                    </Button>
                                                </HStack>
                                            </GridItem>
                                        </Grid>
                                    </Tile>
                                    {workplanItemType !== 'health-and-safety-training' && (
                                        <Tile
                                            colspan={2}
                                            heading={`${typeLabel.toUpperCase()} RECORD`}
                                            collapsable
                                            defaultOpen
                                        >
                                            {renderForm()}
                                        </Tile>
                                    )}
                                    <Tile
                                        colspan={2}
                                        overflowX={'auto'}
                                        heading={
                                            <FocusHeader size={'small'} textColor={'blue.500'}>
                                                Progress Updates
                                            </FocusHeader>
                                        }
                                    >
                                        <ProgressUpdateTile
                                            isReadonly={readOnly || isWorkplanReadonly(workplan)}
                                            workplanItemId={nextWorkplanIdFromSequence || params.workplanItemId}
                                            workplan={workplan}
                                            version={version}
                                        />
                                    </Tile>
                                    <Tile colspan={2} heading={`${typeLabel.toUpperCase()} COMMENTS`}>
                                        <CommentsTile
                                            workplan={workplan}
                                            version={version}
                                            isReadonly={!current || isWorkplanReadonly(workplan, supervisor || admin)}
                                            currentAssignment={currentAssignment}
                                            baseApiUrl={`${workplanItemType}/${params.workplanItemId}`}
                                        />
                                    </Tile>
                                </Grid>
                                <HStack
                                    gap={4}
                                    width={'100%'}
                                    paddingLeft={'24px'}
                                    paddingRight={'24px'}
                                    justifyContent={'space-between'}
                                    wrap={'wrap'}
                                >
                                    <Button
                                        colorScheme={'blue'}
                                        onClick={() => navigatePrevious()}
                                        isDisabled={!listData || index === 0}
                                    >
                                        Previous {typeLabel}
                                    </Button>
                                    <Button
                                        colorScheme={'blue'}
                                        onClick={() => navigateNext()}
                                        isDisabled={!listData || index === listData.items.length - 1}
                                    >
                                        Next {typeLabel}
                                    </Button>
                                </HStack>
                            </span>
                        );
                    }}
                />
            </WorkplanVersionParamProvider>
        </WorkplanIdProvider>
    );
};

export default withCurrentAssignment(WorkplanItemAction);
