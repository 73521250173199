import React from 'react';
import { and, ControlProps, isStringControl, isMultiLineControl, RankedTester, rankWith } from '@jsonforms/core';
import { ChakraFormInput } from '../chakra-controls/ChakraFormInput';
import { ChakraTextarea } from '../chakra-controls';
import { withNamedJsonFormsControlProps } from '@/forms-renderers/chakra-ui/utils/withNamedJsonFormsHOC';

export const ChakraTextareaControl = (props: ControlProps) => <ChakraFormInput {...props} input={ChakraTextarea} />;

export const chakraTextareaControlTester: RankedTester = rankWith(1, and(isStringControl, isMultiLineControl));

export default withNamedJsonFormsControlProps(ChakraTextareaControl);
