import { WorkloadStaffType } from '@/wpt-lib/interfaces/Workplan';

export const categoryFieldValues: {
    [key in 'Teaching Only' | 'Research Only' | 'Teaching and Research']: {
        teaching: string;
        research: string;
        service: string;
        seniorStaff: string;
        professionalStaff: string;
    };
} = {
    'Teaching Only': {
        teaching: '70',
        research: '10',
        service: '20',
        seniorStaff: '0',
        professionalStaff: '0',
    },
    'Research Only': {
        teaching: '0',
        research: '80',
        service: '20',
        seniorStaff: '0',
        professionalStaff: '0',
    },
    'Teaching and Research': {
        teaching: '40',
        research: '40',
        service: '20',
        seniorStaff: '0',
        professionalStaff: '0',
    },
};

export const defaultWorkloadAllocationValues = {
    [WorkloadStaffType.PROFESSIONAL]: {
        teaching: '0',
        research: '0',
        service: '0',
        senior: '0',
        professional: '100',
    },
    [WorkloadStaffType.SENIOR]: {
        teaching: '0',
        research: '0',
        service: '0',
        senior: '100',
        professional: '0',
    },
    [WorkloadStaffType.ACADEMIC]: (categoryName: string) => {
        const { teaching, research, service, seniorStaff, professionalStaff } =
            categoryFieldValues[categoryName as keyof typeof categoryFieldValues] ||
            categoryFieldValues['Teaching and Research'];
        return {
            teaching,
            research,
            service,
            senior: seniorStaff,
            professional: professionalStaff,
        };
    },
};
