export type LabelSide = 'left' | 'right' | 'top' | 'bottom';
export const renderFlexDirection = (direction: LabelSide) => {
    switch (direction) {
        case 'left':
            return 'row';
        case 'right':
            return 'row-reverse';
        case 'top':
            return 'column';
        case 'bottom':
            return 'column-reverse';
        default:
            return 'row';
    }
};
