type CommonConfig = {
    featureFlags: {
        pdfCellMethod: 'none' | 'lines' | 'div';
    };
};

let config: CommonConfig = {
    featureFlags: {
        pdfCellMethod: 'lines',
    },
};

export function getConfig(): CommonConfig {
    return config;
}

export function setConfig(newConfig: CommonConfig) {
    config = newConfig;
}
