import React from 'react';
import { and, ControlProps, isStringControl, optionIs, RankedTester, rankWith } from '@jsonforms/core';
import { ChakraFormInput } from '../chakra-controls/ChakraFormInput';
import { withNamedJsonFormsControlProps } from '@/forms-renderers/chakra-ui/utils/withNamedJsonFormsHOC';
import { ChakraSubjectAutoCompletion } from '../additional/ChakraSubjectAutoCompletion';

export const ChakraSubjectAutoCompletionControl = (props: ControlProps) => {
    return <ChakraFormInput {...props} input={ChakraSubjectAutoCompletion} />;
};

export const chakraSubjectAutoCompleteTester: RankedTester = rankWith(
    3,
    and(isStringControl, optionIs('autocomplete', 'subject')),
);

export default withNamedJsonFormsControlProps(ChakraSubjectAutoCompletionControl);
