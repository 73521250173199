import React from 'react';
import { Table, Thead, Tbody, Th, Td, Tr, SkeletonCircle, Flex, Skeleton } from '@chakra-ui/react';
interface TableSkeletonLoadingProps {
    noOfRows: number;
    noOfCols: number;
}
export const TableSkeletonLoading = (props: TableSkeletonLoadingProps) => {
    return (
        <Table variant={'simple'}>
            <Thead>
                <Tr>
                    {Array.from(Array(props.noOfCols + 1).keys()).map((item) => {
                        return (
                            <Th key={item}>
                                <Skeleton rounded={'full'} h={4} />
                            </Th>
                        );
                    })}
                </Tr>
            </Thead>
            <Tbody>
                {Array.from(Array(props.noOfRows).keys()).map((row) => {
                    return (
                        <Tr key={row}>
                            {Array.from(Array(props.noOfCols).keys()).map((cell) => {
                                return (
                                    <Td key={cell}>
                                        <Skeleton rounded={'full'} h={4} />
                                    </Td>
                                );
                            })}
                            <Td w={40}>
                                <Flex gridGap={2}>
                                    <SkeletonCircle />
                                    <SkeletonCircle />
                                    <SkeletonCircle />
                                </Flex>
                            </Td>
                        </Tr>
                    );
                })}
            </Tbody>
        </Table>
    );
};
