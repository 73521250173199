import { Box, FormControl, FormLabel, Heading, HStack, Radio, RadioGroup, Text, VStack } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import useWorkplanVersion from '@/components/contexts/VersionContext/useWorkplanVersion';
import { LoadingSpinner } from '@/components';
import { usePutRequest } from '@/utils/useStatefulRequests';
import { UpdateProbationDto } from '@/wpt-lib/interfaces/Workplan';
import { SimpleAlert } from '@/components/SimpleAlert/SimpleAlert';
import { useFormik } from 'formik';
import { Assignment } from '@/wpt-lib/interfaces/Assignment';

export const ProbationTile = ({
    readonly,
    currentAssignment,
}: {
    readonly: boolean;
    currentAssignment: Assignment;
}) => {
    const { workplanVersion, loading, updateVersion } = useWorkplanVersion();

    const { error, makeRequest: save } = usePutRequest<unknown, UpdateProbationDto>();

    const formik = useFormik({
        initialValues: {
            probationPlan: workplanVersion?.probationPlan ? 'Yes' : 'No',
        },
        onSubmit: async ({ probationPlan }) => {
            await saveProbation(probationPlan);
        },
    });

    useEffect(() => {
        formik.resetForm({ values: { probationPlan: workplanVersion?.probationPlan ? 'Yes' : 'No' } });
    }, [workplanVersion]);

    const saveProbation = async (probationPlan: string) => {
        if (readonly || !workplanVersion) return;
        await formik.setFieldValue('probationPlan', probationPlan);
        formik.setSubmitting(true);

        await save(
            `/workplan/${workplanVersion?.workplan.id}`,
            {
                probation: { probationPlan: probationPlan === 'Yes' },
            },
            {
                params: {
                    assignment: currentAssignment.id,
                },
            },
        );

        updateVersion({ probationPlan: probationPlan === 'Yes' });
        formik.setSubmitting(false);
        formik.resetForm({ values: { probationPlan } });
    };

    return loading ? (
        <LoadingSpinner />
    ) : (
        <Box marginTop={10}>
            <VStack alignItems="flex-start" gap={3}>
                <Heading size="sm" as="h3">
                    Probation
                </Heading>
                <form>
                    <FormControl>
                        <FormLabel id="probationPlanLabel">Is this workplan for probation?</FormLabel>
                        <RadioGroup
                            aria-labelledby="probationPlanLabel"
                            value={formik.values.probationPlan}
                            onChange={async (value) => {
                                await saveProbation(value);
                            }}
                        >
                            <HStack>
                                {['Yes', 'No'].map((value) => (
                                    <Radio
                                        isReadOnly={readonly}
                                        key={value}
                                        value={value}
                                        size={'lg'}
                                        cursor={'pointer'}
                                    >
                                        {value}
                                    </Radio>
                                ))}
                            </HStack>
                        </RadioGroup>
                    </FormControl>
                    {!readonly && (
                        <>
                            {!!error && (
                                <SimpleAlert variant={'left-accent'} marginTop={4}>
                                    <Text>Could not save probation, please refresh the page and try again</Text>
                                </SimpleAlert>
                            )}
                        </>
                    )}
                </form>
            </VStack>
        </Box>
    );
};

export default ProbationTile;
