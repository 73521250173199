import { User } from '@/wpt-lib/interfaces/User';
import { isNullish } from '@/wpt-lib/utils/isNullish';

export function formatName(user: User | undefined) {
    return `${formatFirstName(user)} ${user?.lastName ?? ''}`;
}

export function formatFirstName(user: User | undefined) {
    /* NEO returns 'null' as preferred name for some users - unlikely to be their
     * actual preferred name. */
    return (
        (isNullish(user?.preferredName) || user?.preferredName === 'null' ? user?.firstName : user?.preferredName) ?? ''
    );
}
