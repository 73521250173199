import React, { ChangeEventHandler, useCallback } from 'react';
import { CellProps, composeWithUi, WithClassname } from '@jsonforms/core';
import { InputProps, Text } from '@chakra-ui/react';
import { namedMemo } from '@/utils/namedMemo';
import _, { debounce } from 'lodash';
import { useGetRequest } from '@/utils/useStatefulRequests';
import { User } from '@/wpt-lib/interfaces/User';
import { ListResponse } from '@/wpt-lib/interfaces/listResponse';
import { ChakraAutoComplete } from '@/forms-renderers/chakra-ui/additional/ChakraAutoComplete';
import { formatName } from '@/wpt-lib/utils/formatName';

interface AdditionalOptions {
    path: string;
    dataPath: string;
}

export const ChakraStaffListingAutoCompletion = namedMemo(
    'ChakraStaffListingAutoCompletion',
    (props: CellProps & WithClassname & InputProps) => {
        const { path, handleChange, uischema } = props;
        const {
            makeRequest: getUserMatchedQuery,
            responseData: matchedUsers,
            loading,
            error,
        } = useGetRequest<ListResponse<User>>();

        const onProjectChange: ChangeEventHandler<HTMLInputElement> = useCallback(
            async (event) => {
                const value = event.target.value;
                handleChange(path, value);
                await searchForUser(value);
            },
            [handleChange, path],
        );
        const setStaffDetails = (user: User) => {
            handleChange(path, `${user.firstName} ${user.lastName}`);
            if (uischema && uischema.options && uischema.options.additional) {
                const basePath = path.split('.').slice(0, -1).join('.');
                uischema.options.additional.forEach((option: AdditionalOptions) => {
                    const collaboratorOrganisationPath = option.path;
                    const collaboratorOrganisationData = option.dataPath;
                    handleChange(
                        composeWithUi({ scope: collaboratorOrganisationPath }, basePath),
                        _.get(user, collaboratorOrganisationData),
                    );
                });
            }
        };
        const searchForUser = debounce(async (query) => {
            if (query.length === 0) {
                return;
            }
            const params = {
                pageSize: 5,
                query: query,
            };
            await getUserMatchedQuery(`users`, {
                params: params,
            });
        }, 1000);

        return (
            <ChakraAutoComplete
                {...props}
                listItemAction={setStaffDetails}
                error={error}
                autoCompleteType={'staff'}
                onChange={onProjectChange}
                loading={loading}
                matchedRecords={matchedUsers?.items}
                renderSuggestion={(user) => (
                    <>
                        <Text textAlign={'left'}>{formatName(user)}</Text>
                        <Text textAlign={'left'}>{user.staffId}</Text>
                        <Text textAlign={'left'}>{user.email}</Text>
                    </>
                )}
            />
        );
    },
);
