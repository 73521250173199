import { VStack } from '@chakra-ui/react';
import { Dialog } from '@/components/Dialog';
import React, { ChangeEvent, useState } from 'react';
import {
    WorkplanItemProgressUpdate,
    WorkplanItemProgressUpdateRequest,
} from '@/wpt-lib/interfaces/WorkplanItemProgressUpdate';
import { LabeledSelect } from '@/components/FormInputField';
import { usePatchRequest, usePostRequest } from '@/utils/useStatefulRequests';
import { useAsyncEffect } from '@/utils/hooks/useAsyncEffect';
import { Assignment } from '@/wpt-lib/interfaces/Assignment';
import { progressUpdateStatus, StatusType } from '@/wpt-lib/interfaces/ProgressUpdate';
import AutoExpandTextArea from '@/components/pages/workplan/comment/AutoExpandTextArea';

export type DialogState = 'create' | 'edit' | 'createError' | 'editError' | 'getError';

interface ProgressUpdateDialogProps {
    selectedProgressUpdate?: WorkplanItemProgressUpdate;
    workplanItemId?: string | number;
    dialogDefaultState: DialogState;
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    getProgressUpdate?: () => Promise<void>;
    currentAssignment?: Assignment['id'];
}

export const ProgressUpdateDialog = (props: ProgressUpdateDialogProps) => {
    const {
        isOpen,
        selectedProgressUpdate,
        workplanItemId,
        setIsOpen,
        getProgressUpdate,
        dialogDefaultState,
        currentAssignment,
    } = props;
    const [dialogState, setDialogState] = useState<DialogState>(dialogDefaultState);
    const [description, setDescription] = useState('');
    const [status, setStatus] = useState<keyof typeof StatusType>(StatusType.IN_PROGRESS);

    const {
        makeRequest: makePostRequest,
        error: createError,
        loading: isSubmitting,
    } = usePostRequest<WorkplanItemProgressUpdate, unknown>();

    const {
        makeRequest: makePatchRequest,
        error: editError,
        loading: isEditing,
    } = usePatchRequest<WorkplanItemProgressUpdate, unknown>();

    const addProgressUpdate = async () => {
        try {
            const data: WorkplanItemProgressUpdateRequest = {
                description: description,
                status: status as StatusType,
            };

            await makePostRequest(
                '/progress-update',
                { ...data },
                {
                    params: { workplanItem: workplanItemId, assignment: currentAssignment },
                },
            );
            setIsOpen(false);
            setDescription('');
            if (getProgressUpdate) {
                await getProgressUpdate();
            }
        } catch (e) {
            setDialogState('createError');
            // handle by hook
        }
    };
    const setProgressUpdateValue = () => {
        if (selectedProgressUpdate) {
            setDescription(selectedProgressUpdate.description);
            setStatus(selectedProgressUpdate.status);
        }
    };
    const editProgressUpdate = async () => {
        try {
            const data: WorkplanItemProgressUpdateRequest = {
                description: description,
                status: status as StatusType,
            };
            await makePatchRequest(
                `/progress-update/${selectedProgressUpdate?.id}`,
                { ...data },
                {
                    params: {
                        assignment: currentAssignment,
                    },
                },
            );
            if (getProgressUpdate) {
                await getProgressUpdate();
            }
            setIsOpen(false);
        } catch (e) {
            setDialogState('editError');
            // handle by hook
        }
    };
    const closeProgressUpdatePopup = () => {
        setDialogState(props.dialogDefaultState);
        setIsOpen(false);
    };

    useAsyncEffect(async () => {
        if (selectedProgressUpdate && dialogState === 'edit') {
            setProgressUpdateValue();
        }
    }, [selectedProgressUpdate, isOpen]);

    const renderProgressUpdateError = () => {
        if (dialogState === 'editError' && editError) {
            return 'Cannot edit progress update. Please refresh your page and try again.';
        } else if (dialogState === 'createError' && createError) {
            return 'Cannot submit new progress update. Please refresh your page and try again.';
        } else {
            return undefined;
        }
    };

    const setProgressUpdateStatus = (event: ChangeEvent<HTMLSelectElement>) => {
        setStatus(StatusType[event.target.value as keyof typeof StatusType]);
    };
    return (
        <Dialog
            colorScheme={'blue'}
            dialogHeader={dialogState === 'create' ? 'Add progress update' : 'Edit progress update'}
            dialogConfirmLabel={dialogState === 'create' ? 'Add Update' : 'Edit Update'}
            dialogBody={
                'Enter details of what you have worked on for this activity. You may wish to comment on whether the time spent on this task was more or less than planned and why.'
            }
            errorMsg={renderProgressUpdateError()}
            isOpen={isOpen}
            isLoading={isSubmitting || isEditing}
            onClose={() => closeProgressUpdatePopup()}
            dialogAction={dialogState === 'create' ? addProgressUpdate : editProgressUpdate}
        >
            <VStack w={'full'}>
                <AutoExpandTextArea
                    data={description}
                    onChange={setDescription}
                    placeholder="Add your progress update description here"
                />
                <LabeledSelect
                    onChange={setProgressUpdateStatus}
                    value={status}
                    label={'Status'}
                    labelAlign={'left'}
                    labelSide={'top'}
                >
                    {Object.values(StatusType).map((value, i) => (
                        <option key={i} value={value}>
                            {progressUpdateStatus[value]}
                        </option>
                    ))}
                </LabeledSelect>
            </VStack>
        </Dialog>
    );
};
