import * as React from 'react';
import { StyleSheet } from '@react-pdf/renderer';
import Text from '@/wpt-lib/pdf/common/Text';
import { Cell } from '@/wpt-lib/pdf/common/Table';

const HeadingColors = ['#a5a7ab', '#bfc1c4', '#dcdee0'];

const SubHeading = ({ text, level = 1 }: { text: string; level?: 1 | 2 | 3 }) => (
    <Cell bg={HeadingColors[level]}>
        <Text style={styles.text}>{text}</Text>
    </Cell>
);

const styles = StyleSheet.create({
    text: {
        textAlign: 'left',
        width: '100%',
        fontWeight: 'bold',
    },
});

export default SubHeading;
