import React, { ComponentType } from 'react';
import { withDisplayName } from '@/utils/withDisplayName';
import WorkplanVersionProvider from '@/components/contexts/VersionContext/WorkplanVersionProvider';
import { useParams } from 'react-router-dom';

export const WorkplanVersionParamProvider = ({ children }: { children: React.ReactNode }) => {
    const { version } = useParams();

    return (
        <WorkplanVersionProvider version={version ? Number.parseInt(version) : undefined}>
            {children}
        </WorkplanVersionProvider>
    );
};

export const withWorkplanVersionParam = <T extends object>(WrappedComponent: ComponentType<T>) => {
    return withDisplayName((props: T) => {
        return (
            <WorkplanVersionParamProvider>
                <WrappedComponent {...props} />
            </WorkplanVersionParamProvider>
        );
    }, 'withWorkplanVersion');
};

export default withWorkplanVersionParam;
