import { AllUsersInterface } from '@/components/pages/Admin/UserSupervision/urlUserListHelpers';
import config from '@/config';
import { defaultTableParams } from '@/utils/tableUtils';
import { ListResponse } from '@/wpt-lib/interfaces/listResponse';
import Icons from '@/components/icons/Icons';
import React, { useCallback, useEffect, useState } from 'react';
import { Assignment } from '@/wpt-lib/interfaces/Assignment';
import { useAsyncEffect } from '@/utils/hooks/useAsyncEffect';
import { useGetRequest } from '@/utils/useStatefulRequests';
import { SimpleAlert } from '@/components/SimpleAlert/SimpleAlert';
import { Button, Flex, Text } from '@chakra-ui/react';
import { SuperviseeWorkplan } from '@/wpt-lib/interfaces/Supervisee';
import { TableTopToolBar } from '@/components/Table/TableTopToolBar';
import { PaginationToolbar } from '@/components/Table';
import { Tile } from '@/components/Tile';
import { Action, ListTable } from '@/components/pages/dashboard/tiles/bottomTile/bottomTileTables/ListTable';
import { superviseeColumns } from '@/components/pages/Admin/UserSupervision/Column/UserSupervisionColumn';
import { downloadExcelTables } from '@/components/Excel/downloadExcelTables';
import StateDisplay from '@/components/StateDisplay/StateDisplay';
import { useAssignment } from '@/components/contexts/AssignmentContext/useAssignment';
import { useUserSupervisionFilters } from '@/components/contexts/UserSupervisionFiltersContext/useUserSupervisionFilters';
import useNavigateOptions from '@/components/contexts/NaviateContext/useNavigateOptions';
import { formatName } from '@/wpt-lib/utils/formatName';

export const SupervisingTable = (props: {
    assignmentId?: string;
    assignment?: Assignment;
    allUsers: AllUsersInterface[];
}) => {
    const { assignmentId, assignment, allUsers } = props;

    const { currentAssignment } = useAssignment();
    const [pageSize, setPageSize] = useState(defaultTableParams.pageSize);
    const [pages, setPages] = useState(1);
    const [page, setPage] = useState(defaultTableParams.page);
    const [orderByColumn, setSuperviseeOrderByColumn] = useState<string>('supervisee.staffId');
    const [orderByOrientation, setOrderByOrientation] = useState<string>(defaultTableParams.orderByOrientation);
    const [focusPage, setFocusPage] = useState(false);
    const params = useUserSupervisionFilters().params;

    const { navigatePathCustom } = useNavigateOptions();

    const {
        makeRequest: makeSuperviseeRequest,
        responseData: superviseeListReponse,
        loading: isGettingSupervisee,
        error: superviseeLoadingError,
    } = useGetRequest<ListResponse<SuperviseeWorkplan>>();

    const { makeRequest: makeExcelRequest, loading, error } = useGetRequest<Blob>();
    const { makeRequest: refreshSupervises } = useGetRequest<void>();

    const getSupervisee = useCallback(async () => {
        try {
            if (assignment) {
                const params = {
                    page: page,
                    orderByColumn: orderByColumn,
                    orderByOrientation: orderByOrientation,
                    pageSize: pageSize,
                    assignment: currentAssignment?.id,
                };
                const supervisee = await makeSuperviseeRequest(`workplan/supervisee/${assignment.id}`, {
                    params,
                });
                setPages(supervisee.data.info.pages);
                setPage(supervisee.data.info.page);
            }
        } catch {
            //handled by hook
        }
    }, [assignmentId, pageSize, page, pages, orderByColumn, orderByOrientation, assignment]);
    useAsyncEffect(async () => {
        try {
            await getSupervisee();
        } catch (error) {
            // handled by hook
        }
    }, [getSupervisee]);

    useEffect(() => {
        setPage(defaultTableParams.page);
    }, [assignmentId]);

    const getAllUsersDetails = () => {
        let routePath = '';
        allUsers.forEach((user) => {
            routePath += `${user.firstName}-${user.lastName}-${user.assignmentId};`;
        });
        return routePath;
    };

    const getSupervises = async () => {
        try {
            if (assignmentId) {
                if (config.featureFlags.doRefreshSupervises) {
                    try {
                        await refreshSupervises(`users/supervising/${assignmentId}`);
                    } catch (e) {
                        console.error('Error loading supervised users, ', e);
                    }
                }
                await getSupervisee();
            }
        } catch {
            // handled by hook
        }
    };

    const viewSuperviseeDetails = (item: SuperviseeWorkplan) => {
        const currentUser = `${item.assignment.user?.firstName}-${item.assignment.user?.lastName}-${item.assignment.id};`;
        navigatePathCustom({
            path: [],
            params: { users: getAllUsersDetails() + currentUser, assignmentId: item.assignment.id },
            search: params,
        });
    };

    const getSupervisedAction = (superviseeWorkplan: SuperviseeWorkplan): Action<SuperviseeWorkplan>[] => {
        if (superviseeWorkplan.assignment.id === Number(assignmentId)) {
            return [];
        }
        return [
            {
                id: 'view',
                event: viewSuperviseeDetails,
                label: 'View',
                ariaLabel: `View ${formatName(superviseeWorkplan.user)} detail`,
                icon: <Icons.PointerRightIcon w={6} h={6} />,
            },
        ];
    };

    const hasSupervisee = superviseeListReponse && superviseeListReponse.info.total > 0;

    const downloadUserSupervisionSupervising = async () => {
        if (assignment) {
            const urlPath = `downloadUserSupervising/${assignment.id}`;
            const staffId = `${assignment.user?.staffId}`;
            const fileName = 'User_Supervision_Supervising_Table';
            await downloadExcelTables({
                page,
                orderByColumn,
                orderByOrientation,
                pageSize,
                urlPath,
                staffId,
                fileName,
                makeExcelRequest,
            });
        }
    };

    return (
        <>
            {hasSupervisee && (
                <Tile
                    overflowX={'auto'}
                    colspan={2}
                    collapsable
                    heading={'Supervising'}
                    collapsableClick={() => {
                        getSupervises();
                    }}
                    defaultOpen
                >
                    <StateDisplay
                        data={superviseeListReponse}
                        loading={isGettingSupervisee}
                        error={!!superviseeLoadingError}
                        errorRender={() => (
                            <Flex justifyContent="center">
                                <SimpleAlert title="Error" variant="subtle">
                                    {`Could not load user supervision supervisees`}
                                </SimpleAlert>
                            </Flex>
                        )}
                        render={() => (
                            <>
                                <Flex justifyContent={'space-between'}>
                                    <TableTopToolBar
                                        listResponse={superviseeListReponse}
                                        loading={isGettingSupervisee}
                                        columns={superviseeColumns}
                                        orderByColumn={orderByColumn}
                                        orderByOrientation={orderByOrientation}
                                        setOrderByColumn={setSuperviseeOrderByColumn}
                                        setOrderByOrientation={setOrderByOrientation}
                                        isSupervisingWorkplanTable={true}
                                    />
                                    <>
                                        {error && (
                                            <SimpleAlert title="Error" variant="subtle" maxWidth={'50%'}>
                                                {`Could not download user supervision workplans table`}
                                            </SimpleAlert>
                                        )}
                                    </>
                                    <Button
                                        h={12}
                                        whiteSpace={'normal'}
                                        colorScheme={'blue'}
                                        display={'flex'}
                                        gridGap={2}
                                        isLoading={loading}
                                        onClick={downloadUserSupervisionSupervising}
                                    >
                                        <Text
                                            display={{
                                                base: 'none',
                                                md: 'block',
                                            }}
                                        >
                                            Download Table
                                        </Text>
                                    </Button>
                                </Flex>
                                <ListTable
                                    tableCaption={`List of Supervising (page ${page + 1} of ${
                                        pages === 0 ? 1 : pages
                                    })`}
                                    getActions={getSupervisedAction}
                                    columns={superviseeColumns}
                                    data={superviseeListReponse?.items}
                                    hideOption={true}
                                />
                                <PaginationToolbar
                                    setPageSize={setPageSize}
                                    setNewPage={setPage}
                                    pageSize={pageSize}
                                    pages={pages}
                                    page={page}
                                    focusPage={focusPage}
                                    setFocusPage={setFocusPage}
                                />
                            </>
                        )}
                    />
                </Tile>
            )}
        </>
    );
};

export default SupervisingTable;
