import { useWorkplanLabels } from '@/components/contexts/LabelsContext/useLabels';
import { FormActionTable } from '@/components/pages/dashboard/tiles/bottomTile/bottomTileTables/FormActionTable';
import { FormTableProps } from '@/components/pages/dashboard/tiles/bottomTile/bottomTileTables/FormTableInterfaces';
import { LeaveFormResponse } from '@/wpt-lib/interfaces/Leave';
import React from 'react';
import { label, optionalLabel } from '@/wpt-lib/utils/label';
import { createdColumn } from '@/components/pages/dashboard/tiles/bottomTile/bottomTileTables/PersonalGoalTable';

type LeaveTableProps = FormTableProps<LeaveFormResponse>;

export const PlannedLeaveTable = (props: LeaveTableProps) => {
    const leaveTypes = useWorkplanLabels(props.workplan, 'LEAVE', 'absenceType');
    const leaveCodes = useWorkplanLabels(props.workplan, 'LEAVE', 'leaveCode');

    const convertDateFormat = (date: string) => {
        const [year, month, day] = date.split('-');
        return [day, month, year].join('/');
    };

    const columns = [
        {
            name: 'Type',
            field: 'leaveCode',
            highlight: (item: LeaveFormResponse) => item.changed,
            getData: (item: LeaveFormResponse) => {
                const parts = [];
                if (item.leaveCode) parts.push(label(leaveCodes, item.leaveCode));
                if (item.absenceType) parts.push(label(leaveTypes, item.absenceType));
                return parts.join(' - ');
            },
        },
        {
            name: 'Start Date',
            field: 'startDate',
            getData: (item: LeaveFormResponse) => item.startDate && convertDateFormat(item.startDate),
        },
        {
            name: 'End Date',
            field: 'endDate',
            getData: (item: LeaveFormResponse) => item.endDate && convertDateFormat(item.endDate),
        },
        {
            name: 'Created',
            field: 'createdAt',
            getData: createdColumn,
        },
    ];

    const getAbsenceType = (row: LeaveFormResponse) => optionalLabel(leaveTypes, row.absenceType);

    return (
        <FormActionTable
            getColumns={props.getColumns}
            endpoint={'leave'}
            urlLabel={'planned-leave'}
            typeOfForm={'Planned Leave'}
            tableCaptions={'Planned Leave'}
            columns={columns}
            data={props.data}
            fetchFormList={props.fetchFormList}
            onChange={props.onChange}
            getActions={props.getActions}
            workplan={props.workplan}
            getName={getAbsenceType}
            page={props.page}
            pages={props.pages}
            orderByOrientation={props.orderByOrientation}
            orderByColumn={props.orderByColumn}
        />
    );
};
