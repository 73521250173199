import React, { ChangeEventHandler, useCallback } from 'react';
import { CellProps, WithClassname } from '@jsonforms/core';
import { InputProps, Text } from '@chakra-ui/react';
import { namedMemo } from '@/utils/namedMemo';
import { debounce } from 'lodash';
import { useGetRequest } from '@/utils/useStatefulRequests';
import { ChakraAutoComplete } from '@/forms-renderers/chakra-ui/additional/ChakraAutoComplete';
import { Subject } from '@/wpt-lib/interfaces/Subject';

export const ChakraSubjectAutoCompletion = namedMemo(
    'ChakraSubjectAutoCompletion',
    (props: CellProps & WithClassname & InputProps) => {
        const { path, handleChange } = props;
        const {
            makeRequest: getMatchedSubject,
            responseData: matchedSubject,
            loading,
            error,
        } = useGetRequest<Subject[]>();

        const onProjectChange: ChangeEventHandler<HTMLInputElement> = useCallback(
            async (event) => {
                const value = event.target.value;
                handleChange(path, value);
                await searchForSubject(value);
            },
            [handleChange, path],
        );
        const setOpenCourseDetails = (subject: Subject) => {
            handleChange(path, subject.subjectFullTitle);
        };
        const searchForSubject = debounce(async (query) => {
            try {
                if (query.length === 0) {
                    return;
                }
                const params = {
                    searchQuery: query,
                };
                await getMatchedSubject(`uts-api/subjects`, {
                    params: params,
                });
            } catch {
                // handled by Hook
            }
        }, 1000);

        return (
            <ChakraAutoComplete
                {...props}
                error={error}
                listItemAction={setOpenCourseDetails}
                autoCompleteType={'subject'}
                onChange={onProjectChange}
                loading={loading}
                matchedRecords={matchedSubject}
                renderSuggestion={(subject) => (
                    <>
                        <Text textAlign={'left'}>{subject.subjectCode}</Text>
                        <Text textAlign={'left'}>{subject.subjectFullTitle}</Text>
                    </>
                )}
            />
        );
    },
);
