import { ActivityFormResponse, ActivityWorkplanItemAction } from '@/wpt-lib/interfaces/Activity';
import WorkplanItemAction, { Params, useParam, useWorkplanItemData } from '../WorkplanItemAction';
import React from 'react';
import { useParams } from 'react-router-dom';
import WorkplanItemHandler from '@/components/pages/workplanItemHandler/WorkplanItemHandler';

const ActivityWorkplanItem = () => {
    const { workplanId, assignmentId, versionId, userDetails } = useParams<{
        workplanId: string;
        assignmentId: string;
        versionId: string;
        userDetails: string;
    }>();
    const breadCrumbData = {
        workplanId,
        assignmentId,
        versionId,
        userDetails,
    };
    const type = 'activity';
    const { parsedData: parsedActivity } = useWorkplanItemData<ActivityFormResponse, ActivityWorkplanItemAction>(type);
    const activityName = parsedActivity ? parsedActivity.activityName : 'Activity';
    return (
        <WorkplanItemAction
            {...breadCrumbData}
            renderForm={() => <WorkplanItemHandler readonly={true} formType="activity" />}
            workplanItemType={type}
            typeLabel={'Activity'}
            params={useParam() as Params}
            name={activityName}
        />
    );
};
export default ActivityWorkplanItem;
