import React from 'react';
import { LayoutProps, RankedTester, rankWith, uiTypeIs } from '@jsonforms/core';
import { withNamedJsonFormsLayoutProps } from '@/forms-renderers/chakra-ui/utils/withNamedJsonFormsHOC';
import { namedMemo } from '@/utils/namedMemo';
import configuredAreEqual from '../utils/configuredAreEqual';
import { Layout } from '@jsonforms/core/src/models/uischema';
import ChakraLayoutElements from '@/forms-renderers/chakra-ui/layouts/ChakraLayoutElements';
import isEmpty from 'lodash/isEmpty';

export interface RuleGroupLayout extends Layout {
    type: 'RuleGroup';
}

export const ChakraRuleGroupRenderer = namedMemo(
    'ChakraRuleGroupRenderer',
    ({ uischema, schema, path, enabled, visible, renderers, cells }: LayoutProps) => {
        const layout = uischema as Layout;
        if (!isEmpty(layout.elements) || visible) {
            return (
                <ChakraLayoutElements
                    {...{
                        elements: layout.elements,
                        schema,
                        path,
                        enabled,
                        renderers,
                        cells,
                    }}
                />
            );
        } else {
            return null;
        }
    },
    configuredAreEqual({ ignore: ['data'] }),
);

export const chakraRuleGroupTester: RankedTester = rankWith(1, uiTypeIs('RuleGroup'));

export default withNamedJsonFormsLayoutProps(ChakraRuleGroupRenderer);
