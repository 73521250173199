import { Flex, HStack, Text } from '@chakra-ui/react';
import React from 'react';
import { ListResponse } from '@/wpt-lib/interfaces/listResponse';
import { sortedOrientation, supervisingSortedOrientation } from '@/utils/tableUtils';
import { Column } from '@/components/pages/dashboard/tiles/bottomTile/bottomTileTables/ListTable';
import { LabeledSelect } from '@/components/FormInputField/LabeledSelect';
import { isNullish } from '@/wpt-lib/utils/isNullish';
import { WorkplanYearRangeInterface } from '../pages/supervisor/SubmittedWorkplans';

interface TableTopToolbarProps<T> {
    listResponse?: ListResponse<T>;
    loading: boolean;
    children?: React.ReactNode;
    columns?: (Column<T> & { sort?: boolean })[];
    setOrderByColumn: (arg: string) => void;
    setOrderByOrientation: (arg: string) => void;
    orderByColumn: string;
    orderByOrientation: string;
    selectedYear?: number;
    setSelectedYear?: React.Dispatch<React.SetStateAction<number>>;
    yearResponse?: WorkplanYearRangeInterface[];
    isSupervisingWorkplanTable?: boolean;
}

export const TableTopToolBar = <T,>({
    listResponse,
    loading,
    children,
    columns,
    setOrderByColumn,
    setOrderByOrientation,
    orderByColumn,
    orderByOrientation,
    selectedYear,
    setSelectedYear,
    yearResponse,
    isSupervisingWorkplanTable,
}: TableTopToolbarProps<T>) => {
    const getWorkplanYearRange = (yearResponse: WorkplanYearRangeInterface[]): number[] => {
        const max = yearResponse[0].maxYear;
        const min = yearResponse[0].minYear;
        const newWorkplanYearRange = [];
        for (let i = min; i <= max; i++) {
            newWorkplanYearRange.push(i);
        }
        return newWorkplanYearRange;
    };

    const originalOrderByOrientation = () => {
        return sortedOrientation.map((orient, i) => {
            return (
                <option key={i} value={orient.key}>
                    {orient.value}
                </option>
            );
        });
    };

    return (
        <Flex justifyContent={'space-between'} alignItems={'center'} p={4} gridGap={3} flexDirection="row-reverse">
            {isNullish(children) ? <div></div> : children}
            <Flex
                gridGap={{
                    sm: 1,
                    md: 10,
                }}
                alignItems={'center'}
                flexDirection={'row'}
            >
                <Flex
                    gridGap={{
                        sm: 1,
                        md: 5,
                    }}
                    alignItems={{
                        base: 'flex-start',
                        md: 'center',
                    }}
                    flexDirection={{
                        base: 'column',
                        md: 'row',
                    }}
                >
                    <Text whiteSpace={'nowrap'}>Total: {listResponse && !loading ? listResponse.info.total : '-'}</Text>
                    <HStack>
                        {!isNullish(selectedYear) && setSelectedYear && (
                            <LabeledSelect
                                label="Year:"
                                controlProps={{ w: 'unset' }}
                                value={selectedYear}
                                onChange={(e) => setSelectedYear(Number(e.target.value))}
                            >
                                {yearResponse &&
                                    getWorkplanYearRange(yearResponse).map((year, i) => {
                                        return (
                                            <option key={i} value={year}>
                                                {year}
                                            </option>
                                        );
                                    })}
                            </LabeledSelect>
                        )}
                        <LabeledSelect
                            label="Sorted By:"
                            controlProps={{ w: 'unset' }}
                            value={orderByColumn}
                            onChange={(e) => setOrderByColumn(e.target.value)}
                        >
                            {columns
                                ?.filter((column) => isNullish(column.sort) || column.sort)
                                .map((column, i) => {
                                    return (
                                        <option key={i} value={column.field}>
                                            {column.name}
                                        </option>
                                    );
                                })}
                        </LabeledSelect>
                        <LabeledSelect
                            label="Order By:"
                            controlProps={{ w: 'unset' }}
                            value={orderByOrientation}
                            onChange={(e) => setOrderByOrientation(e.target.value)}
                        >
                            {isSupervisingWorkplanTable
                                ? orderByColumn === 'supervisee.staffId'
                                    ? originalOrderByOrientation()
                                    : supervisingSortedOrientation.map((orient, i) => {
                                          return (
                                              <option key={i} value={orient.key}>
                                                  {orient.value}
                                              </option>
                                          );
                                      })
                                : originalOrderByOrientation()}
                        </LabeledSelect>
                    </HStack>
                </Flex>
            </Flex>
        </Flex>
    );
};
