import React from 'react';
import { and, ControlProps, isEnumControl, optionIs, RankedTester, rankWith } from '@jsonforms/core';
import { ChakraFormInput } from '../chakra-controls/ChakraFormInput';
import { ChakraRadioGroup } from '../chakra-controls';
import { withNamedJsonFormsEnumProps } from '@//forms-renderers/chakra-ui/utils/withNamedJsonFormsHOC';

export const ChakraRadioGroupControl = (props: ControlProps) => (
    <ChakraFormInput
        {...props}
        input={ChakraRadioGroup}
        formControlProps={{ as: 'fieldset' }}
        labelProps={{ as: 'legend' }}
    />
);

export const chakraRadioGroupControlTester: RankedTester = rankWith(3, and(isEnumControl, optionIs('format', 'radio')));

export default withNamedJsonFormsEnumProps(ChakraRadioGroupControl);
