import { BreadCrumb, BreadCrumbItem } from '@/components/BreadCrumb';
import WorkplanStateDisplay from '@/components/contexts/WorkplanContext/WorkplanStateDisplay';
import { Tile } from '@/components/Tile';
import { Assignment } from '@/wpt-lib/interfaces/Assignment';
import { Flex, HStack, Text } from '@chakra-ui/react';
import React from 'react';
import { useUserSupervisionFilters } from '@/components/contexts/UserSupervisionFiltersContext/useUserSupervisionFilters';
import useUserSupervisionBreadcrumbs from '@/components/pages/Admin/UserSupervision/useUserSupervisionBreadcrumbs';
import useNavigateOptions from '@/components/contexts/NaviateContext/useNavigateOptions';
import { formatName } from '@/wpt-lib/utils/formatName';

interface AdminHeaderProps {
    assignment?: Assignment;
    version?: string;
}

export const UserSupervisionWorkplanHeader = (props: AdminHeaderProps) => {
    const { assignment, version } = props;
    const params = useUserSupervisionFilters().params;

    const users = useUserSupervisionBreadcrumbs(1);
    const userName = `${assignment && `${formatName(assignment.user)}`}`;

    const { supervisor } = useNavigateOptions();

    return (
        <Tile colspan={2}>
            <WorkplanStateDisplay
                render={(workplan) => {
                    const breadCrumbs: BreadCrumbItem[] = [
                        {
                            label: 'Staff Workplans',
                            path: `/${supervisor ? 'supervisor' : 'admin'}?tab=supervision&${params}`,
                            isCurrent: false,
                        },
                        ...users,
                        {
                            label: `Workplan ${workplan.year} - Version ${version}`,
                            path: '#',
                            isCurrent: true,
                        },
                    ];
                    return (
                        <>
                            <Flex justifyContent={'space-between'} alignItems={'center'}>
                                <BreadCrumb breadCrumbs={breadCrumbs} color={'blue.500'} />
                            </Flex>
                            <Flex justifyContent={'space-between'} alignItems={'center'}>
                                <HStack>
                                    <Text fontSize={'lg'} fontWeight={'semibold'} textColor={'black'}>
                                        {`${userName}`}
                                    </Text>
                                    <Text fontSize={'lg'} fontWeight={'normal'} textColor={'black'}>
                                        {`${assignment?.name}`}
                                    </Text>
                                </HStack>
                            </Flex>
                        </>
                    );
                }}
            />
        </Tile>
    );
};
