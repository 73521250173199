import React from 'react';
import { CellProps, isBooleanControl, RankedTester, rankWith, WithClassname } from '@jsonforms/core';
import { ChakraCheckbox } from '../chakra-controls';
import { withNamedJsonFormsCellProps } from '@/forms-renderers/chakra-ui/utils/withNamedJsonFormsHOC';

export const ChakraBooleanCell = (props: CellProps & WithClassname) => {
    return <ChakraCheckbox {...props} />;
};

export const chakraBooleanCellTester: RankedTester = rankWith(2, isBooleanControl);

export default withNamedJsonFormsCellProps(ChakraBooleanCell);
