import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { OwnPropsOfRenderer, UISchemaElement } from '@jsonforms/core';
import { ResponsiveValue, SimpleGrid } from '@chakra-ui/react';
import { namedMemo } from '@/utils/namedMemo';
import ChakraLayoutElements from '@/forms-renderers/chakra-ui/layouts/ChakraLayoutElements';

export interface ChakraLayoutRendererProps extends OwnPropsOfRenderer {
    elements: UISchemaElement[];
    width?: string;
    columns?: ResponsiveValue<number | string>;
    rows?: ResponsiveValue<number> | string;
    autoRows?: string;
    gap?: string;
}

export const ChakraLayoutRenderer = namedMemo(
    'ChakraLayoutRenderer',
    ({
        visible,
        elements,
        schema,
        path,
        enabled,
        renderers,
        cells,
        width,
        columns,
        rows,
        autoRows,
        gap,
    }: ChakraLayoutRendererProps) => {
        if (isEmpty(elements) || !visible) {
            return null;
        } else {
            const getColumnsProps = () => {
                // Use as template if a non-number string
                if (columns && typeof columns === 'string' && Number.isNaN(Number(columns))) {
                    return { templateColumns: `${columns}` };
                }
                return { templateColumns: `repeat(${columns ?? 1},1fr)` };
            };
            const getRowsProps = () => {
                if (autoRows) {
                    return { autoRows: autoRows };
                }
                if (rows) {
                    // Use as template if a non-number string
                    if (typeof rows === 'string' && Number.isNaN(Number(rows))) {
                        return { templateRows: `${rows}` };
                    }
                    return { templateRows: `repeat(${rows},1fr)` };
                }
                return { templateRows: 'auto' };
            };
            return (
                <SimpleGrid {...getColumnsProps()} {...getRowsProps()} gap={gap ?? 8} h={'100%'} w={width ?? '100%'}>
                    <ChakraLayoutElements
                        {...{
                            elements,
                            schema,
                            path,
                            enabled,
                            renderers,
                            cells,
                        }}
                    />
                </SimpleGrid>
            );
        }
    },
);
