import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { ControlProps, isBooleanControl, RankedTester, rankWith, UISchemaElement } from '@jsonforms/core';
import { ChakraCheckbox } from '../chakra-controls';
import { withNamedJsonFormsControlProps } from '@/forms-renderers/chakra-ui/utils/withNamedJsonFormsHOC';
import { JsonFormsDispatch } from '@jsonforms/react';

export const ChakraBooleanControl = ({
    data,
    visible,
    label,
    id,
    enabled,
    uischema,
    schema,
    rootSchema,
    handleChange,
    errors,
    path,
    config,
    required,
}: ControlProps) => {
    const descriptionElements = uischema.options?.descriptionElements;
    return (
        <>
            <ChakraCheckbox
                id={id}
                isValid={isEmpty(errors)}
                data={data}
                enabled={enabled}
                visible={visible}
                path={path}
                uischema={uischema}
                schema={schema}
                rootSchema={rootSchema}
                handleChange={handleChange}
                errors={errors}
                config={config}
                label={label}
                required={required}
            />{' '}
            {Array.isArray(descriptionElements) &&
                descriptionElements.map((desc: UISchemaElement, i: number) => {
                    return <JsonFormsDispatch uischema={desc} key={i} />;
                })}
        </>
    );
};

export const chakraBooleanControlTester: RankedTester = rankWith(2, isBooleanControl);
export default withNamedJsonFormsControlProps(ChakraBooleanControl);
