import { SuperviseeWorkplan } from '@/wpt-lib/interfaces/Supervisee';
import { mapWorkplanStateToString, WorkplanVersion } from '@/wpt-lib/interfaces/Workplan';
import { formatFirstName } from '@/wpt-lib/utils/formatName';

export const workplanColumns = [
    {
        name: 'Year',
        field: 'year',
        getData: (item: WorkplanVersion) => item.workplan.year,
    },
    {
        name: 'Version',
        field: 'version',
        getData: (item: WorkplanVersion) => item.version,
    },
    {
        name: 'Created Date',
        field: 'createdAt',
        getData: (item: WorkplanVersion) => new Date(item.createdAt).toLocaleDateString(),
    },
    {
        name: 'Status',
        field: 'currentVersion.state',
        getData: (item: WorkplanVersion) =>
            item.version === item.workplan.currentVersion.version ? mapWorkplanStateToString[item.state] : 'Superseded',
    },
];

export const superviseeColumns = [
    {
        name: 'Id',
        field: 'supervisee.staffId',
        getData: (item: SuperviseeWorkplan) => item.assignment.user?.staffId,
    },
    {
        name: 'Preferred Name',
        field: 'supervisee.preferredName',
        getData: (item: SuperviseeWorkplan) => formatFirstName(item.assignment.user),
    },
    {
        name: 'Last Name',
        field: 'supervisee.lastName',
        getData: (item: SuperviseeWorkplan) => item.assignment.user?.lastName,
    },
    {
        name: 'Assignment Role',
        field: 'assignment.name',
        getData: (item: SuperviseeWorkplan) => item.assignment.name,
    },
    {
        name: 'Faculty/Division',
        field: 'faculty.name',
        getData: (item: SuperviseeWorkplan) => item.assignment.organisation.faculty.name,
    },
    {
        name: 'Org Unit',
        field: 'organisation.name',
        getData: (item: SuperviseeWorkplan) => item.assignment.organisation.name,
    },
    {
        name: 'WP Status',
        field: 'currentVersion.state',
        getData: (item: SuperviseeWorkplan) => {
            const currentYear = new Date().getFullYear();
            const superviseeWorkplans = item.assignment.workplans;
            if (superviseeWorkplans?.length === 0) {
                return 'Not Started';
            } else {
                const currentWorkplan = superviseeWorkplans?.find((workplan) => workplan.year === currentYear);
                if (currentWorkplan) {
                    return mapWorkplanStateToString[currentWorkplan.state];
                }
                return 'Not Started';
            }
        },
    },
];
