import { Box, Button, Heading, HStack, Progress, Text, VStack } from '@chakra-ui/react';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Tile } from '@/components/Tile';
import { useGetRequest } from '@/utils/useStatefulRequests';
import { PdfRun } from '@/wpt-lib/interfaces/PdfRun';
import { SimpleAlert } from '@/components/SimpleAlert/SimpleAlert';
import { PdfError } from '@/wpt-lib/interfaces/PdfError';
import { ListResponse } from '@/wpt-lib/interfaces/listResponse';
import { defaultTableParams } from '@/utils/tableUtils';
import { ListTable } from '@/components/pages/dashboard/tiles/bottomTile/bottomTileTables/ListTable';
import { PaginationToolbar } from '@/components/Table';
import config from '@/config';
import { LabeledSelect } from '@/components/FormInputField';
import PDFUploadsList from '@/components/pages/Admin/Global/PDFUploadsList';

const columns = [
    {
        name: 'Error Time',
        getData: (item: PdfError) => `${new Date(item.createdAt).toLocaleString()}`,
    },
    {
        name: 'Error Message',
        noOfLines: 2,
        getData: (item: PdfError) => `${item.errorMsg}`,
    },
    {
        name: 'Workplan ID',
        getData: (item: PdfError) => `${JSON.parse(item.payload).workplanId}`,
    },
    {
        name: 'Academic',
        getData: (item: PdfError) => `${item.academicName}`,
    },
    {
        name: 'Assignment',
        getData: (item: PdfError) => `${item.assignmentName}`,
    },
];

const TrimUploadPDF = () => {
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(defaultTableParams.page);
    const [pages, setPages] = useState(1);

    const [uploadYear, setUploadYear] = useState('' + new Date().getFullYear());

    const { error: runError, responseData: latestRun, makeRequest: makeRunRequest } = useGetRequest<PdfRun>();

    const {
        responseData: numberOfEndorsed,
        makeRequest: makeEndorsedRequest,
        loading: endorsedLoading,
    } = useGetRequest<{ [uploadYear: string]: number }>();

    const { responseData: errorsList, makeRequest: makeErrorsRequest } = useGetRequest<ListResponse<PdfError>>();

    const { error: launchError, makeRequest: makeUploadRequest } = useGetRequest<Blob>();

    const getLatestRun = async () => {
        try {
            await makeRunRequest(`pdf-run/latest/${uploadYear}`);
        } catch (e) {
            //handled by hook
        }
    };

    const getEndorsed = async () => {
        try {
            await makeEndorsedRequest(`workplan/numberOfEndorsed`);
        } catch (e) {
            //handled by hook
        }
    };

    const getErrors = async (runId: number) => {
        try {
            const params = {
                page: page,
                pageSize: pageSize,
            };
            const res = await makeErrorsRequest(`pdf-error/${runId}/${uploadYear}`, {
                params,
            });
            setPages(res.data.info.pages);
            setPage(res.data.info.page);
        } catch (e) {
            //handled by hook
        }
    };

    const uploadPdf = async () => {
        try {
            await makeUploadRequest(`pdf/uploadWorkplans/${uploadYear}`);
            getLatestRun();
        } catch (e) {
            //handled by hook
        }
    };

    useEffect(() => {
        getLatestRun();

        const pollInterval = setInterval(() => {
            getLatestRun();
        }, config.admin.pdfPolling);

        return () => clearInterval(pollInterval);
    }, [uploadYear]);

    useEffect(() => {
        getEndorsed();
    }, [latestRun]);

    useLayoutEffect(() => {
        if (latestRun) {
            getErrors(latestRun.id);
        }
    }, [pageSize, page, pages, latestRun]);

    const inProgress = latestRun && !latestRun.finishedAt;
    const progress = latestRun ? ((latestRun.totalJobs - latestRun.currentJobs) / latestRun.totalJobs) * 100 : 0;

    const buttonDisabled =
        (latestRun && latestRun.currentJobs > 0) ||
        !numberOfEndorsed ||
        !(uploadYear in numberOfEndorsed) ||
        numberOfEndorsed[uploadYear] === 0;

    if (runError) {
        return (
            <SimpleAlert title="Error" variant="subtle" width="full">
                Could not load latest pdf run
            </SimpleAlert>
        );
    }

    return (
        <Tile
            colspan={3}
            heading={'Upload workplans to trim'}
            collapsable
            rounded={'lg'}
            borderWidth={1}
            borderColor={'gray.100'}
        >
            <VStack alignItems={'flex-start'} spacing={3}>
                <Text>
                    This section automates the process of uploading endorsed workplans to TRIM at the end of the year.
                    It runs in the background and may take some time. Errors, if any, will be displayed below.
                </Text>

                {!inProgress && (
                    <VStack paddingTop={5} alignItems="flex-start">
                        <>
                            <Heading as="p" size="sm">
                                Start Upload
                            </Heading>
                            <HStack spacing={3}>
                                <LabeledSelect
                                    label="Select year:"
                                    value={uploadYear}
                                    onChange={(e) => setUploadYear(e.target.value)}
                                    isDisabled={!numberOfEndorsed || endorsedLoading}
                                >
                                    {Object.keys(numberOfEndorsed ?? { [uploadYear]: 0 })
                                        .sort()
                                        .reverse()
                                        .map((y) => (
                                            <option key={y} value={y}>
                                                {y}
                                            </option>
                                        ))}
                                </LabeledSelect>

                                <Button colorScheme="blue" isDisabled={buttonDisabled} onClick={uploadPdf} width={160}>
                                    Upload
                                </Button>
                                {!endorsedLoading && numberOfEndorsed && (
                                    <Text flexShrink={0}>
                                        Endorsed Workplans to upload: {numberOfEndorsed[uploadYear] ?? 0}
                                    </Text>
                                )}
                            </HStack>

                            {launchError && (
                                <SimpleAlert title="Error" variant="subtle" width="full">
                                    {`Failed starting uploading to trim ${launchError}`}
                                </SimpleAlert>
                            )}
                        </>
                    </VStack>
                )}
                {latestRun && (
                    <VStack width="full" alignItems={'flex-start'} spacing={3} paddingTop={3}>
                        <Heading as="p" size="sm">
                            {inProgress ? 'Upload Progress' : 'Last Upload'}
                        </Heading>
                        <Text>
                            Started at: {new Date(latestRun.createdAt).toLocaleTimeString()}
                            {', '}
                            {new Date(latestRun.createdAt).toLocaleDateString()}
                        </Text>
                        <Text>
                            {latestRun.finishedAt ? (
                                <>
                                    Finished at: {new Date(latestRun.finishedAt).toLocaleTimeString()}
                                    {', '}
                                    {new Date(latestRun.finishedAt).toLocaleDateString()}
                                </>
                            ) : (
                                `In progress, running for ${Math.round(
                                    (Date.now() - new Date(latestRun.createdAt).getTime()) / 1000,
                                )} seconds.`
                            )}
                        </Text>

                        {inProgress && (
                            <>
                                <Progress
                                    value={progress}
                                    size="lg"
                                    colorScheme="green"
                                    width="full"
                                    mt={2}
                                    borderRadius={'0.375rem'}
                                />
                                <Box fontSize="md" color="black" textAlign="center" width="full">
                                    {latestRun.totalJobs - latestRun.currentJobs}/{latestRun.totalJobs}
                                </Box>
                            </>
                        )}
                    </VStack>
                )}
                <PDFUploadsList year={uploadYear} latestRun={latestRun} error={runError} />
                {latestRun && (errorsList?.info.total ?? 0) > 0 && (
                    <>
                        <Text>Total: {errorsList && errorsList.info.total}</Text>
                        <Box width="full" overflowX={'auto'}>
                            <ListTable
                                tableCaption="List of Errors"
                                getActions={() => []}
                                columns={columns}
                                data={errorsList && errorsList.items}
                            />
                        </Box>
                        <Box width="full" overflowX={'auto'}>
                            <PaginationToolbar
                                setPageSize={setPageSize}
                                setNewPage={setPage}
                                pageSize={pageSize}
                                pages={pages}
                                page={page}
                            />
                        </Box>
                    </>
                )}
            </VStack>
        </Tile>
    );
};

export default TrimUploadPDF;
