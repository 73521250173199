import React from 'react';
import { FormType } from '@/components/contexts/FormsContext/FormsProvider';
import WorkplanFormSchemas from '@/components/FormSchemas/WorkplanFormSchemas';
import WorkplanIdOrCurrentYearProvider from '@/components/WorkplanItemForm/WorkplanIdOrCurrentYearProvider';
import WorkplanItemForm from '@/components/WorkplanItemForm/WorkplanItemForm';
import { ReadOnlyInterface } from '@/wpt-lib/interfaces/ReadOnlyInterface';
import WorkplanVersionProvider from '@/components/contexts/VersionContext/WorkplanVersionProvider';
import { Box } from '@chakra-ui/react';
import { DashboardHeaderTile } from '@/components/Header/DashboardHeaderTile';
import { BaseFormRequest } from '@/wpt-lib/interfaces/baseFormRequest';
import { BaseFormResponse } from '@/wpt-lib/interfaces/baseFormResponse';
import { useNavigate, useParams } from 'react-router-dom';

interface WorkplanItemHandlerProps extends ReadOnlyInterface {
    formType: 'activity' | 'goal' | 'planned-leave' | 'training' | 'consideration';
    page?: boolean;
}

const formConfig = {
    activity: {
        name: 'Activity',
        baseEndpoint: 'activity',
        formTypeType: FormType.ACTIVITY,
    },
    goal: {
        name: 'Goal',
        baseEndpoint: 'career-goal',
        formTypeType: FormType.PERSONAL_GOAL,
    },
    'planned-leave': {
        name: 'Plan Leave',
        baseEndpoint: 'leave',
        formTypeType: FormType.LEAVE,
    },
    training: {
        name: 'Training',
        baseEndpoint: 'health-and-safety-training',
        formTypeType: FormType.HEALTH_AND_SAFETY_TRAINING,
    },
    consideration: {
        name: 'Consideration',
        baseEndpoint: 'consideration',
        formTypeType: FormType.CONSIDERATION,
    },
};

const WorkplanItemHandler: React.FC<WorkplanItemHandlerProps> = ({ readonly, page, formType }) => {
    const formDetails = formConfig[formType];
    return (
        <WorkplanIdOrCurrentYearProvider>
            <WorkplanVersionProvider>
                {!readonly && (
                    <Box w={'100%'} p={6} pb={0}>
                        <DashboardHeaderTile readonly={true} />
                    </Box>
                )}
                <WorkplanFormSchemas
                    type={formDetails.formTypeType}
                    render={({ schema, uischema }) => (
                        <WorkplanItemForm<BaseFormRequest, BaseFormResponse>
                            name={formDetails.name}
                            pageTitle={(mode: 'Add' | 'Edit' | 'View' | 'Exit' | unknown) => {
                                return mode ? `${mode} ${formDetails.name}` : formDetails.name;
                            }}
                            baseEndpoint={formDetails.baseEndpoint}
                            convertFormDataToRequestBody={(completed, formData, formDataString) => {
                                return {
                                    formData: formDataString,
                                };
                            }}
                            readonly={!!readonly}
                            page={page}
                            autosave={formType === 'training' ? undefined : 30000} // disabling autosave for training items
                            schema={schema}
                            uischema={uischema}
                        />
                    )}
                />
            </WorkplanVersionProvider>
        </WorkplanIdOrCurrentYearProvider>
    );
};

export default WorkplanItemHandler;

export const RouteWorkplanItem = ({ readonly }: { readonly: boolean }) => {
    const { type } = useParams();
    const navigate = useNavigate();

    if (type && type in formConfig)
        return <WorkplanItemHandler formType={type as WorkplanItemHandlerProps['formType']} readonly={readonly} page />;
    else
        setTimeout(() =>
            navigate('/notfound', {
                replace: true,
            }),
        );

    return <></>;
};
