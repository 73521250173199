import React from 'react';
import { ControlProps, isStringControl, RankedTester, rankWith } from '@jsonforms/core';
import { ChakraInputText } from '../chakra-controls';
import { ChakraFormInput } from '../chakra-controls/ChakraFormInput';
import { withNamedJsonFormsControlProps } from '@/forms-renderers/chakra-ui/utils/withNamedJsonFormsHOC';

export const ChakraTextControl = (props: ControlProps) => <ChakraFormInput {...props} input={ChakraInputText} />;

export const chakraTextControlTester: RankedTester = rankWith(1, isStringControl);

export default withNamedJsonFormsControlProps(ChakraTextControl);
