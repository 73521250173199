import React from 'react';
import { CellProps, isStringControl, RankedTester, rankWith, WithClassname } from '@jsonforms/core';
import { ChakraInputText } from '../chakra-controls';
import { withNamedJsonFormsCellProps } from '@/forms-renderers/chakra-ui/utils/withNamedJsonFormsHOC';

export const ChakraTextCell = (props: CellProps & WithClassname) => <ChakraInputText {...props} />;

/**
 * Default tester for text-based/string controls.
 * @type {RankedTester}
 */
export const chakraTextCellTester: RankedTester = rankWith(1, isStringControl);

export default withNamedJsonFormsCellProps(ChakraTextCell);
