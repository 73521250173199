import { Checkbox, Heading, Text } from '@chakra-ui/react';
import { SimpleAlert } from '@/components/SimpleAlert/SimpleAlert';
import React from 'react';
import { usePutRequest } from '@/utils/useStatefulRequests';
import { UpdateDeclarationsDto } from '@/wpt-lib/interfaces/Workplan';
import { useAssignment } from '@/components/contexts/AssignmentContext/useAssignment';
import useWorkplanVersion from '@/components/contexts/VersionContext/useWorkplanVersion';

export const TrainingTileAdditions = ({ label, isReadonly }: { label: string; isReadonly: boolean }) => {
    const { currentAssignment } = useAssignment();
    const { workplanVersion, updateVersion } = useWorkplanVersion();

    const { error: saveError, makeRequest: save } = usePutRequest<unknown, UpdateDeclarationsDto>();

    const handleMandatoryTrainingDeclaration = async (mandatoryTrainingDeclaration: boolean) => {
        updateVersion({ mandatoryTrainingDeclaration });
        await save(
            `/workplan/${workplanVersion?.workplan.id}`,
            {
                declarations: { mandatoryTrainingDeclaration },
            },
            {
                params: {
                    assignment: currentAssignment?.id,
                },
            },
        );
    };

    return (
        <>
            <Heading as="h3" size="sm" color="blue.500" marginTop={10} marginBottom={2}>
                Mandatory Training
            </Heading>
            <Checkbox
                aria-label="Mandatory Training"
                isChecked={!!workplanVersion?.mandatoryTrainingDeclaration}
                onChange={(ev) => !isReadonly && handleMandatoryTrainingDeclaration(ev.target.checked)}
            >
                I have completed all required mandatory training for this year. Please only tick this option when
                training is complete during the workplan year.
            </Checkbox>
            {saveError && (
                <SimpleAlert marginTop={1} variant="subtle">
                    <Text>Could not update the declarations, Please try again later</Text>
                </SimpleAlert>
            )}
            <Heading as="h3" size="sm" color="blue.500" marginTop={10}>
                Additional {label} Comments
            </Heading>
            <Text marginTop={1}>
                Academics and supervisors can add comments relating to training progress in the space below during the
                year.
            </Text>
        </>
    );
};
