import React from 'react';
import { Dialog } from '@/components/Dialog';

interface DeleteRowAlertProps<T> {
    deleteAction: (item: T) => Promise<boolean>;
    deleteError: unknown;
    isOpen: boolean;
    isDeleting: boolean;
    rowToDelete?: T;
    onClose: () => void;
    recordName?: string;
}

export const DeleteRowAlert = <T,>({
    deleteAction,
    deleteError,
    isDeleting,
    isOpen,
    onClose,
    rowToDelete,
    recordName,
}: DeleteRowAlertProps<T>) => {
    const deleteRecord = async () => {
        if (rowToDelete && deleteAction) {
            const success = await deleteAction(rowToDelete);
            if (success) {
                onClose();
            }
        }
    };

    return (
        <Dialog
            colorScheme={'red'}
            dialogHeader={`Delete ${recordName ? recordName : 'record'}`}
            dialogBody={"Are you sure? You can't undo this action afterwards."}
            errorMsg={deleteError ? 'Cannot delete this row, please refresh the page and try again' : undefined}
            dialogAction={deleteRecord}
            dialogConfirmLabel={'Delete'}
            isOpen={isOpen}
            isLoading={isDeleting}
            onClose={onClose}
        />
    );
};
