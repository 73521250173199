import * as React from 'react';
import { Row, ValueCell } from '@/wpt-lib/pdf/common/Table';

const OtherConsideration = ({
    otherDetails,
}: Partial<{
    otherDetails: string;
}>) => (
    <>
        <Row>
            <ValueCell label="Other Details" value={otherDetails} />
        </Row>
    </>
);

export default OtherConsideration;
