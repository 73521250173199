import React from 'react';
import { ControlProps, RankedTester, rankWith, uiTypeIs } from '@jsonforms/core';
import { CurrentYearSelect } from './CurrentYearSelect';
import { withNamedJsonFormsControlProps } from '@/forms-renderers/chakra-ui/utils/withNamedJsonFormsHOC';
import { ChakraFormInput } from '@/forms-renderers/chakra-ui/chakra-controls/ChakraFormInput';

export const CurrentYearSelectRenderer = (props: ControlProps) => {
    return <ChakraFormInput {...props} input={CurrentYearSelect} />;
};

export const currentYearSelectTester: RankedTester = rankWith(2, uiTypeIs('CurrentYearSelect'));

export default withNamedJsonFormsControlProps(CurrentYearSelectRenderer);
