import React from 'react';
import { ControlProps, isEnumControl, OwnPropsOfEnum, RankedTester, rankWith } from '@jsonforms/core';
import { ChakraFormInput } from '../chakra-controls/ChakraFormInput';
import { ChakraSelect } from '../chakra-controls';
import { withNamedJsonFormsEnumProps } from '@/forms-renderers/chakra-ui/utils/withNamedJsonFormsHOC';

export const ChakraEnumControl = (props: ControlProps & OwnPropsOfEnum) => {
    return <ChakraFormInput {...props} input={ChakraSelect} />;
};

export const chakraEnumControlTester: RankedTester = rankWith(2, isEnumControl);

export default withNamedJsonFormsEnumProps(ChakraEnumControl);
