import React, { useCallback, useEffect, useState } from 'react';
import { ArrayLayoutProps, RankedTester, rankWith, uiTypeIs } from '@jsonforms/core';
import { Flex, Spinner, Text } from '@chakra-ui/react';
import { useGetRequest } from '@/utils/useStatefulRequests';
import { namedMemo } from '@/utils/namedMemo';
import { withNamedJsonFormsArrayLayoutProps } from '@/forms-renderers/chakra-ui/utils/withNamedJsonFormsHOC';
import { SimpleAlert } from '@/components/SimpleAlert/SimpleAlert';

export const ChakraLeaveDiffRenderer = namedMemo('ChakraLeaveDiffRenderer', (props: ArrayLayoutProps) => {
    const { uischema } = props;
    const [businessDays, setBusinessDays] = useState(0);
    const { makeRequest, loading, error } = useGetRequest<number>();
    const startDate = uischema.options?.startDate as string;
    const endDate = uischema.options?.endDate as string;

    const fetchBusinessDays = useCallback(async () => {
        const response = await makeRequest('/holiday/business-days', {
            params: {
                startDate,
                endDate,
            },
        });
        setBusinessDays(response.data);
    }, [startDate, endDate]);

    useEffect(() => {
        if (startDate && endDate && new Date(startDate) <= new Date(endDate)) {
            fetchBusinessDays();
        }
    }, [startDate, endDate]);

    return (
        <>
            {error && (
                <SimpleAlert variant="subtle" w="full">
                    Could not load the total days, please refresh the page and try again
                </SimpleAlert>
            )}
            <Flex fontSize="md" alignItems="center" gap={2}>
                <Text>Total days:</Text>
                {loading ? (
                    <Flex alignItems="center">
                        <Spinner size="sm" mr={2} />
                        <Text>Loading...</Text>
                    </Flex>
                ) : (
                    <Text>{businessDays}</Text>
                )}
            </Flex>
        </>
    );
});

export const chakraLeaveDiffRendererTester: RankedTester = rankWith(1, uiTypeIs('ChakraLeaveDiffRenderer'));

export default withNamedJsonFormsArrayLayoutProps(ChakraLeaveDiffRenderer);
