import React, { ReactElement, useState } from 'react';
import { Box, Flex, Table, TableCaption, Tbody, Td, Text, TextProps, Th, Thead, Tr } from '@chakra-ui/react';
import { RowActionButton } from '@/components/Table/RowActionButton';
import { ExpandableText } from '@/components/ExpandableText';
import TextHighlighting from '@/components/TextHighlighting/TextHighlighting';
import { Icon, WarningIcon } from '@chakra-ui/icons';

export interface Column<T> {
    name: string;
    field?: string;
    nowrap?: boolean;
    highlight?: (item: T) => boolean;
    noOfLines?: TextProps['noOfLines'];
    getData: (item: T) => string | number | ReactElement | undefined;
}

export interface Action<T> {
    id: string;
    event?: (item: T) => void;
    onSelectEvent?: (item: T[]) => void;
    label: string;
    ariaLabel?: string;
    isLoading?: boolean;
    isDisabled?: (row: T) => boolean;
    error?: unknown;
    icon?: ReactElement;
}

interface TableProps<T> {
    tableCaption: string;
    columns: Column<T>[];
    errorRows?: Record<string, string>;
    idKey?: keyof T;
    getActions: (row: T, selectedRows?: T[]) => Action<T>[];
    data?: T[];
    info?: T[];
    hideOption?: boolean;
    message?: ReactElement;
}

function getCellContent<T>(col: Column<T>, row: T) {
    const data = col.getData(row);

    if (React.isValidElement(data)) return data;
    else if (col.noOfLines) return <ExpandableText text={'' + data} noOfLines={2} />;
    else {
        const text = '' + data;
        return (
            <Text>
                <TextHighlighting query={col.highlight && col.highlight(row) ? text : ''} version={1}>
                    {text}
                </TextHighlighting>
            </Text>
        );
    }
}

export const ListTable = <T,>(props: TableProps<T>) => {
    const [selectedRow, setSelectedRow] = useState<T | undefined>();
    const [selectedRows, setSelectedRows] = useState<T[]>([]);

    const toggleRowSelection = async (row: T, callback: (rows: T[]) => void) => {
        const index = selectedRows.indexOf(row);
        let newSelectedRows: T[];
        if (index > -1) {
            newSelectedRows = selectedRows.filter((selectedRow) => selectedRow !== row);
        } else {
            newSelectedRows = [...selectedRows, row];
        }
        await setSelectedRows(newSelectedRows);
        await callback(newSelectedRows);
    };

    const setClickedRow = async (row: T, callback: () => void) => {
        await setSelectedRow(row);
        await callback();
    };

    const rowActions = props.data?.map((row) => props.getActions(row, selectedRows)) ?? [];
    const hasActions = rowActions.flatMap((actions) => actions).length > 0;

    return (
        <Box overflow="auto" w="full">
            {props.message && props.message}
            <Table variant="striped" colorScheme="gray" marginBottom="16px">
                <TableCaption
                    textAlign="start"
                    marginTop="0"
                    placement="top"
                    aria-live="polite"
                    fontSize="md"
                    color="gray.700"
                >
                    {props.tableCaption}
                </TableCaption>
                <Thead>
                    <Tr>
                        {props.columns.map((col, i) => {
                            return (
                                <Th key={i} color="gray.700">
                                    {col.name}
                                </Th>
                            );
                        })}
                        {hasActions && (
                            <Th color="gray.700" position="sticky" right={0}>
                                {!props.hideOption ? 'Options' : ''}
                            </Th>
                        )}
                    </Tr>
                </Thead>
                <Tbody>
                    {props.data && props.data.length > 0 ? (
                        props.data.map((row, i) => {
                            const actions = rowActions[i];
                            const isSelected = selectedRows.includes(row);
                            const rowId = props.idKey ? row[props.idKey] : undefined;
                            const isRejected = props.errorRows && rowId && `${rowId}` in props.errorRows;
                            const rowBgColor = isSelected
                                ? 'blue.100 !important'
                                : isRejected
                                ? 'red.100 !important'
                                : undefined;

                            return (
                                <React.Fragment key={i}>
                                    <Tr key={i}>
                                        {props.columns.map((col, j) => {
                                            return (
                                                <Td
                                                    key={j}
                                                    whiteSpace={col.nowrap ? 'nowrap' : 'pre-wrap'}
                                                    bg={rowBgColor}
                                                >
                                                    {getCellContent(col, row)}
                                                </Td>
                                            );
                                        })}
                                        {actions.length > 0 ? (
                                            <Td position="sticky" right={0} bg={rowBgColor}>
                                                <Flex gridGap={2}>
                                                    {actions.map((action, k) => (
                                                        <RowActionButton
                                                            key={k}
                                                            label={action.label}
                                                            icon={action.icon}
                                                            isLoading={action.isLoading && selectedRow === row}
                                                            isDisabled={action.isDisabled && action.isDisabled(row)}
                                                            event={() =>
                                                                setClickedRow(
                                                                    row,
                                                                    () => action.event && action.event(row),
                                                                )
                                                            }
                                                            ariaLabel={action.ariaLabel}
                                                            isCheckbox={action.id === 'select'}
                                                            isChecked={isSelected}
                                                            onCheckboxChange={() =>
                                                                toggleRowSelection(
                                                                    row,
                                                                    (rows) =>
                                                                        action.onSelectEvent &&
                                                                        action.onSelectEvent(rows),
                                                                )
                                                            }
                                                        />
                                                    ))}
                                                </Flex>
                                            </Td>
                                        ) : (
                                            hasActions && <Td position="sticky" right={0} />
                                        )}
                                    </Tr>
                                    {isRejected && !isSelected && (
                                        <Tr key={`rejected-${i}`}>
                                            <Td
                                                colSpan={props.columns.length + (hasActions ? 1 : 0)}
                                                bg="red.100 !important"
                                            >
                                                <Flex alignItems="center" gap={2}>
                                                    <Icon as={WarningIcon} color="red.500" boxSize={4} />{' '}
                                                    <Text color="red.500" id={`rejected-message-${rowId}`}>
                                                        {props.errorRows && rowId && props.errorRows[`${rowId}`]}
                                                    </Text>
                                                </Flex>
                                            </Td>
                                        </Tr>
                                    )}
                                </React.Fragment>
                            );
                        })
                    ) : (
                        <Tr>
                            <Td colSpan={props.columns.length + (hasActions ? 1 : 0)}>
                                <Text
                                    fontWeight="bold"
                                    fontSize={'4xl'}
                                    color={'gray.700'}
                                    p={3}
                                    textAlign={'center'}
                                    as="p"
                                >
                                    No records found
                                </Text>
                            </Td>
                        </Tr>
                    )}
                </Tbody>
            </Table>
        </Box>
    );
};
