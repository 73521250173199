import { measureTextWidth } from '@/wpt-lib/utils/chart';
import { getConfig } from '@/wpt-lib/utils/config';

type CellHeightFunction = (
    text: string,
    label: string,
    widthPercent: number,
    fontSize?: number,
    single?: boolean,
) => number;

export const getCellHeight: CellHeightFunction = (...args) => {
    switch (getConfig().featureFlags.pdfCellMethod) {
        case 'none':
            return getCellHeightUnset(...args);
        case 'div':
            return getCellHeightByDiv(...args);
        case 'lines':
        // Falls through
        default:
            return getCellHeightByLines(...args);
    }
};

const getCellHeightUnset: CellHeightFunction = () => -1;

const getCellHeightByLines: CellHeightFunction = (text, label, widthPercent, fontSize, single) => {
    /* Only set height if element is a value cell as these are the problematic cells
     * that clip and overflow. */

    const paragraphs = text.split('\n');
    const numLines = paragraphs.reduce(
        (lines, p) =>
            lines +
            Math.max(
                /* Calculation numbers:
                 *   - 842 - pixel width of A4 landscape at 72dpi
                 *   - 100 - padding of page around table, 50px each side
                 *   - widths - percentage cell width in row
                 */
                Math.ceil(measureTextWidth(p, fontSize ?? 10, 'Akademie') / (((842 - 100) * widthPercent) / 100)),
                /*  At least 1 line for a paragraph otherwise empty lines won't be calculated */
                1,
            ),
        0,
    );

    /* Turns out in react-pdf, line height is a proportion of font size
     * with default of 100%, adding 2px is an empirical estimation to allow
     * kerning space. */
    const lineHeight = (fontSize ?? 10) + 2;

    /* Number of lines with 10px padding. */
    let cell = numLines * lineHeight + 10;
    /* If not a single cell, added a line for heading and margin below. */
    if (!single) cell += lineHeight + 5;

    return cell;
};

const getCellHeightByDiv: CellHeightFunction = (text, label, widthPercent, fontSize) => {
    const valueHeight = getTextHeight(text, widthPercent, fontSize);
    const labelHeight = getTextHeight(label, widthPercent, 10) + 5;

    return Math.ceil(valueHeight + labelHeight + 12);
};

function getTextHeight(textInput: string, widthPercent: number, fontSize?: number) {
    const pageWidth = 770; //table width

    //table cell width per percent, minus 10 margin
    const valueCellWidth = pageWidth * widthPercent * 0.01 - 10;

    const textEl = document.createElement('div');
    document.body.appendChild(textEl);

    textEl.style.font = 'Akademie';
    textEl.style.fontSize = `${fontSize || 11}px`;
    textEl.style.height = 'auto';
    textEl.style.width = `${valueCellWidth}px`;
    textEl.style.position = 'absolute';
    textEl.style.whiteSpace = 'pre-wrap';
    textEl.style.lineHeight = `${(fontSize || 11) + 1.25}px`;
    textEl.innerHTML = textInput;

    const textHeight = Math.ceil(textEl.offsetHeight);
    document.body.removeChild(textEl);

    return textInput.length === 0 ? 0 : textHeight;
}
