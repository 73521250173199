import React from 'react';

import { Flex, HStack, Text } from '@chakra-ui/react';
import { BreadCrumb, BreadCrumbItem } from '../BreadCrumb';
import { Workplan } from '@/wpt-lib/interfaces/Workplan';

interface FormContainerProps {
    breadCrumbItems: BreadCrumbItem[];
    workplan?: Workplan;
    name?: string;
    workplanItemType?: string;
    isSupervisor?: boolean;
    isFromDashboard?: boolean;
    isFromUserSupervision?: boolean;
    isFromWorkplan?: boolean;
}

export const WorkplanItemHeader = (props: FormContainerProps) => {
    const workplanItemLabel = props.breadCrumbItems[1].label;
    const { name } = props;

    return (
        <>
            <BreadCrumb breadCrumbs={props.breadCrumbItems} color={'blue.500'} />
            <Flex justifyContent={'space-between'} alignItems={'center'}>
                <HStack>
                    <Text as="h2" fontSize={'lg'} fontWeight={'semibold'} textColor={'black'}>
                        {name ? `${props.workplanItemType}: ${name}` : workplanItemLabel}
                    </Text>
                </HStack>
            </Flex>
        </>
    );
};
