import React from 'react';
import { ControlProps, isNumberControl, RankedTester, rankWith } from '@jsonforms/core';
import { ChakraFormInput } from '../chakra-controls/ChakraFormInput';
import { ChakraInputDecimal } from '../chakra-controls';
import { withNamedJsonFormsControlProps } from '@/forms-renderers/chakra-ui/utils/withNamedJsonFormsHOC';

export const ChakraDecimalControl = (props: ControlProps) => <ChakraFormInput {...props} input={ChakraInputDecimal} />;

export const chakraDecimalControlTester: RankedTester = rankWith(2, isNumberControl);

export default withNamedJsonFormsControlProps(ChakraDecimalControl);
