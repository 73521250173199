import { Tile } from '@/components/Tile';
import { WorkplanEOYRating } from '@/components/WorkplanEOYRating/WorkplanEOYRating';
import React from 'react';
import WorkplanVersionStateDisplay from '@/components/contexts/VersionContext/WorkplanVersionStateDisplay';
import { WorkplanStage, WorkplanState, WorkplanVersion } from '@/wpt-lib/interfaces/Workplan';
import { isWorkplanInState } from '@/utils/isWorkplanReadonly';
import useWorkplanVersion from '@/components/contexts/VersionContext/useWorkplanVersion';
import useNavigateOptions from '@/components/contexts/NaviateContext/useNavigateOptions';
import { Assignment } from '@/wpt-lib/interfaces/Assignment';

const WorkplanEOYRatingTile = ({ assignment }: { assignment: Assignment }) => {
    function showRatings(workplanVersion: WorkplanVersion, current: boolean, supervisor: boolean, admin: boolean) {
        /* Ratings have been set and may be viewed by academic and supervisors. */
        if (workplanVersion.state === WorkplanState.ENDORSED) return true;

        /* Supervisor or admin can edit ratings. */
        return (
            current &&
            (supervisor || admin) &&
            isWorkplanInState(workplanVersion, WorkplanState.REVIEW, WorkplanState.MODERATION) &&
            workplanVersion.stage === WorkplanStage.END_YEAR
        );
    }
    const { supervisor, admin } = useNavigateOptions();
    const { current } = useWorkplanVersion();

    return (
        <WorkplanVersionStateDisplay
            render={([workplan, workplanVersion]) => {
                const props = {
                    workplan,
                    workplanVersion,
                    currentAssignment: assignment,
                };
                return (
                    <>
                        {showRatings(workplanVersion, current, supervisor, admin) && (
                            <Tile colspan={2} heading={'END OF YEAR RATING'}>
                                <WorkplanEOYRating {...props} />
                            </Tile>
                        )}
                    </>
                );
            }}
        />
    );
};

export default WorkplanEOYRatingTile;
