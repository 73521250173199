import * as React from 'react';
import { Row, ValueCell } from '@/wpt-lib/pdf/common/Table';

const OutsideWorkConsideration = () => (
    <>
        <Row>
            <ValueCell
                value={'Information relating to outside work opportunities can be found on StaffConnect.'}
                label=""
            />
        </Row>
    </>
);

export default OutsideWorkConsideration;
