export enum StatusType {
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    NOT_YET_STARTED = 'NOT_YET_STARTED',
    NO_LONGER_UNDERTAKING = 'NO_LONGER_UNDERTAKING',
}

export const progressUpdateStatus: Record<StatusType, string> = {
    [StatusType.IN_PROGRESS]: 'In Progress',
    [StatusType.COMPLETED]: 'Completed',
    [StatusType.NOT_YET_STARTED]: 'Not Yet Started',
    [StatusType.NO_LONGER_UNDERTAKING]: 'No Longer Undertaking',
};

export interface ProgressUpdate {
    id: number;
    status: keyof typeof StatusType;
    createdAt: string;
    updatedAt: string;
    description: string;
    approved: boolean;
}
