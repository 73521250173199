import WorkplanStateDisplay from '@/components/contexts/WorkplanContext/WorkplanStateDisplay';
import { Dialog } from '@/components/Dialog';
import { Assignment } from '@/wpt-lib/interfaces/Assignment';
import {
    UpdateWorkplanEOYRatingDto,
    UpdateWorkplanStateDto,
    Workplan,
    WorkplanState,
    WorkplanVersion,
} from '@/wpt-lib/interfaces/Workplan';
import usePageTitle from '@/utils/hooks/usePageTitle';
import { isWorkplanCompleted, isWorkplanReadonly } from '@/utils/isWorkplanReadonly';
import { usePutRequest } from '@/utils/useStatefulRequests';
import { Button, Flex, GridItem, HStack, VStack } from '@chakra-ui/react';
import React, { useState } from 'react';

import useNavigateOptions from '@/components/contexts/NaviateContext/useNavigateOptions';
import { isNullish } from '@/wpt-lib/utils/isNullish';

export function goToNextVersion() {
    const url = window.location.toString();

    const start = url.lastIndexOf('/');
    const end = url.indexOf('?', start);

    let nextVersion = url.substring(0, start + 1) + (+url.substring(start + 1, end > 0 ? end : url.length) + 1);
    if (end > 0) nextVersion += url.substring(end);

    window.location.replace(nextVersion);
}

interface SubmitForReviewButtonTileProps {
    currentAssignment: Assignment;
    workplan: Workplan;
    workplanVersion: WorkplanVersion;
}
const SubmitForReviewButtonTile = ({
    workplan,
    workplanVersion,
    currentAssignment,
}: SubmitForReviewButtonTileProps) => {
    usePageTitle(`My ${workplan.year} Workplan`);

    const { admin } = useNavigateOptions();

    const [confirmOpen, setConfirmOpen] = useState(false);

    const {
        loading: isSubmittingWorkplan,
        error: submittingError,
        makeRequest: submitWorkplan,
    } = usePutRequest<unknown, UpdateWorkplanStateDto | UpdateWorkplanEOYRatingDto>();

    const submitForReview = async () => {
        try {
            await submitWorkplan(
                `workplan/${workplan.id}`,
                {
                    state: workplan.state === WorkplanState.DRAFT ? WorkplanState.SUBMITTED : WorkplanState.REVIEW,
                },
                {
                    params: {
                        assignment: currentAssignment.id,
                    },
                },
            );

            if (admin && workplan.assignment?.id !== currentAssignment.id) goToNextVersion();
            else window.location.reload();
        } catch (e) {
            // handled by hook
        }
    };

    const completedDeclaration = !isNullish(workplanVersion?.conflictOfInterestDeclaration);

    return (
        <WorkplanStateDisplay
            render={(workplan) => {
                const isSubmittable =
                    workplan && !isWorkplanReadonly(workplan, false) && (completedDeclaration || admin);
                return (
                    <>
                        <Dialog
                            dialogHeader="Submit this workplan?"
                            dialogConfirmLabel="Submit"
                            dialogBody={
                                admin
                                    ? 'Submitting workplan on behalf of academic will be create a new version of workplan.'
                                    : "Your supervisor will be notified about your submission, and you won't be able to edit your workplan item until your workplan is approved"
                            }
                            isOpen={confirmOpen}
                            isLoading={isSubmittingWorkplan}
                            errorMsg={
                                submittingError
                                    ? 'Could not submit Workplan, refresh the page to try again, or return to Dashboard.'
                                    : ''
                            }
                            colorScheme="blue"
                            dialogAction={submitForReview}
                            onClose={() => setConfirmOpen(false)}
                        />
                        {!isWorkplanCompleted(workplan) ? (
                            <GridItem colSpan={2}>
                                <Flex direction="row" w={'full'} justifyContent="flex-end">
                                    <VStack bg={'white'} p={6}>
                                        <HStack w="full" justifyContent="flex-end">
                                            <Button
                                                colorScheme={'blue'}
                                                isLoading={isSubmittingWorkplan}
                                                flexShrink={0}
                                                isDisabled={!isSubmittable}
                                                onClick={() => setConfirmOpen(true)}
                                            >
                                                Submit Workplan for Review
                                            </Button>
                                        </HStack>
                                    </VStack>
                                </Flex>
                            </GridItem>
                        ) : (
                            <></>
                        )}
                    </>
                );
            }}
        />
    );
};

export default SubmitForReviewButtonTile;
