import React, { ChangeEventHandler, useCallback } from 'react';
import { CellProps, WithClassname } from '@jsonforms/core';
import { InputProps, Text } from '@chakra-ui/react';
import { namedMemo } from '@/utils/namedMemo';
import { debounce, merge } from 'lodash';
import { useGetRequest } from '@/utils/useStatefulRequests';
import { ChakraAutoComplete } from '@/forms-renderers/chakra-ui/additional/ChakraAutoComplete';
import { OpenCourse, OpenCourseType } from '@/wpt-lib/interfaces/OpenCourse';

export const ChakraOpenCourseAutoCompletion = namedMemo(
    'ChakraOpenCourseAutoCompletion',
    (props: CellProps & WithClassname & InputProps) => {
        const { config, uischema } = props;
        const appliedUiSchemaOptions = merge({}, config, uischema.options);
        const { path, handleChange } = props;
        const {
            makeRequest: getMatchedOpenCourses,
            responseData: matchedOpenCourses,
            loading,
            error,
        } = useGetRequest<OpenCourse[]>();

        const onProjectChange: ChangeEventHandler<HTMLInputElement> = useCallback(
            async (event) => {
                const value = event.target.value;
                handleChange(path, value);
                await searchForCourses(value);
            },
            [handleChange, path],
        );
        const setOpenCourseDetails = (openCourse: OpenCourse) => {
            handleChange(path, openCourse.open_course_name);
        };
        const searchForCourses = debounce(async (query) => {
            try {
                if (query.length === 0) {
                    return;
                }
                const openCourseType = appliedUiSchemaOptions.openCourseType;
                const params = {
                    searchQuery: query,
                    openCourseType: OpenCourseType[openCourseType as keyof typeof OpenCourseType],
                };
                await getMatchedOpenCourses(`uts-api/open-courses`, {
                    params: params,
                });
            } catch {
                // handled by Hook
            }
        }, 1000);

        return (
            <ChakraAutoComplete
                {...props}
                error={error}
                listItemAction={setOpenCourseDetails}
                autoCompleteType={'open course'}
                onChange={onProjectChange}
                loading={loading}
                matchedRecords={matchedOpenCourses}
                renderSuggestion={(openCourse) => (
                    <>
                        <Text textAlign={'left'}>{openCourse.open_course_name}</Text>
                        <Text textAlign={'left'}>{openCourse.sessionCode}</Text>
                    </>
                )}
            />
        );
    },
);
