import React from 'react';
import { createUnprovisionedContext } from '@/wpt-lib/utils/contextHelpers';
import { useQuery } from '@/utils/hooks/useQuery';

export const UserSupervisionFiltersContext = createUnprovisionedContext<{
    params: string;
}>('UserSupervisionFiltersContext');

const UserSupervisionFiltersProvider = (props: { children: React.ReactNode }) => {
    const params = useQuery();

    const allowed = ['search', 'access', 'faculty', 'org', 'supervisor', 'status', 'sort', 'order'];

    const filtered = new URLSearchParams(
        Object.keys(params)
            .filter((key) => allowed.includes(key))
            .reduce((obj, key) => {
                return {
                    ...obj,
                    [key]: params[key],
                };
            }, {}),
    ).toString();

    return (
        <UserSupervisionFiltersContext.Provider value={{ params: filtered }}>
            {props.children}
        </UserSupervisionFiltersContext.Provider>
    );
};

export default UserSupervisionFiltersProvider;
