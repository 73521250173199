import React, { useState } from 'react';
import { Button, HStack, Input, InputGroup, InputRightElement, Text } from '@chakra-ui/react';

type Props = {
    search: string;
    doSearch: (search: string) => void;
};

const SearchBar = ({ search, doSearch }: Props) => {
    const [dirty, setDirty] = useState(search);
    return (
        <HStack overflowX={'auto'} width="full" py={3} gap={3}>
            <Text fontWeight={'bold'}>Search</Text>
            <InputGroup size={'md'} minWidth={'200'} maxWidth={'500'}>
                <Input
                    pr={'100'}
                    type={'text'}
                    onChange={(e) => {
                        setDirty(e.target.value);
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            doSearch(dirty);
                        }
                    }}
                    value={dirty}
                />
                <InputRightElement width="100">
                    <Button
                        margin={'0'}
                        size={'md'}
                        onClick={() => {
                            doSearch(dirty);
                        }}
                    >
                        Search
                    </Button>
                </InputRightElement>
            </InputGroup>
        </HStack>
    );
};

export default SearchBar;
