import { namedMemo } from '@/utils/namedMemo';
import { EnumCellProps, WithClassname } from '@jsonforms/core';
import merge from 'lodash/merge';
import { Select } from '@chakra-ui/react';
import React from 'react';
import { useWorkplan } from '@/components/contexts/WorkplanContext/useWorkplan';

export const CurrentYearSelect = namedMemo('CurrentYearSelect', (props: EnumCellProps & WithClassname) => {
    const { data, config, id, enabled, uischema, path, handleChange } = props;
    const appliedUiSchemaOptions = merge({}, config, uischema.options);

    const currentYear = new Date().getFullYear();

    const { workplan } = useWorkplan();
    const startYear = +(workplan?.year ?? currentYear);

    return (
        <Select
            id={id}
            value={data ?? ''}
            isDisabled={!enabled}
            autoFocus={appliedUiSchemaOptions.focus}
            placeholder={appliedUiSchemaOptions.placeholder}
            onChange={(ev) => handleChange(path, ev.target.value)}
            role="listbox"
        >
            <option value={startYear} style={{ background: 'unset' }}>
                {startYear}
            </option>
            <option value={startYear + 1} style={{ background: 'unset' }}>
                {startYear + 1}
            </option>{' '}
            <option value={startYear + 2} style={{ background: 'unset' }}>
                {startYear + 2}
            </option>
        </Select>
    );
});
