import React, { useEffect } from 'react';
import { Box, Checkbox, FormControl, Link, Radio, RadioGroup, Text, VStack } from '@chakra-ui/react';
import InfoTooltip from '@/components/Tooltip/InfoTooltip';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { usePutRequest } from '@/utils/useStatefulRequests';
import { UpdateDeclarationsDto, WorkplanVersion } from '@/wpt-lib/interfaces/Workplan';
import { SimpleAlert } from '@/components/SimpleAlert/SimpleAlert';
import { Assignment } from '@/wpt-lib/interfaces/Assignment';
import { LoadingSpinner } from '@/components';
import { Tile } from '@/components/Tile';
import { isNullish } from '@/wpt-lib/utils/isNullish';
import useWorkplanVersion from '@/components/contexts/VersionContext/useWorkplanVersion';
import { dedent } from '@/utils/stringUtils';

interface DeclarationsTileProps {
    currentAssignment: Assignment;
    isReadonly: boolean;
}

function declarationValue(workplanVersion?: WorkplanVersion): number | undefined {
    if (isNullish(workplanVersion?.conflictOfInterestDeclaration)) return undefined;
    else return workplanVersion?.conflictOfInterestDeclaration ? 1 : 0;
}

const DeclarationsTile: React.FC<DeclarationsTileProps> = ({ currentAssignment, isReadonly }) => {
    const { workplanVersion, loading, updateVersion } = useWorkplanVersion();

    const { error: saveError, makeRequest: save } = usePutRequest<unknown, UpdateDeclarationsDto>();

    const formik = useFormik({
        initialValues: {
            plannedLeaveDeclaration: workplanVersion?.plannedLeaveDeclaration || false,
            conflictOfInterestDeclaration: declarationValue(workplanVersion),
        },
        validationSchema: Yup.object().shape({
            plannedLeaveDeclaration: Yup.boolean(),
            conflictOfInterestDeclaration: Yup.number().required('Required'),
        }),
        onSubmit: () => {
            // Not required because we are saving it when change a happens
        },
    });

    useEffect(() => {
        formik.resetForm({
            values: {
                plannedLeaveDeclaration: workplanVersion?.plannedLeaveDeclaration || false,
                conflictOfInterestDeclaration: declarationValue(workplanVersion),
            },
        });
        formik.validateForm({
            plannedLeaveDeclaration: workplanVersion?.plannedLeaveDeclaration || false,
            conflictOfInterestDeclaration: declarationValue(workplanVersion),
        });
    }, [workplanVersion]);

    const handlePlannedLeaveUpdate = async (plannedLeaveDeclaration: boolean) => {
        await save(
            `/workplan/${workplanVersion?.workplan.id}`,
            {
                declarations: { plannedLeaveDeclaration },
            },
            {
                params: {
                    assignment: currentAssignment.id,
                },
            },
        );

        updateVersion({ plannedLeaveDeclaration });
        await formik.setFieldValue('plannedLeaveDeclaration', plannedLeaveDeclaration);
    };

    const handleConflictOfInterestUpdate = async (value: number) => {
        const state = value === 1;
        await save(
            `/workplan/${workplanVersion?.workplan.id}`,
            {
                declarations: { conflictOfInterestDeclaration: state },
            },
            {
                params: {
                    assignment: currentAssignment.id,
                },
            },
        );
        updateVersion({ conflictOfInterestDeclaration: state });
        await formik.setFieldValue('conflictOfInterestDeclaration', value);
    };

    const conflictOfInterestHelpText = dedent(
        `UTS is committed to maintaining a culture of integrity and transparency in line with the behavioural standards outlines in the UTS Code of Conduct.
        
        All staff must proactively declare and manage any actual, perceived, or potential conflicts of interest as part of their annual workplanning. The requirements for declaring conflicts of interest are outlined in the Conflict of Interest Disclosure Policy.
        
        I have discussed conflicts of interest with my supervisor and understand the policy requirements and the obligation for continuous disclosure.
        
        If a conflict of interest is identified, the Declaration of Interest form is available from the Speak Up website and must be completed`,
    );

    const plannedLeaveHelpText =
        'Leave is an important aspect to maintaining staff wellbeing and to promoting a healthy workplace. Please ensure that you speak with your supervisor about any indicative or confirmed plans for the year.';

    return loading ? (
        <LoadingSpinner />
    ) : (
        <Tile colspan={2} mt={-6}>
            <VStack gap={5} alignItems="flex-start">
                <>
                    {saveError && (
                        <SimpleAlert variant="subtle">
                            <Text>Could not update the declarations, Please try again later</Text>
                        </SimpleAlert>
                    )}
                </>
                <Box>
                    <Text as="h3" size="md" fontWeight="medium" marginBottom={2} alignContent="center">
                        Planned leave
                        <InfoTooltip tooltipLabel={'Planned Leave Tooltip'} tooltipInfoText={plannedLeaveHelpText} />
                    </Text>

                    <Checkbox
                        aria-label="Planned leave"
                        isChecked={formik.values.plannedLeaveDeclaration}
                        isDisabled={loading}
                        onChange={(ev) => handlePlannedLeaveUpdate(ev.target.checked)}
                        isReadOnly={isReadonly || loading}
                        size="lg"
                    >
                        I have discussed my leave plans with my supervisor for this workplanning year
                    </Checkbox>
                </Box>
                <form>
                    <FormControl as="fieldset">
                        <Text as="h3" size="md" fontWeight="medium" alignContent="center">
                            Conflict of Interest Declaration{' '}
                            <InfoTooltip
                                tooltipLabel={'Conflict of Interest Declaration Tooltip'}
                                tooltipInfoText={conflictOfInterestHelpText}
                            />
                        </Text>
                        <RadioGroup
                            name="conflictOfInterestDeclaration"
                            isDisabled={loading}
                            value={formik.values.conflictOfInterestDeclaration}
                            onChange={(value) => handleConflictOfInterestUpdate(+value)}
                        >
                            <VStack alignItems="flex-start">
                                <Text fontWeight="medium">
                                    Please read the above information regarding Conflicts of Interest and select ONE of
                                    the following boxes:
                                </Text>
                                {[
                                    'I understand and have no interest to declare',
                                    'I understand and have declared my conflicts of interest via the Declaration of Interest form from the Speak Up website.',
                                ].map((value, i) => (
                                    <Radio
                                        aria-label={value}
                                        isReadOnly={isReadonly || loading}
                                        key={i}
                                        value={i}
                                        size={'lg'}
                                        cursor={'pointer'}
                                    >
                                        {i === 1 ? (
                                            <>
                                                {value.substring(0, value.length - 17)}{' '}
                                                <Link
                                                    href="https://www.uts.edu.au/about/uts-governance/speak-up"
                                                    target="-blank"
                                                    rel="noreferrer"
                                                >
                                                    Speak Up website
                                                </Link>
                                                .
                                            </>
                                        ) : (
                                            value
                                        )}
                                    </Radio>
                                ))}
                            </VStack>
                        </RadioGroup>
                    </FormControl>
                </form>
                {!formik.isValid && !isReadonly && (
                    <SimpleAlert variant={'subtle'} status="warning">
                        You have to complete this declaration before submitting your workplan for review.
                    </SimpleAlert>
                )}
            </VStack>
        </Tile>
    );
};

export default DeclarationsTile;
