import React, { ChangeEventHandler, useCallback, useState } from 'react';
import { CellProps, WithClassname } from '@jsonforms/core';
import { Flex, InputProps, Spinner, Text } from '@chakra-ui/react';
import { namedMemo } from '@/utils/namedMemo';
import { debounce } from 'lodash';
import { useGetRequest } from '@/utils/useStatefulRequests';
import { Project } from '@/wpt-lib/interfaces/ResearchMaster';
import { ChakraAutoComplete } from '@/forms-renderers/chakra-ui/additional/ChakraAutoComplete';

export const ChakraResearchMasterAutoCompletion = namedMemo(
    'ChakraResearchMasterAutoCompletion',
    (props: CellProps & WithClassname & InputProps) => {
        const { path, handleChange } = props;
        const [matchedResearch, setMatchedResearch] = useState<Project[]>();
        const onProjectChange: ChangeEventHandler<HTMLInputElement> = useCallback(
            async (event) => {
                const value = event.target.value;
                handleChange(path, value);
                await searchForProject(value);
            },
            [handleChange, path],
        );

        const [loadingDetails, setLoadingDetails] = useState<boolean>(false);

        const searchForProject = debounce(async (query) => {
            const res = await getResearchMatchedQuery(`uts-api/research-master/search?searchQuery=${query}`);
            setMatchedResearch(res.data);
        }, 1000);

        const getProjectDetails = async (research: Project) => {
            setLoadingDetails(true);
            const res = await getResearch(`uts-api/research-master/${research.id}`);

            const researchMasterData = {
                researchMasterAutoCompleteValue: research.title,
                projectEndDate: res.data.end_date ? new Date(res.data.end_date).toLocaleDateString() : 'Not Specified',
                fundingOrg: res.data.funding_org ?? 'Not Specified',
                incomeCategory: res.data.income_category ?? 'Not Specified',
                existingLeadResearcher: res.data.lead_researcher ?? 'Not Specified',
            };

            handleChange(path.substring(0, path.lastIndexOf('.')), researchMasterData);
            setMatchedResearch([]);
            setLoadingDetails(false);
        };
        const { makeRequest: getResearchMatchedQuery, loading, error } = useGetRequest<Project[]>();

        const { makeRequest: getResearch } = useGetRequest<Project>();

        return (
            <Flex flexDirection={'column'}>
                <ChakraAutoComplete
                    {...props}
                    error={error}
                    listItemAction={getProjectDetails}
                    autoCompleteType={'research master project'}
                    onChange={onProjectChange}
                    loading={loading}
                    matchedRecords={matchedResearch}
                    renderSuggestion={(research) => (
                        <>
                            <Text textAlign={'left'}>{research.title}</Text>
                        </>
                    )}
                />
                {loadingDetails && (
                    <Flex alignItems={'center'} justifyContent={'start'} position="absolute" right={3} top={20}>
                        <Text p={3} fontSize="sm">
                            loading project details...{' '}
                        </Text>
                        <Spinner color={'blue'} size={'sm'} />
                    </Flex>
                )}
            </Flex>
        );
    },
);
