import { BreadCrumb } from '@/components/BreadCrumb';
import { Tile } from '@/components/Tile';
import { Assignment } from '@/wpt-lib/interfaces/Assignment';
import { Flex, HStack, Text } from '@chakra-ui/react';
import React from 'react';
import { LoadingSpinner } from '@/components';
import { useUserSupervisionFilters } from '@/components/contexts/UserSupervisionFiltersContext/useUserSupervisionFilters';
import useUserSupervisionBreadcrumbs from '@/components/pages/Admin/UserSupervision/useUserSupervisionBreadcrumbs';
import useNavigateOptions from '@/components/contexts/NaviateContext/useNavigateOptions';
import { formatName } from '@/wpt-lib/utils/formatName';

interface AdminHeaderProps {
    assignment?: Assignment;
    loading: boolean;
}

export const AdminHeader = (props: AdminHeaderProps) => {
    const { assignment, loading } = props;
    const params = useUserSupervisionFilters().params;
    const breadCrumbs = useUserSupervisionBreadcrumbs(0);

    const { admin } = useNavigateOptions();

    if (!assignment || loading) {
        return <LoadingSpinner fullScreen={false} />;
    }

    const userName = `${assignment && `${formatName(assignment.user)}`}`;

    const getBreadCrumbs = () => {
        const breadcrumbs = [
            {
                label: 'Staff Workplans',
                path: `/${admin ? 'admin' : 'supervisor'}?tab=supervision&${params}`,
                isCurrent: false,
            },
            ...breadCrumbs,
        ];
        breadcrumbs[breadcrumbs.length - 1].isCurrent = true;
        return breadcrumbs;
    };

    return (
        <Tile colspan={2}>
            <>
                <BreadCrumb breadCrumbs={getBreadCrumbs()} color={'blue.500'} />
                <Flex justifyContent={'space-between'} alignItems={'center'}>
                    <HStack>
                        <Text fontSize={'lg'} fontWeight={'semibold'} textColor={'black'}>
                            {`${userName}`}
                        </Text>
                    </HStack>
                </Flex>
            </>
        </Tile>
    );
};
