import { Row, Table, ValueCell } from '@/wpt-lib/pdf/common/Table';
import SubHeading from '@/wpt-lib/pdf/common/SubHeading';
import * as React from 'react';
import { mapWorkplanAttachmentTypeToString, WorkplanAttachment } from '@/wpt-lib/interfaces/Workplan';

const SupportingDocuments = ({ workplanAttachments }: { workplanAttachments: WorkplanAttachment[] }) => {
    return (
        <Table cols={3}>
            {workplanAttachments.length > 0 && (
                <>
                    <Row>
                        <SubHeading level={2} text="Supporting Documents" />
                    </Row>
                    {workplanAttachments.map((doc) => (
                        <Row key={doc.id} widths={[66, 34]}>
                            <ValueCell label="File Name" value={doc.fileName} />
                            <ValueCell
                                label="File Type"
                                value={mapWorkplanAttachmentTypeToString[doc.attachmentType]}
                            />
                        </Row>
                    ))}
                </>
            )}
        </Table>
    );
};

export default SupportingDocuments;
