import React, { ReactElement } from 'react';
import { Checkbox, IconButton, Tooltip } from '@chakra-ui/react';

interface RowActionButtonProps {
    isLoading?: boolean;
    isDisabled?: boolean;
    label: string;
    ariaLabel?: string;
    icon?: ReactElement;
    isCheckbox?: boolean;
    isChecked?: boolean;
    onCheckboxChange?: (isChecked: boolean) => void;
    event: () => void;
}

export const RowActionButton = (props: RowActionButtonProps) => {
    const { label, icon, isLoading, isDisabled, event, ariaLabel, isCheckbox, isChecked, onCheckboxChange } = props;

    return isCheckbox ? (
        <Checkbox
            aria-label={ariaLabel || label}
            isChecked={isChecked}
            isDisabled={isDisabled}
            onChange={(e) => onCheckboxChange && onCheckboxChange(e.target.checked)}
            borderColor="black"
            colorScheme="blue"
            display="flex"
            alignItems="center"
        >
            <span style={{ display: 'none' }}>{label}</span> {/* This is to remove errors on wave*/}
        </Checkbox>
    ) : (
        <Tooltip label={label}>
            <IconButton
                aria-label={ariaLabel || label}
                icon={icon}
                isLoading={isLoading}
                isDisabled={isDisabled}
                onClick={event}
                bg={'transparent'}
            />
        </Tooltip>
    );
};
