import { HealthAndSafetyTrainingFormResponse } from '@/wpt-lib/interfaces/HealthAndSafetyTraining';
import WorkplanItemAction, { Params, useParam, useWorkplanItemData } from '../WorkplanItemAction';
import React from 'react';
import { useParams } from 'react-router-dom';
import WorkplanItemHandler from '@/components/pages/workplanItemHandler/WorkplanItemHandler';

interface TrainingInterface {
    trainingUndertaken: {
        trainingName: string;
    }[];
}
const TrainingWorkplanItem = () => {
    const { workplanId, assignmentId, versionId, userDetails } = useParams<{
        workplanId: string;
        assignmentId: string;
        versionId: string;
        userDetails: string;
    }>();
    const breadCrumbData = {
        workplanId,
        assignmentId,
        versionId,
        userDetails,
    };
    const type = 'health-and-safety-training';
    const { parsedData: parsedTraining } = useWorkplanItemData<
        HealthAndSafetyTrainingFormResponse,
        HealthAndSafetyTrainingFormResponse
    >(type);
    const formatTraining = parsedTraining as unknown as TrainingInterface;
    const healthName = parsedTraining ? formatTraining.trainingUndertaken[0].trainingName : 'Training';
    return (
        <WorkplanItemAction
            {...breadCrumbData}
            renderForm={() => <WorkplanItemHandler readonly={true} formType="training" />}
            workplanItemType={type}
            typeLabel={'Training'}
            params={useParam() as Params}
            name={healthName}
        />
    );
};
export default TrainingWorkplanItem;
