import {
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    Button,
    VStack,
    ButtonProps,
} from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { SimpleAlert } from '@/components/SimpleAlert/SimpleAlert';

export interface DialogProps {
    colorScheme?: string;
    dialogHeader: string;
    dialogConfirmLabel?: string;
    dialogConfirmDisabled?: boolean;
    dialogBody?: string;
    isOpen: boolean;
    errorMsg?: string;
    isLoading: boolean;
    onClose: () => void;
    dialogAction?: () => void;
    children?: ReactNode;
    confirmBtnProps?: ButtonProps;
}

export const Dialog = ({
    colorScheme = 'blue',
    dialogHeader,
    dialogConfirmLabel = 'Confirm',
    dialogConfirmDisabled,
    dialogBody,
    isOpen,
    errorMsg,
    isLoading,
    onClose,
    dialogAction,
    confirmBtnProps,
    children,
}: DialogProps) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
            <ModalOverlay shadow={'md'}>
                <ModalContent
                    aria-label={`${dialogHeader} Modal Content`}
                    padding={'30px'}
                    bg="offWhite"
                    rounded="none"
                >
                    <ModalHeader as={'h2'} fontSize="lg" fontWeight="bold">
                        {dialogHeader}
                    </ModalHeader>

                    <ModalBody>
                        <VStack spacing={3} w={'full'} alignItems={'flex-start'}>
                            {dialogBody && <Text>{dialogBody}</Text>}
                            {children}
                            {errorMsg && <SimpleAlert variant={'left-accent'}>{errorMsg}</SimpleAlert>}
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button
                            colorScheme={colorScheme}
                            isLoading={isLoading}
                            isDisabled={dialogConfirmDisabled}
                            onClick={dialogAction}
                            ml={3}
                            {...confirmBtnProps}
                        >
                            {dialogConfirmLabel}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </ModalOverlay>
        </Modal>
    );
};
