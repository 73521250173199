import { BaseFormRequest } from './baseFormRequest';
import { BaseFormResponse } from './baseFormResponse';
import { StatusType } from '@/wpt-lib/interfaces/ProgressUpdate';

export const ActivityFocusAreaSchemaKey = 'academicFocusArea';

export enum ActivityFocusArea {
    TEACHING = 'TEACHING',
    RESEARCH = 'RESEARCH',
    ENGAGEMENT = 'ENGAGEMENT',
}

export interface ActivityFormRequest extends BaseFormRequest {
    completed: boolean;
}

export interface ActivityWorkplanItemAction {
    activityName: string;
}

export interface ActivityFormResponse extends BaseFormResponse {
    activityName: string;
    status?: keyof typeof StatusType;
    academicFocusArea: string;
}

export const mapFocusAreaToStaticString: Record<ActivityFocusArea, string> = {
    [ActivityFocusArea.TEACHING]: 'Teaching',
    [ActivityFocusArea.RESEARCH]: 'Research',
    [ActivityFocusArea.ENGAGEMENT]: 'Engagement',
};
