import * as React from 'react';
import { Row, ValueCell } from '@/wpt-lib/pdf/common/Table';
import { useLabel } from '@/wpt-lib/pdf/useData';

const DisclosureOfInterestConsideration = ({
    disclosureDeclaration,
}: Partial<{
    disclosureDeclaration: string;
}>) => (
    <>
        <Row>
            <ValueCell label="Declaration" value={useLabel('disclosureDeclaration', disclosureDeclaration ?? 'n/a')} />
        </Row>
    </>
);

export default DisclosureOfInterestConsideration;
