import { BaseFormRequest } from './baseFormRequest';
import { BaseFormResponse } from './baseFormResponse';

export enum LeaveType {
    annualLeave = 'annualLeave',
    pep = 'pep',
    parentalLeave = 'parentalLeave',
    longServiceLeave = 'longServiceLeave',
    outsideWork = 'outsideWork',
    secondmentsAndExchanges = 'secondmentsAndExchanges',
    personalLeave = 'personalLeave',
    extendedSickLeave = 'extendedSickLeave',
    carersLeave = 'carersLeave',
    absenceFromDuty = 'absenceFromDuty',
    leaveWithoutPay = 'leaveWithoutPay',
    communityLeave = 'communityLeave',
    domesticViolenceLeave = 'domesticViolenceLeave',
    other = 'other',
}

export interface LeaveFormResponse extends BaseFormResponse {
    startDate?: string;
    endDate?: string;
    absenceType?: string;
    leaveCode?: string;
    days?: number;
}

export type LeaveFormRequest = BaseFormRequest;

export interface BookedLeave {
    startDate: string;
    endDate: string;
    type: string;
    code: string;
    hours: number;
}

export type BookedLeaveCode =
    | 'ANN'
    | 'SICK'
    | 'LSLV'
    | 'PERS'
    | 'PURLV'
    | 'LSLCA'
    | 'SLWOP'
    | 'PARLR'
    | 'LOAD'
    | 'PER2S'
    | 'PARL';

export const mapBookedLeaveCodes: Record<BookedLeaveCode, string> = {
    ANN: 'Annual Leave',
    SICK: 'Sick Leave',
    LSLV: 'Long Service Leave',
    PERS: 'Personal Leave - UTS Only',
    PURLV: 'Purchased Leave',
    LSLCA: 'Long Service Leave - Casual',
    SLWOP: 'Sick Leave Without Pay',
    PARLR: 'Parental Leave - Return to Work',
    LOAD: 'Annual Leave Loading - 2SER Only',
    PER2S: 'Additional Personal Leave - 2SER Only',
    PARL: 'Parental Leave',
};
