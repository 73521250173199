import React from 'react';
import { and, ControlProps, isStringControl, optionIs, RankedTester, rankWith } from '@jsonforms/core';
import { ChakraFormInput } from '../chakra-controls/ChakraFormInput';
import { withNamedJsonFormsControlProps } from '@/forms-renderers/chakra-ui/utils/withNamedJsonFormsHOC';
import { ChakraStaffListingAutoCompletion } from '@/forms-renderers/chakra-ui/additional/ChakraStaffListingAutoCompletion';

export const ChakraStaffListingAutoCompleteControl = (props: ControlProps) => {
    return <ChakraFormInput {...props} input={ChakraStaffListingAutoCompletion} />;
};

export const chakraStaffListingAutoCompleteTester: RankedTester = rankWith(
    3,
    and(isStringControl, optionIs('autocomplete', 'staffListing')),
);

export default withNamedJsonFormsControlProps(ChakraStaffListingAutoCompleteControl);
