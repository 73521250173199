import React from 'react';
import './App.css';
import { ChakraProvider, VStack } from '@chakra-ui/react';
import customTheme from './theme';
import { TopNavigation } from './components/navigation/TopNavigation';
import Routes from './components/Router/Routes';
import routes from './routes/routes';
import { BrowserRouter } from 'react-router-dom';
import AuthProvider from './components/auth/AuthProvider';
import AxiosProvider from '@/components/AxiosContext';
import AssignmentProvider from '@/components/contexts/AssignmentContext/AssignmentProvider';
import FormsProvider from '@/components/contexts/FormsContext/FormsProvider';
import { LabelsProvider } from '@/components/contexts/LabelsContext/LabelsProvider';
import { SkipNavLink } from '@chakra-ui/skip-nav';
import config from '@/config';
import Footer from './components/pages/Footer/Footer';
import ScrollToTop from './components/navigation/ScrollToTop';
import UserSupervisionFiltersProvider from '@/components/contexts/UserSupervisionFiltersContext/UserSupervisionFiltersProvider';
import { NavigationBar } from '@/components/navigation/NavigationBar';

function App() {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <AuthProvider>
                <AxiosProvider>
                    <AssignmentProvider>
                        <LabelsProvider>
                            <FormsProvider>
                                <UserSupervisionFiltersProvider>
                                    <ChakraProvider theme={customTheme}>
                                        <header role="banner">
                                            <SkipNavLink id={config.constants.mainContent}>Skip to content</SkipNavLink>
                                            <TopNavigation />
                                            <NavigationBar />
                                        </header>
                                        <VStack
                                            maxW="100vw"
                                            minH={'calc(100vh - 60px - 40px)'}
                                            alignItems="center"
                                            h="full"
                                            bgColor={'gray.200'}
                                            as="main"
                                            role="main"
                                            id={config.constants.mainContent}
                                            tabIndex={-1}
                                            outline={0}
                                            spacing={0}
                                        >
                                            <Routes routes={routes} />
                                        </VStack>
                                        <Footer />
                                    </ChakraProvider>
                                </UserSupervisionFiltersProvider>
                            </FormsProvider>
                        </LabelsProvider>
                    </AssignmentProvider>
                </AxiosProvider>
            </AuthProvider>
        </BrowserRouter>
    );
}
export default App;
