import { Radio, FormControl, SimpleGrid, GridItem, FormLabel, RadioGroup, Stack } from '@chakra-ui/react';

import React from 'react';
import { SingleRatingProps } from '@/components/WorkplanEOYRating/SingleEOYRating';

export const SingleProbationReview = ({ readOnly, title, rating, handleChange, value }: SingleRatingProps<boolean>) => {
    const id = `${rating}-input`;

    return (
        <FormControl as="fieldset" p={4} width="100%">
            <SimpleGrid templateColumns={`1fr minmax(360px, max-content) 1fr`} gap={5} alignItems="center">
                <GridItem justifyContent="center">
                    <FormLabel as="legend" textAlign="right" m={0} htmlFor={id}>
                        {title}
                    </FormLabel>
                </GridItem>
                <GridItem textAlign="center">
                    <RadioGroup
                        id={id}
                        onChange={(v) => handleChange(rating, v === 'Yes')}
                        value={value ? 'Yes' : 'No'}
                    >
                        <Stack direction="row" spacing={6} justifyContent={'flex-start'} alignItems={'center'}>
                            {Array(2)
                                .fill(0)
                                .map((_, i) => {
                                    const option = i === 0 ? 'Yes' : 'No';
                                    return (
                                        <Radio
                                            isReadOnly={readOnly}
                                            key={option}
                                            value={option}
                                            size={'lg'}
                                            cursor={'pointer'}
                                        >
                                            {option}
                                        </Radio>
                                    );
                                })}
                        </Stack>
                    </RadioGroup>
                </GridItem>
            </SimpleGrid>
        </FormControl>
    );
};
