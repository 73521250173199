import React, { ComponentType, useState, useEffect, useRef } from 'react';
import {
    and,
    Categorization,
    getAjv,
    isCategory,
    isEnabled,
    isVisible,
    LayoutProps,
    RankedTester,
    rankWith,
    UISchemaElement,
    uiTypeIs,
    toDataPath,
    Category,
    isControl,
    isLayout,
} from '@jsonforms/core';
import { ChakraLayoutRenderer, ChakraLayoutRendererProps } from './ChakraLayout';
import { useJsonForms } from '@jsonforms/react';
import { Box, Button, Flex, Spacer, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import configuredAreEqual from '../utils/configuredAreEqual';
import { withNamedJsonFormsLayoutProps } from '@/forms-renderers/chakra-ui/utils/withNamedJsonFormsHOC';
import { namedMemo } from '@/utils/namedMemo';
import { withDisplayName } from '@/utils/withDisplayName';
import { WorkplanItemFormConfig } from '@/components/WorkplanItemForm/WorkplanItemForm';
import TextHighlighting from '@/components/TextHighlighting/TextHighlighting';

export const ChakraCategorisationLayoutRenderer = namedMemo(
    'ChakraCategorisationLayoutRenderer',
    ({
        uischema,
        schema,
        path,
        enabled,
        visible,
        renderers,
        cells,
        config,
        categories,
    }: LayoutProps & {
        categories: (Categorization['elements'][0] & {
            visible: boolean;
            enabled: boolean;
        })[];
    }) => {
        const [activeCategory, setActiveCategory] = useState(0);
        const appliedUiSchemaOptions = merge({}, config, uischema.options);
        const newConfig = config as WorkplanItemFormConfig;
        const [categoryElementMap, setCategoryElementMap] = useState<Record<string, Set<string>>>({});
        const childProps: Omit<ChakraLayoutRendererProps, 'elements'> = {
            schema,
            path,
            enabled,
            width: appliedUiSchemaOptions.w,
            columns: appliedUiSchemaOptions.columns,
            rows: appliedUiSchemaOptions.rows,
            autoRows: appliedUiSchemaOptions.autoRows,
            visible,
        };
        const visibleCategories = categories.filter((c) => c.visible);

        const categoryHasChangesElement = (
            category: (Categorization | Category) & {
                visible: boolean;
                enabled: boolean;
            },
        ): boolean => {
            if (!categoryElementMap[category.label]) return false;
            for (const dataPath of Array.from(categoryElementMap[category.label].values())) {
                if (newConfig.formChanges[dataPath]) return true;
            }
            return false;
        };
        const constructElementMaps = (
            element: UISchemaElement,
            categoriesElementsMap: Record<string, Set<string>>,
            parent: string,
        ) => {
            if (isControl(element)) {
                categoriesElementsMap[parent].add(toDataPath(element.scope));
            }
            if (isLayout(element)) {
                for (let i = 0; i < element.elements.length; i++) {
                    const child = element.elements[i];
                    constructElementMaps(child, categoriesElementsMap, parent);
                }
            }
        };
        useEffect(() => {
            const categoriesElementsMap: Record<string, Set<string>> = {};
            categories.forEach((category) => {
                categoriesElementsMap[category.label] = new Set<string>();
                constructElementMaps(category, categoriesElementsMap, category.label);
            });
            setCategoryElementMap(categoriesElementsMap);
        }, []);

        const tabRef = useRef<HTMLDivElement>(null);

        const scrollTab = () => {
            const node = tabRef.current;
            if (node !== null) {
                if (node.getBoundingClientRect().top < 0) {
                    setTimeout(() => {
                        node.scrollIntoView({ behavior: 'smooth' });
                    }, 0);
                }
            }
        };

        return (
            <Box>
                <Tabs isLazy onChange={setActiveCategory} index={activeCategory}>
                    <Box ref={tabRef} overflowX="auto" display="flex">
                        <TabList tabIndex={-1} flexGrow={1}>
                            {visibleCategories.map((category, i) => {
                                return (
                                    <Tab key={i} isDisabled={!category.enabled}>
                                        <TextHighlighting
                                            version={1}
                                            query={categoryHasChangesElement(category) ? category.label : ''}
                                        >
                                            {category.label}
                                        </TextHighlighting>
                                    </Tab>
                                );
                            })}
                        </TabList>
                    </Box>
                    <TabPanels>
                        {visibleCategories.map((category, i) => (
                            <TabPanel key={i} tabIndex={-1}>
                                <ChakraLayoutRenderer
                                    {...childProps}
                                    columns={category.options?.columns}
                                    rows={category.options?.rows}
                                    autoRows={category.options?.autoRows}
                                    renderers={renderers}
                                    cells={cells}
                                    elements={category.elements}
                                />
                            </TabPanel>
                        ))}
                    </TabPanels>
                </Tabs>
                {appliedUiSchemaOptions.showNavButtons && (
                    <Flex>
                        <Button
                            colorScheme="gray"
                            hidden={activeCategory <= 0}
                            isDisabled={activeCategory <= 0 || !visibleCategories[activeCategory - 1].enabled}
                            onClick={() => {
                                scrollTab();
                                setActiveCategory(activeCategory - 1);
                            }}
                        >
                            Previous
                        </Button>
                        <Spacer />
                        <Button
                            colorScheme="blue"
                            hidden={activeCategory >= visibleCategories.length - 1}
                            isDisabled={
                                activeCategory >= visibleCategories.length - 1 ||
                                !visibleCategories[activeCategory + 1].enabled
                            }
                            onClick={() => {
                                scrollTab();
                                setActiveCategory(activeCategory + 1);
                            }}
                        >
                            Next
                        </Button>
                    </Flex>
                )}
            </Box>
        );
    },
    configuredAreEqual({ ignore: ['data'] }),
);

const elementsAreCategories = (uischema: UISchemaElement) => {
    const categorisation = uischema as Categorization;
    return !isEmpty(categorisation.elements) && categorisation.elements.every(isCategory);
};

export const chakraCategorisationLayoutTester: RankedTester = rankWith(
    1,
    and(uiTypeIs('Categorisation'), elementsAreCategories),
);

interface WithCategoriesProps {
    categories: (Categorization['elements'][0] & {
        visible: boolean;
        enabled: boolean;
    })[];
}

const withCategories = <P extends LayoutProps>(Component: ComponentType<P & WithCategoriesProps>) =>
    withDisplayName((props: P) => {
        const ctx = useJsonForms();
        const ajv = getAjv({ jsonforms: { ...ctx } });
        const categorisation = props.uischema as Categorization;

        const categories = categorisation.elements.map((category) => ({
            ...category,
            enabled: isEnabled(category, props.data, '', ajv),
            visible: isVisible(category, props.data, '', ajv),
        }));

        return <Component {...props} categories={categories} />;
    }, 'withCategories');

export default withNamedJsonFormsLayoutProps(
    withCategories(ChakraCategorisationLayoutRenderer as ComponentType<LayoutProps & WithCategoriesProps>),
);
