import { Link, Text, TextProps, TypographyProps } from '@chakra-ui/react';
import { renderHeadingWithURL } from '@/forms-renderers/chakra-ui/additional/Labels/renderHeadingWithURL';
import React from 'react';
import { namedMemo } from '@/utils/namedMemo';

interface LabelWithURLInterface extends TextProps, TypographyProps {
    text?: string;
}

const ChakraLabelWithURL = namedMemo('ChakraLabelWithURL', (props: LabelWithURLInterface) => {
    const content = renderHeadingWithURL(props.text || '')?.map((data, i) => {
        return data.type === 'text' ? (
            <span key={i}>{data.value}</span>
        ) : (
            <Link key={i} href={data.value || ''} color={'blue.500'} isExternal>
                {data.label}
            </Link>
        );
    });

    return (
        <Text {...props} whiteSpace="pre-line">
            {content}
        </Text>
    );
});

export default ChakraLabelWithURL;
