import { WorkplanItem } from '@/wpt-lib/interfaces/WorkplanItem';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseFormData(formData: WorkplanItem['formData']): any {
    if (typeof formData === 'string') {
        return JSON.parse(formData);
    } else return formData;
}

export function serialiseFormData(formData: WorkplanItem['formData']): string {
    if (typeof formData !== 'string') {
        return JSON.stringify(formData);
    } else return formData;
}

export function getFormTypeKeys(type: string | object | undefined): string[] {
    if (!type) return [];
    else if (typeof type === 'string') return [type];
    else if (typeof type === 'object') {
        return Object.entries(type)
            .filter((e) => !!e[1])
            .map((e) => e[0]);
    } else {
        console.error(`Unexpected form type ${typeof type}.`);
        return [];
    }
}
