import InfoTooltip from '@/components/Tooltip/InfoTooltip';
import { Heading } from '@chakra-ui/react';
import React from 'react';
import { dedent } from '@/utils/stringUtils';

export const MySummaryHeading = () => {
    const tooltipText = dedent(`The following information relates to your current assignment as indicated in Neo:
    
        My Supervisor: reflects your current manager for this assignment.
        
        Leave: Booked leave is annual leave that has been approved in Ascender Pay. Planned Leave refers to any leave entered within this tool and is considered indicative for the purposes of a leave planning conversation. You should discuss your leave plan with your supervisor for the workplan period and apply for this leave in Ascender Pay following approval.
        
        Workload Allocation: To request an allocation update, you will need to follow internal faculty process and you must have supervisor approval.`);

    return (
        <Heading as="h2" size={'md'} color={'black'}>
            {'My Summary'}
            <InfoTooltip tooltipLabel={'My Summary Tooltip'} tooltipInfoText={tooltipText} />
        </Heading>
    );
};
