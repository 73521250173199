import React from 'react';
import { and, ControlProps, isStringControl, optionIs, RankedTester, rankWith } from '@jsonforms/core';
import { ChakraFormInput } from '../chakra-controls/ChakraFormInput';
import { withNamedJsonFormsControlProps } from '@/forms-renderers/chakra-ui/utils/withNamedJsonFormsHOC';
import { ChakraOpenCourseAutoCompletion } from '../additional/ChakraOpenCourseAutoCompletion';

export const ChakraOpenCourseAutoCompletionControl = (props: ControlProps) => {
    return <ChakraFormInput {...props} input={ChakraOpenCourseAutoCompletion} />;
};

export const chakraOpenCourseAutoCompleteTester: RankedTester = rankWith(
    3,
    and(isStringControl, optionIs('autocomplete', 'openCourse')),
);

export default withNamedJsonFormsControlProps(ChakraOpenCourseAutoCompletionControl);
