import { toMaxFixed } from '@/forms-renderers/chakra-ui/additional/DataReplacement/MathEvaluator';
import React, { useCallback } from 'react';
import { CellProps, WithClassname } from '@jsonforms/core';
import merge from 'lodash/merge';
import {
    Center,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputProps,
    NumberInputStepper,
} from '@chakra-ui/react';
import { namedMemo } from '@/utils/namedMemo';

const isNumberString = (value: string) => /^\d+(\.\d*[1-9])?$/.test(value);

interface ChakraInputNumberProps extends CellProps, WithClassname, Pick<NumberInputProps, 'step'> {}

const getMultiplierNumber = (inMultiplier: unknown): number => {
    if (typeof inMultiplier === 'number') return inMultiplier;
    if (typeof inMultiplier === 'string') {
        const asNumber = parseFloat(inMultiplier);
        if (!Number.isNaN(asNumber)) {
            return asNumber;
        }
    }
    return 1;
};

interface ChakraInputNumberOptions {
    readonly?: unknown;
    focus?: unknown;
    multiplier?: unknown;
    prefix?: unknown;
}

export const ChakraInputNumber = namedMemo('ChakraInputNumber', (props: ChakraInputNumberProps) => {
    const { data, config, className, id, enabled, uischema, isValid, path, handleChange, schema, step } = props;
    const { minimum, maximum } = schema;
    const appliedUiSchemaOptions: ChakraInputNumberOptions = merge({}, config, uischema.options);

    const multiplier = getMultiplierNumber(appliedUiSchemaOptions?.multiplier);

    const onChange = useCallback(
        (valueAsString: string, valueAsNumber: number) => {
            // Remove non-digit numeric symbols (e,+,-)
            const stringVal = valueAsString.replaceAll(/[^\d.]/g, '');

            let setVal: string | number | undefined = stringVal;
            // Use string value if not a proper number, to allow for typing '.'
            if (isNumberString(stringVal)) {
                setVal = toMaxFixed(valueAsNumber / multiplier, 2);
            } else if (setVal === '') {
                setVal = undefined;
            }

            handleChange(path, setVal);
        },
        [handleChange, path],
    );

    let value: string | number = data;
    if (isNumberString(data)) {
        value = toMaxFixed(data * multiplier, 2);
    }

    return (
        <NumberInput
            isReadOnly={!!appliedUiSchemaOptions.readonly || !enabled}
            id={id}
            value={value ?? ''}
            className={className}
            isInvalid={!isValid}
            onChange={onChange}
            min={minimum}
            max={maximum}
            step={step}
            style={{ display: 'flex' }}
            clampValueOnBlur={false}
        >
            {!!appliedUiSchemaOptions.prefix && typeof appliedUiSchemaOptions.prefix === 'string' && (
                <Center
                    width="var(--chakra-sizes-10)"
                    height="100%"
                    position="absolute"
                    left={0}
                    background={'gray.200'}
                    borderLeftRadius="md"
                    fontWeight="bold"
                >
                    {appliedUiSchemaOptions.prefix}
                </Center>
            )}
            <NumberInputField
                paddingInlineStart={appliedUiSchemaOptions.prefix ? 'var(--chakra-space-14)' : undefined}
            />
            <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
            </NumberInputStepper>
        </NumberInput>
    );
});
