import WorkplanItemAction, { Params, useParam, useWorkplanItemData } from '../WorkplanItemAction';
import React from 'react';
import { useParams } from 'react-router-dom';
import { ConsiderationFormRequest, ConsiderationFormResponse } from '@/wpt-lib/interfaces/Consideration';
import WorkplanItemHandler from '@/components/pages/workplanItemHandler/WorkplanItemHandler';

const ConsiderationWorkplanItem = () => {
    const { workplanId, assignmentId, versionId, userDetails } = useParams<{
        workplanId: string;
        assignmentId: string;
        versionId: string;
        userDetails: string;
    }>();
    const breadCrumbData = {
        workplanId,
        assignmentId,
        versionId,
        userDetails,
    };
    const type = 'consideration';

    const { workplanItem } = useWorkplanItemData<ConsiderationFormResponse, ConsiderationFormRequest>(type);

    return (
        <WorkplanItemAction
            {...breadCrumbData}
            renderForm={() => <WorkplanItemHandler readonly formType="consideration" />}
            workplanItemType={type}
            typeLabel={'Consideration'}
            params={useParam() as Params}
            name={workplanItem?.considerationTitle}
        />
    );
};
export default ConsiderationWorkplanItem;
