import React from 'react';
import { and, ControlProps, isStringControl, optionIs, RankedTester, rankWith } from '@jsonforms/core';
import { ChakraFormInput } from '../chakra-controls/ChakraFormInput';
import { withNamedJsonFormsControlProps } from '@/forms-renderers/chakra-ui/utils/withNamedJsonFormsHOC';
import { ChakraResearchMasterAutoCompletion } from '@/forms-renderers/chakra-ui/additional/ChakraResearchMasterAutoCompletion';

export const ChakraResearchMasterAutoCompleteControl = (props: ControlProps) => (
    <ChakraFormInput {...props} input={ChakraResearchMasterAutoCompletion} />
);

export const chakraResearchMasterAutoCompletedTester: RankedTester = rankWith(
    3,
    and(isStringControl, optionIs('autocomplete', 'ResearchMaster')),
);

export default withNamedJsonFormsControlProps(ChakraResearchMasterAutoCompleteControl);
