import { BaseFormRequest } from './baseFormRequest';
import { BaseFormResponse } from './baseFormResponse';

export enum ConsiderationType {
    PEP = 'PEP',
    OPPORTUNITY = 'SUBJECT_TO_OPPORTUNITY',
    OUTSIDE_WORK = 'OUTSIDE_WORK',
    CONFLICTS_OF_INTEREST_DECLARATION = 'CONFLICTS_OF_INTEREST_DECLARATION',
    OTHER = 'OTHER',
}

export enum DisclosureDeclaration {
    NO_INTEREST = 'I understand and have no interest to declare',
    DECLARED_INTEREST = 'I understand and have declared my interest by completing the Declaration of Interest form',
}

export interface ConsiderationFormResponse extends BaseFormResponse {
    considerationTitle?: string;
    considerationType?: string;
}

export type ConsiderationFormRequest = BaseFormRequest;
