import * as React from 'react';
import { StyleSheet, View } from '@react-pdf/renderer';
import Heading from '@/wpt-lib/pdf/common/Heading';

const Allocation = ({ image }: { image: JSX.Element }) => {
    return (
        <View style={styles.center}>
            <View style={[styles.heading, styles.center]}>
                <Heading text="Workload Allocation" level={3} center />
            </View>
            <View style={styles.center}>{image}</View>
        </View>
    );
};

const styles = StyleSheet.create({
    heading: {
        width: '100%',
    },
    center: {
        justifyContent: 'space-between',
        alignItems: 'center',
        alignSelf: 'center',
    },
    image: {
        margin: 10,
        width: 342,
        height: 216,
    },
});

export default Allocation;
