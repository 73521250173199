import { Assignment } from '@/wpt-lib/interfaces/Assignment';
import { Divider, VStack } from '@chakra-ui/react';
import React from 'react';
import AdminFieldValueSet from '../AdminFieldValueSet/AdminFieldValueSet';
import { toMaxFixed } from '@/forms-renderers/chakra-ui/additional/DataReplacement/MathEvaluator';
import { Tile } from '@/components/Tile';
import { formatName } from '@/wpt-lib/utils/formatName';

export const UserProvisionDetailsProfile = ({ assignment }: { assignment: Assignment }) => {
    const getSupervisorName = () => {
        if (assignment.supervisors?.length === 0) {
            return '-';
        } else {
            let name = '';
            assignment.supervisors?.forEach((sup, i) => {
                const fullName = `${formatName(sup.user)}`;
                const comma = i + 1 === assignment.supervisors?.length ? '' : ', ';
                name += `${fullName}${comma} `;
            });
            return name;
        }
    };

    const getWorkloadBalance = () => {
        const calculateWorkload = (workload: number) => {
            return toMaxFixed(workload * 100, 1);
        };

        const parts: string[] = [];

        if (
            assignment.teachingWorkloadPortion +
                assignment.researchWorkloadPortion +
                assignment.serviceWorkloadPortion >
            0
        ) {
            parts.push(`Teaching ${calculateWorkload(assignment.teachingWorkloadPortion)}%`);
            parts.push(`Research ${calculateWorkload(assignment.researchWorkloadPortion)}%`);
            parts.push(`Service ${calculateWorkload(assignment.serviceWorkloadPortion)}%`);
        }

        if (assignment.seniorStaffWorkloadPortion > 0)
            parts.push(`Senior Staff ${calculateWorkload(assignment.seniorStaffWorkloadPortion)}%`);

        if (assignment.professionalStaffWorkloadPortion > 0)
            parts.push(`Professional ${calculateWorkload(assignment.professionalStaffWorkloadPortion)}%`);

        return parts.join(', ');
    };

    return (
        <Tile colspan={2} heading={`Profile`} collapsable defaultOpen>
            <VStack spacing={2} alignItems="flex-start" ml={4}>
                <AdminFieldValueSet
                    data={{
                        'ID:': assignment.user?.staffId,
                        'Assignment Role:': assignment.assignmentNumber,
                        'Faculty/Division:': assignment.organisation.faculty.name,
                        'Org Unit:': assignment.organisation.name,
                    }}
                />
                <Divider style={{ margin: '2rem 0 2rem' }} />
                <AdminFieldValueSet
                    data={{
                        'Supervisor:': getSupervisorName(),
                        'Workload balance:': getWorkloadBalance(),
                    }}
                />
            </VStack>
        </Tile>
    );
};
