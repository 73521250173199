import * as React from 'react';
import { PropsWithChildren } from 'react';
import { StyleSheet, View } from '@react-pdf/renderer';
import Text from '@/wpt-lib/pdf/common/Text';
import { isNullish } from '@/wpt-lib/utils/isNullish';
import { formatDate } from '@/wpt-lib/utils/dateUtils';
import { getCellHeight } from '@/wpt-lib/pdf/common/calculations';

interface WithWidthsProp {
    widths?: number[];
}

export const Table: React.FC<PropsWithChildren<{ cols: number }>> = ({ cols, children }) => {
    const widths = new Array(cols).fill(100 / (cols || 1));

    return (
        <View style={styles.table}>
            {React.Children.map(children, (c) => {
                if (React.isValidElement<WithWidthsProp>(c))
                    return React.cloneElement(c, {
                        widths: c.props.widths ?? widths,
                    });
            })}
        </View>
    );
};

export const Row: React.FC<PropsWithChildren<WithWidthsProp>> = ({ widths = [], children }) => {
    if (Array.isArray(children)) children = children.filter((c) => !!c);

    const num = React.Children.count(children);

    if (widths.length < num) widths = new Array(num).fill(100 / num);
    else if (widths.length > num) {
        widths = widths?.slice(0, num - 1);
        widths.push(100 - widths?.reduce((a, s) => a + s, 0));
    }

    const heights: number[] = [];

    React.Children.forEach(children, (child, i) => {
        if (React.isValidElement(child) && child.props?.label && child.props?.value) {
            heights.push(
                getCellHeight(
                    formatValue(child.props.value, child.props.format),
                    child.props.label,
                    widths[i] || 100,
                    child.props.fontSize,
                    child.props.single,
                ),
            );
        } else {
            heights.push(-1);
        }
    });

    const maxHeight = Math.max(...heights);
    return (
        <View style={[styles.row, {}]} wrap={maxHeight > 440}>
            {React.Children.map(children, (c, i) => (
                <View
                    key={i}
                    style={[
                        styles.border,
                        {
                            borderLeftWidth: i === 0 ? 1 : 0,
                            borderRightWidth: 1,
                            width: `${widths[i]}%`,
                        },
                    ]}
                >
                    {React.isValidElement(c)
                        ? React.cloneElement(c, {
                              height: heights[i] > 0 ? Math.max(heights[i], 31) : undefined,
                          } as Partial<typeof c>)
                        : undefined}
                </View>
            ))}
        </View>
    );
};

interface CellProps {
    bg?: string;
    single?: boolean;
    height?: number;
}

export const Cell: React.FC<PropsWithChildren<CellProps>> = ({ bg, single, children, height }) => (
    <View
        style={[
            styles.cell,
            {
                backgroundColor: bg,
                flexDirection: single ? 'row' : 'column',
                justifyContent: 'flex-start',
                alignItems: single ? 'center' : 'flex-start',
                height: height ? height : 'auto',
            },
        ]}
    >
        {children}
    </View>
);

export const TitleCell = ({ title, variant = 'normal' }: { title: string; variant?: 'normal' | 'summary' }) => (
    <Cell bg="#0066ff">
        <Text style={variant === 'summary' ? styles.summaryTitle : styles.normalTitle}>{title}</Text>
    </Cell>
);

export const HeadingCell = ({
    heading,
    textAlign = 'center',
    top,
    bg,
}: {
    heading: string;
    textAlign?: 'left' | 'center';
    top?: number;
    bg?: string;
}) => (
    <Cell bg={bg}>
        <Text style={[styles.heading, { textAlign, marginTop: top }]}>{heading}</Text>
    </Cell>
);

export const LabelCell = ({ label, highlight }: { label: string; highlight?: boolean }) => (
    <Cell bg={highlight ? 'yellow' : undefined}>
        <Text style={styles.label}>{label}</Text>
    </Cell>
);

type ValueCellProps = {
    label: string;
    value: string | number | undefined | null;
    highlight?: boolean;
    fontSize?: number;
    textAlign?: 'left' | 'center';
    format?: 'date';
    color?: string;
};

function formatValue(value: ValueCellProps['value'], format: ValueCellProps['format']): string {
    if (isNullish(value)) return 'n/a';

    value = '' + value;

    switch (format) {
        case 'date':
            return formatDate(value);

        default:
            return value;
    }
}

export const ValueCell = ({
    label,
    value,
    textAlign = 'left',
    highlight,
    bg = undefined,
    fontSize,
    format,
    color,
    ...cellProps
}: ValueCellProps & CellProps) => {
    return (
        <Cell {...cellProps} bg={highlight ? 'yellow' : bg}>
            <Text
                style={[
                    styles.label,
                    {
                        fontSize,
                        color: color ? color : 'black',
                        width: !cellProps.single ? '100%' : 'auto',
                    },
                ]}
            >
                {label}
                {label.length > 0 ? ':' : ''}
            </Text>

            <Text
                style={[
                    styles.value,
                    {
                        color: color ? color : 'black',
                        textAlign,
                        fontSize,
                        marginLeft: cellProps.single ? 5 : 0,
                        marginBottom: cellProps.single ? 5 : 0,
                        width: !cellProps.single ? '100%' : 'auto',
                    },
                ]}
            >
                {formatValue(value, format)}
            </Text>
        </Cell>
    );
};

export const ListCell = ({
    label,
    values,
    highlight,
    ...cellProps
}: {
    label: string;
    values: string[];
    highlight?: boolean;
} & CellProps) => (
    <Cell {...cellProps} bg={highlight ? 'yellow' : undefined}>
        <Text style={styles.label}>{label}:</Text>
        <View style={styles.list}>
            {values.map((v, i) => (
                <Text key={i} style={styles.value}>
                    &#8226; {v}
                </Text>
            ))}
        </View>
    </Cell>
);

export const AlertCell = ({ label }: { label: string }) => (
    <Cell>
        <Text style={styles.alert}>{label}</Text>
    </Cell>
);

const styles = StyleSheet.create({
    table: {
        width: '100%',
        flexDirection: 'column',
        marginTop: 10,
    },
    border: {
        borderStyle: 'solid',
        borderColor: '#000000',
    },
    row: {
        width: '100%',
        flexDirection: 'row',
        borderTopWidth: 1,
        borderBottomWidth: 1,
        marginTop: -1,
    },
    cell: {
        paddingHorizontal: 5,
        paddingVertical: 5,
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-evenly',
        textAlign: 'left',
    },
    normalTitle: {
        width: '100%',
        color: '#fff',
        fontWeight: 'bold',
        fontSize: 13,
        paddingVertical: 5,
    },
    summaryTitle: {
        textTransform: 'uppercase',
        textAlign: 'center',
        width: '100%',
        color: '#fff',
        fontWeight: 'bold',
        marginTop: 3,
    },
    subCategory: {
        textAlign: 'left',
        width: '100%',
        fontWeight: 'bold',
    },
    heading: {
        fontWeight: 'bold',
        width: '100%',
    },
    label: {
        fontWeight: 'bold',
        fontSize: 10,
        marginRight: 5,
        marginBottom: 5,
    },
    value: {},
    list: {
        marginLeft: 10,
    },
    listItem: {
        marginVertical: 10,
    },
    alert: {
        fontSize: 10,
        color: '#FF5038',
        fontStyle: 'italic',
        width: '100%',
        textAlign: 'center',
    },
});
