import { Assignment } from '@/wpt-lib/interfaces/Assignment';
import React from 'react';
import SubmitForReviewButtonTile from './SubmitForReviewButtonTile';
import useWorkplanVersion from '@/components/contexts/VersionContext/useWorkplanVersion';
import useNavigateOptions from '@/components/contexts/NaviateContext/useNavigateOptions';
import { Workplan, WorkplanState } from '@/wpt-lib/interfaces/Workplan';
import DeclarationsTile from '@/components/pages/dashboard/tiles/DeclarationsTile/DeclarationsTile';
import WorkplanVersionStateDisplay from '@/components/contexts/VersionContext/WorkplanVersionStateDisplay';
import { isWorkplanInState, isWorkplanReadonly } from '@/utils/isWorkplanReadonly';
import SupervisorActionButtonTile from '@/components/pages/dashboard/tiles/ButtonTile/SupervisorActionButtonTile';

interface ButtonActionTileProps {
    assignment: Assignment;
}
function showSubmitButton(workplan: Workplan, current: boolean, supervisor: boolean) {
    /* Earlier versions cannot be submitted. */
    if (!current) return false;

    /* Supervisor cannot submit on behalf of academic, admin can. */
    if (supervisor) return false;

    /* Submit button visible if workplan in academic edit state. */
    return isWorkplanInState(
        workplan,
        WorkplanState.DRAFT,
        WorkplanState.IN_PROGRESS,
        WorkplanState.INFORMATION_REQUESTED,
    );
}

function showApproveButton(workplan: Workplan, current: boolean, supervisor: boolean, admin: boolean) {
    /* Earlier versions have already been actioned. */
    if (!current) return false;

    /* Supervisor can approve workplans. */
    if (
        (supervisor || admin) &&
        isWorkplanInState(workplan, WorkplanState.SUBMITTED, WorkplanState.REVIEW, WorkplanState.MODERATION)
    )
        return true;

    /* Admin can also unendorse workplans. */
    return admin && isWorkplanInState(workplan, WorkplanState.ENDORSED);
}

const ButtonActionTile = ({ assignment }: ButtonActionTileProps) => {
    const { supervisor, admin } = useNavigateOptions();
    const { current } = useWorkplanVersion();

    return (
        <WorkplanVersionStateDisplay
            render={([workplan, workplanVersion]) => {
                const props = {
                    workplan,
                    workplanVersion,
                    currentAssignment: assignment,
                };

                return (
                    <>
                        <DeclarationsTile
                            currentAssignment={assignment}
                            isReadonly={!current || supervisor || admin || isWorkplanReadonly(workplan)} // because supervisor cannot edit the declarations
                        />
                        {showSubmitButton(workplan, current, supervisor) && <SubmitForReviewButtonTile {...props} />}
                        {showApproveButton(workplan, current, supervisor, admin) && (
                            <SupervisorActionButtonTile {...props} />
                        )}
                    </>
                );
            }}
        />
    );
};

export default ButtonActionTile;
