import { BreadCrumbItem } from '@/components/BreadCrumb';
import useNavigateOptions from '@/components/contexts/NaviateContext/useNavigateOptions';
import { useParams } from 'react-router-dom';
import { useUserSupervisionFilters } from '@/components/contexts/UserSupervisionFiltersContext/useUserSupervisionFilters';

function useUserSupervisionBreadcrumbs(parent = 0): BreadCrumbItem[] {
    const { makePathCustom } = useNavigateOptions();
    const spec = useParams()['users'];

    const search = useUserSupervisionFilters().params;

    if (!spec) return [];

    const items = spec
        .split(';')
        .filter(Boolean)
        .map((s) => s.split('-'));

    return items
        .reduce(
            (acc, item) => [
                ...acc,
                {
                    assignmentId: item[item.length - 1],
                    label: item.slice(0, item.length - 1).join(' '),
                    users: (acc.length > 0 ? acc[acc.length - 1].users : '') + item.join('-') + ';',
                },
            ],
            [] as { assignmentId: string; label: string; users: string }[],
        )
        .map((params) => ({
            label: params.label,
            path: makePathCustom({
                path: [],
                params,
                parent,
                search,
            }),
            isCurrent: false,
        }));
}

export default useUserSupervisionBreadcrumbs;
