import { useWorkplanLabels } from '@/components/contexts/LabelsContext/useLabels';
import { FormActionTable } from '@/components/pages/dashboard/tiles/bottomTile/bottomTileTables/FormActionTable';
import { FormTableProps } from '@/components/pages/dashboard/tiles/bottomTile/bottomTileTables/FormTableInterfaces';
import { Column } from '@/components/pages/dashboard/tiles/bottomTile/bottomTileTables/ListTable';
import { ActivityFormResponse } from '@/wpt-lib/interfaces/Activity';
import { progressUpdateStatus, StatusType } from '@/wpt-lib/interfaces/ProgressUpdate';
import React from 'react';
import { label } from '@/wpt-lib/utils/label';
import { createdColumn } from '@/components/pages/dashboard/tiles/bottomTile/bottomTileTables/PersonalGoalTable';

type ActivityTableProps = FormTableProps<ActivityFormResponse>;

export const getActivityName = (row: ActivityFormResponse) => row.activityName;

export const ActivityTable = (props: ActivityTableProps) => {
    const focusAreas = useWorkplanLabels(props.workplan, 'ACTIVITY', 'academicFocusArea');

    const columns: Column<ActivityFormResponse>[] = [
        {
            name: 'Name',
            field: 'activityName',
            highlight: (item: ActivityFormResponse) => item.changed,
            getData: (item: ActivityFormResponse) => item.activityName,
        },
        {
            name: 'Focus Area',
            field: 'academicFocusArea',
            getData: (item: ActivityFormResponse) => label(focusAreas, item.academicFocusArea),
        },
        {
            name: 'Status',
            field: 'status',
            getData: (item: ActivityFormResponse) => progressUpdateStatus[item?.status ?? StatusType.NOT_YET_STARTED],
        },
        {
            name: 'Created',
            field: 'createdAt',
            getData: createdColumn,
        },
    ];

    return (
        <FormActionTable
            getActions={props.getActions}
            getColumns={props.getColumns}
            endpoint={'activity'}
            urlLabel={'activity'}
            typeOfForm={'Activity'}
            tableCaptions={'Activities'}
            columns={columns}
            data={props.data}
            fetchFormList={props.fetchFormList}
            onChange={props.onChange}
            workplan={props.workplan}
            getName={getActivityName}
            page={props.page}
            pages={props.pages}
            orderByOrientation={props.orderByOrientation}
            orderByColumn={props.orderByColumn}
        />
    );
};
