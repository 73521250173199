import React from 'react';
import { CellProps, WithClassname } from '@jsonforms/core';
import merge from 'lodash/merge';
import { Switch } from '@chakra-ui/react';
import { namedMemo } from '@/utils/namedMemo';

export const ChakraSwitch = namedMemo('ChakraSwitch', (props: CellProps & WithClassname) => {
    const { data, className, id, enabled, uischema, path, handleChange, config } = props;
    const appliedUiSchemaOptions = merge({}, config, uischema.options);
    const inputProps = { autoFocus: !!appliedUiSchemaOptions.focus };
    const checked = !!data;

    return (
        <Switch
            isChecked={checked}
            isReadOnly={appliedUiSchemaOptions.readonly}
            onChange={(ev) => handleChange(path, ev.target.checked)}
            className={className}
            id={id}
            disabled={!enabled}
            {...inputProps}
        />
    );
});
