import { InfoIcon } from '@chakra-ui/icons';
import { Box, Tooltip } from '@chakra-ui/react';
import React from 'react';

interface InfoTooltipProps {
    tooltipLabel: string;
    tooltipInfoText: string;
}
export default function InfoTooltip(props: InfoTooltipProps) {
    return (
        <Tooltip
            whiteSpace={'pre-wrap'}
            label={props.tooltipInfoText}
            aria-label={props.tooltipLabel}
            hasArrow
            closeOnClick={false}
        >
            <Box
                as="span"
                style={{
                    padding: 0,
                    margin: 0,
                    marginLeft: '5px',
                    marginRight: '5px',
                }}
                _focus={{
                    outline: '3px dashed #000000',
                    outlineOffset: 2,
                }}
                role={'note'}
                aria-label={`${props.tooltipLabel}: ${props.tooltipInfoText}`}
                tabIndex={0}
            >
                <InfoIcon
                    width={'20px'}
                    height={'20px'}
                    verticalAlign={'middle'}
                    padding={0}
                    color="blue.500"
                    margin={0}
                    aria-hidden={'true'}
                />
            </Box>
        </Tooltip>
    );
}
