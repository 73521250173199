import React from 'react';
import { LabeledSelect } from '@/components/FormInputField/LabeledSelect';
import { Button, ButtonProps, HStack, StackProps, Text, TextProps } from '@chakra-ui/react';

interface Option {
    name?: string;
    id?: number | string;
}

export interface Column {
    label: string;
    optionList?: Option[];
    disabled?: boolean;
    noAll?: boolean;
    selectedValue?: string;
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

interface SupervisionToolBarProps {
    label: string;
    columns: Column[];
    textProps?: TextProps;
    stackProps?: StackProps;
    showApproveButton?: boolean;
    buttonProps?: {
        chakraProps?: ButtonProps;
        label: string;
    };
}

export const SupervisionToolbar = (props: SupervisionToolBarProps) => {
    return (
        <HStack flexWrap="wrap" py={3} gap={3} {...props.stackProps}>
            <Text fontWeight={'bold'} {...props.textProps}>
                {props.label}
            </Text>
            {props.columns.map((column, i) => (
                <LabeledSelect
                    key={i}
                    label={`${column.label}:`}
                    controlProps={{ w: 'unset' }}
                    isDisabled={column.disabled}
                    onChange={(e) => {
                        column.onChange(e);
                    }}
                    value={column.selectedValue}
                >
                    {!column.noAll ? <option value={'All'}>All</option> : null}
                    {column.optionList &&
                        column.optionList.map((option, i) => (
                            <option key={i} value={option.id !== undefined ? option.id : option.name}>
                                {option.name}
                            </option>
                        ))}
                </LabeledSelect>
            ))}
            {props.showApproveButton && <Button {...props.buttonProps?.chakraProps}>{props.buttonProps?.label}</Button>}
        </HStack>
    );
};
