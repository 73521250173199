import { Button, Flex, IconButton } from '@chakra-ui/react';
import React from 'react';
import Icons from '@/components/icons/Icons';
import { LabeledSelect } from '@/components/FormInputField/LabeledSelect';
import { range } from 'lodash';

/**
 * Get up to 10 page numbers, above and below the current page
 * @param pageCount
 * @param currentPage
 */
const getPageNumbers = (pageCount: number, currentPage: number) => {
    let low = 0;
    let high = pageCount;
    if (pageCount > 10) {
        if (currentPage < 6) {
            high = 10;
        } else if (currentPage + 4 >= pageCount) {
            low = high - 10;
        } else {
            low = currentPage - 5;
            high = currentPage + 5;
        }
    }
    return range(low, high);
};

interface PaginationToolbarProps {
    pages: number;
    page: number;
    pageSize: number;
    setNewPage: (newPage: number) => void;
    setPageSize: (newPageSize: number) => void;
    focusPage?: boolean;
    setFocusPage?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PaginationToolbar = (props: PaginationToolbarProps) => {
    return (
        <Flex justifyContent={'space-between'} gridGap={1} overflowX="auto">
            <Flex gridGap={2} alignItems={'center'}>
                <LabeledSelect
                    label="Items Per Page"
                    labelSide="right"
                    value={props.pageSize}
                    onChange={(e) => props.setPageSize(+e.target.value)}
                >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={15}>15</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                </LabeledSelect>
            </Flex>
            <Flex gridGap={1} justifyContent={'flex-end'}>
                <nav aria-label="Pagination">
                    <IconButton
                        aria-label="Previous Page"
                        bg={'gray.100'}
                        disabled={props.page <= 0}
                        _disabled={{
                            color: 'gray.800',
                            bg: 'gray.50',
                            cursor: 'not-allowed',
                        }}
                        _hover={{
                            _disabled: {
                                bg: 'gray.50',
                            },
                            bg: 'gray.200',
                        }}
                        onClick={() => {
                            props.setNewPage(props.page - 1);
                            props.setFocusPage && props.setFocusPage(true);
                        }}
                        icon={<Icons.PointerLeftIcon w={8} h={8} />}
                    />
                    {getPageNumbers(Math.max(1, props.pages), props.page).map((item, i) => {
                        return (
                            <Button
                                key={i}
                                autoFocus={props.focusPage && item === props.page}
                                aria-label={`Page ${item + 1}`}
                                aria-current={item === props.page}
                                bg={item === props.page ? 'blue.500' : 'gray.100'}
                                textColor={item === props.page ? 'gray.100' : 'black'}
                                colorScheme={item === props.page ? 'blue' : 'gray'}
                                fontWeight={'normal'}
                                onClick={() => {
                                    props.setNewPage(item);
                                    props.setFocusPage && props.setFocusPage(true);
                                }}
                            >
                                <span aria-hidden="true">{item + 1}</span>
                            </Button>
                        );
                    })}
                    <IconButton
                        aria-label="Next Page"
                        bg={'gray.100'}
                        isDisabled={props.page >= props.pages - 1}
                        _disabled={{
                            color: 'gray.800',
                            bg: 'gray.50',
                            cursor: 'not-allowed',
                        }}
                        _hover={{
                            _disabled: {
                                bg: 'gray.50',
                            },
                            bg: 'gray.200',
                        }}
                        onClick={() => {
                            props.setNewPage(props.page + 1);
                            props.setFocusPage && props.setFocusPage(true);
                        }}
                        icon={<Icons.PointerRightIcon w={8} h={8} />}
                    />
                </nav>
            </Flex>
        </Flex>
    );
};
