import React, { useRef } from 'react';
import { CellProps, ControlProps, isDateControl, RankedTester, rankWith } from '@jsonforms/core';
import { ChakraInputText } from '../chakra-controls';
import { ChakraFormInput } from '../chakra-controls/ChakraFormInput';
import { withNamedJsonFormsControlProps } from '@/forms-renderers/chakra-ui/utils/withNamedJsonFormsHOC';
import merge from 'lodash/merge';
import { Button, InputGroup, InputRightElement } from '@chakra-ui/react';

const ChakraDateInput = (props: CellProps) => {
    const appliedUiSchemaOptions = merge({}, props.config, props.uischema.options);
    const readonly = appliedUiSchemaOptions.readonly;

    const inputRef = useRef<HTMLInputElement>(null);
    const clearDate = () => {
        props.handleChange(props.path, undefined);
        if (inputRef.current) inputRef.current.focus();
    };

    return (
        <InputGroup>
            <ChakraInputText
                {...props}
                inputProps={{
                    max: appliedUiSchemaOptions.max,
                    min: appliedUiSchemaOptions.min,
                    onKeyDown: (ev) => {
                        if (!readonly && ev.key === 'Delete') {
                            clearDate();
                            ev.stopPropagation();
                        }
                    },
                    ref: inputRef,
                    pr: '5rem',
                }}
            />
            {!readonly && props.data && (
                <InputRightElement p="0.375rem" display="flex" w="auto">
                    <Button h="1.75rem" size="sm" onClick={clearDate}>
                        Clear
                    </Button>
                </InputRightElement>
            )}
        </InputGroup>
    );
};

export const ChakraDateControl = (props: ControlProps) => {
    return <ChakraFormInput {...props} input={ChakraDateInput} />;
};

export const chakraDateControlTester: RankedTester = rankWith(4, isDateControl);

export default withNamedJsonFormsControlProps(ChakraDateControl);
