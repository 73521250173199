import { Box, Flex, Grid } from '@chakra-ui/react';
import React from 'react';
import { BreadCrumbItem } from '@/components/BreadCrumb';
import { WorkplanItemHeader } from './Header/WorkplanItemHeader';
import { Tile } from './Tile';
import { Workplan } from '@/wpt-lib/interfaces/Workplan';

interface FormContainerProps {
    page: boolean;
    children: React.ReactNode;
    breadCrumbItems: BreadCrumbItem[];
    workplan?: Workplan;
}
export const FormContainer = (props: FormContainerProps) => {
    const { page } = props;
    return (
        <>
            {page && (
                <Grid maxH={'auto'} w={'100%'} gap={6} p={6} paddingBottom={0}>
                    <Tile
                        colspan={{
                            sm: 1,
                            lg: 2,
                        }}
                    >
                        <WorkplanItemHeader breadCrumbItems={props.breadCrumbItems} workplan={props.workplan} />
                    </Tile>
                </Grid>
            )}

            <Box p={page ? 6 : 0} h="full" w="full" mt={4}>
                <Flex p={page ? 6 : 0} gridGap={2} bgColor={'offWhite'} rounded="none" flexDirection={'column'}>
                    {props.children}
                </Flex>
            </Box>
        </>
    );
};
