import React, { useState } from 'react';
import { Tile } from '@/components/Tile';
import { Button, FormControl, FormLabel, Input, Stack, Text } from '@chakra-ui/react';
import { usePostRequest } from '@/utils/useStatefulRequests';
import { User } from '@/wpt-lib/interfaces/User';
import { SimpleAlert } from '@/components/SimpleAlert/SimpleAlert';
import { formatName } from '@/wpt-lib/utils/formatName';

const NeoImport = () => {
    const [staffId, setStaffId] = useState<string>();

    const { loading, responseData: user, error, makeRequest } = usePostRequest<User, { staffId: string }>();

    const onChange = (value: string) => {
        setStaffId(value);
    };

    const importUser = () => {
        if (!staffId) return;

        makeRequest('/users/import', {
            staffId,
        });
    };

    return (
        <Tile collapsable colspan={3} heading="User Import" rounded={'lg'} borderWidth={1} borderColor={'gray.100'}>
            <Stack gap={3}>
                <Text>
                    This section allows a user to be imported from NEO. If the user already exists in the system, their
                    user and assignment records will be refreshed.
                </Text>

                <Stack direction="row" justify="flexStart" gap={3} align="center">
                    <FormControl as={Stack} direction="row" alignItems="center" w="fit-content">
                        <FormLabel mb={0}>Staff ID:</FormLabel>
                        <Input type="text" w={60} value={staffId} onChange={(e) => onChange(e.target.value)} />
                    </FormControl>

                    <Button
                        colorScheme="blue"
                        w="fit-content"
                        disabled={!staffId || loading}
                        isLoading={loading}
                        onClick={importUser}
                    >
                        Import
                    </Button>
                </Stack>

                {user && (
                    <Text>
                        Imported {user.staffId} - {formatName(user)}.
                    </Text>
                )}

                {error ? (
                    <SimpleAlert title="Error" variant="subtle">
                        User import failed.
                    </SimpleAlert>
                ) : (
                    <></>
                )}
            </Stack>
        </Tile>
    );
};

export default NeoImport;
