import { BaseFormRequest } from './baseFormRequest';
import { BaseFormResponse } from './baseFormResponse';

export enum GoalType {
    CAREER_GOAL = 'CAREER_GOAL',
    MID_LONG_TERM_GOAL = 'MID_LONG_TERM_GOAL',
    CAREER_PROGRESSION = 'CAREER_PROGRESSION',
    EQUITY_CONSIDERATION = 'EQUITY_CONSIDERATION',
    CAPABILITY_DEVELOPMENT = 'CAPABILITY_DEVELOPMENT',
    TEACHING_AND_EDUCATIONAL_FACILITATION = 'TEACHING_AND_EDUCATIONAL_FACILITATION',
    RESEARCH_AND_SCHOLARSHIP = 'RESEARCH_AND_SCHOLARSHIP',
    SERVICE_AND_ENGAGEMENT = 'SERVICE_AND_ENGAGEMENT',
    LEADERSHIP = 'LEADERSHIP',
    GENERAL = 'GENERAL',
}

export interface PersonalGoalFormResponse extends BaseFormResponse {
    goalName?: string;
    goalType?: string;
}

export interface PersonalGoalWorkplanItemAction {
    goalSummary: string;
}

export type PersonalGoalFormRequest = BaseFormRequest;
