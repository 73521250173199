import saveAs from 'file-saver';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

interface DownloadExcelTableProps {
    page: number;
    orderByColumn: string;
    orderByOrientation: string;
    pageSize: number;
    urlPath: string;
    staffId?: string;
    fileName: string;
    makeExcelRequest: (url: string, config?: AxiosRequestConfig) => Promise<AxiosResponse<Blob>>;
}

export const downloadExcelTables = async (props: DownloadExcelTableProps) => {
    const { page, orderByColumn, orderByOrientation, pageSize, urlPath, staffId, fileName, makeExcelRequest } = props;

    const currentDate = new Date();
    const formatDate = currentDate.toISOString().split('T')[0];
    const formatTime = currentDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }).replace(':', '-');
    try {
        const params = {
            page: page,
            orderByColumn: orderByColumn,
            orderByOrientation: orderByOrientation,
            pageSize: pageSize,
        };
        const workbook = await makeExcelRequest(`excel/${urlPath}`, {
            params,
            responseType: 'blob',
        });

        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        const fileExtension = '.xlsx';
        const blob = new Blob([workbook.data], { type: fileType });
        saveAs(blob, `${formatDate}_${formatTime}_${fileName}_${staffId}` + fileExtension);
    } catch (err) {
        console.log(err);
    }
};
