import { BaseFormResponse } from '@/wpt-lib/interfaces/baseFormResponse';
import { Workplan } from '@/wpt-lib/interfaces/Workplan';
import { Action } from '../pages/dashboard/tiles/bottomTile/bottomTileTables/ListTable';

export type FormType = 'Goal' | 'Activity' | 'Planned Leave' | 'Training' | 'Consideration';

const ReadOnlyActions = {
    Goal: ['view'],
    Activity: ['view'],
    Training: ['view'],
    'Planned Leave': [],
    Consideration: ['view'],
};

const EditableActions = {
    Goal: ['view', 'copy', 'edit', 'delete'],
    Activity: ['view', 'copy', 'edit', 'delete'],
    Training: ['view', 'delete'],
    'Planned Leave': ['copy', 'edit', 'delete'],
    Consideration: ['view', 'copy', 'edit', 'delete'],
};

const ApprovedActions = {
    Goal: ['view', 'copy', 'add'],
    Activity: ['view', 'copy', 'add'],
    Training: ['view', 'add'],
    'Planned Leave': ['copy'],
    Consideration: ['view', 'copy', 'add'],
};

export const defaultActionLists = (
    row: BaseFormResponse,
    defaultActions: Action<BaseFormResponse>[],
    workplan: Workplan,
    typeOfForm: FormType,
    isReadonly: boolean,
) => {
    let actionSet: string[];

    if (isReadonly) {
        actionSet = ReadOnlyActions[typeOfForm];
    } else if (row.workplanItem.approved) {
        actionSet = ApprovedActions[typeOfForm];
    } else {
        actionSet = EditableActions[typeOfForm];
    }

    return defaultActions.filter((a) => actionSet.includes(a.id));
};
