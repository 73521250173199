import * as React from 'react';
import { useState } from 'react';
import { useAssignment } from '@/components/contexts/AssignmentContext/useAssignment';
import { Box, FormControl, FormLabel, Select, Spinner } from '@chakra-ui/react';
import { Workplan, WorkplanState } from '@/wpt-lib/interfaces/Workplan';
import { useGetRequest } from '@/utils/useStatefulRequests';
import { Assignment } from '@/wpt-lib/interfaces/Assignment';
import { useAsyncEffect } from '@/utils/hooks/useAsyncEffect';
import { SimpleAlert } from '@/components/SimpleAlert/SimpleAlert';
import useNavigateOptions from '@/components/contexts/NaviateContext/useNavigateOptions';
import { ListResponse } from '@/wpt-lib/interfaces/listResponse';
import { isWorkplanInState } from '@/utils/isWorkplanReadonly';

interface StaffAssignmentDropdownProps {
    workplan: Workplan;
    readonly?: boolean;
}

const StaffAssignmentDropdown = ({ workplan, readonly }: StaffAssignmentDropdownProps) => {
    const [selectedAssignment, setSelectedAssignment] = useState<number>();
    const { currentAssignment } = useAssignment();

    const { navigatePath, supervisor, admin } = useNavigateOptions();

    const { makeRequest, error, loading, responseData: staffAssignments } = useGetRequest<ListResponse<Assignment>>();

    const fetchStaffAssignments = async () => {
        const user = workplan.assignment?.user?.staffId;
        if (!user) return;

        const resp = await makeRequest(`assignment/filter`, {
            params: {
                assignment: currentAssignment?.id,
                sortedOption: {
                    all: true,
                },
                search: user,
                assignState: 'All',
                year: workplan.year,
            },
        });

        const filteredAssignment = resp.data?.items?.find((a) => a.id === workplan.assignment?.id);
        if (filteredAssignment) {
            setSelectedAssignment(filteredAssignment.id);
        }
    };

    useAsyncEffect(() => {
        void fetchStaffAssignments();
    }, [currentAssignment]);

    if (error) {
        return <SimpleAlert variant="subtle">{`${error}`}</SimpleAlert>;
    }

    const navigateWorkplan = (assignmentId: number) => {
        setSelectedAssignment(assignmentId);

        const workplan = staffAssignments?.items.find((a) => a.id === assignmentId)?.workplans?.[0];
        if (!workplan) return;

        let version = workplan.currentVersion.version;

        if (
            supervisor &&
            isWorkplanInState(
                workplan,
                WorkplanState.DRAFT,
                WorkplanState.IN_PROGRESS,
                WorkplanState.INFORMATION_REQUESTED,
            )
        ) {
            /* Supervisor is not able to view latest version. */
            version -= 1;
        }

        navigatePath(workplan.id, version);
    };

    const accessibleAssignments =
        staffAssignments?.items.filter(
            (assignment) =>
                assignment.workplans &&
                assignment.workplans.length > 0 &&
                (admin ||
                    !!assignment.workplans.find(
                        (w) => w.currentVersion.version > 1 || !isWorkplanInState(w, WorkplanState.DRAFT),
                    )),
        ) ?? [];

    return (
        <Box>
            {loading ? (
                <Spinner size="sm" />
            ) : (
                accessibleAssignments.length > 1 && (
                    <FormControl display="flex" alignItems="baseline" width="unset">
                        <FormLabel fontSize="lg" fontWeight="semibold" textColor="black" whiteSpace="nowrap">
                            Assignment
                        </FormLabel>
                        <Select
                            isDisabled={loading}
                            value={selectedAssignment}
                            onChange={(e) => navigateWorkplan(parseInt(e.target.value))}
                            rounded="none"
                            disabled={readonly}
                            role="listbox"
                        >
                            {accessibleAssignments.map((assignment) => (
                                <option key={assignment.id} value={assignment.id}>
                                    {`${assignment.assignmentNumber} - ${assignment.name}`}
                                </option>
                            ))}
                        </Select>
                    </FormControl>
                )
            )}
        </Box>
    );
};

export default StaffAssignmentDropdown;
