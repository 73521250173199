import { Box, Text } from '@chakra-ui/react';
import { PdfRun } from '@/wpt-lib/interfaces/PdfRun';
import { ListTable } from '@/components/pages/dashboard/tiles/bottomTile/bottomTileTables/ListTable';
import { SimpleAlert } from '@/components/SimpleAlert/SimpleAlert';
import React, { FC } from 'react';
import axios from 'axios';

const uploadedColumns = [
    {
        name: 'Workplan Year',
        getData: (item: PdfRun) => `${item.uploadedWorkplansYear}` ?? '-',
    },
    {
        name: 'Started',
        getData: (item: PdfRun) => `${new Date(item.createdAt).toLocaleString()}`,
    },
    {
        name: 'Completed',
        getData: (item: PdfRun) => `${new Date(item.finishedAt).toLocaleString() ?? '-'}`,
    },
    {
        name: 'In Progress',
        getData: (item: PdfRun) => item.currentJobs,
    },
    {
        name: 'Uploaded',
        getData: (item: PdfRun) => item.totalJobs - item.currentJobs - item.pdfErrors.length,
    },
    {
        name: 'Failed',
        getData: (item: PdfRun) => item.pdfErrors.length,
    },
    {
        name: 'Total',
        getData: (item: PdfRun) => `${item.totalJobs}`,
    },
];

interface PDFUploadsListProps {
    year: string;
    latestRun: PdfRun | undefined;
    error: unknown;
}
const PDFUploadsList: FC<PDFUploadsListProps> = ({ year, latestRun, error }) => {
    return (
        <>
            {latestRun ? (
                <>
                    {error && axios.isAxiosError(error) && error.response?.status !== 404 && (
                        <SimpleAlert title="Error" variant="subtle" width="full">
                            {`Could not retrieve the latest run for the ${year}. Please try again later.`}
                        </SimpleAlert>
                    )}
                    <Box width="full" overflowX={'auto'}>
                        <ListTable tableCaption="" getActions={() => []} columns={uploadedColumns} data={[latestRun]} />
                    </Box>
                </>
            ) : (
                <Text color={'gray.700'} fontStyle="italic">
                    No workplans have been uploaded for year {year} yet.
                </Text>
            )}
        </>
    );
};

export default PDFUploadsList;
