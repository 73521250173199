import React from 'react';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';
import { Link, useLocation } from 'react-router-dom';

export interface BreadCrumbItem {
    label: string;
    path: string;
    isCurrent?: boolean;
}
interface BreadCrumbProps {
    breadCrumbs: BreadCrumbItem[];
    color: string;
}
export const BreadCrumb = (props: BreadCrumbProps) => {
    const location = useLocation();
    return (
        <Breadcrumb color={props.color}>
            {props.breadCrumbs.map((breadCrumb, i) => {
                return (
                    <BreadcrumbItem key={i}>
                        <BreadcrumbLink
                            as={Link}
                            to={breadCrumb.isCurrent ? `${location.pathname}${location.search}` : breadCrumb.path}
                            isCurrentPage={breadCrumb.isCurrent}
                        >
                            {breadCrumb.label}
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                );
            })}
        </Breadcrumb>
    );
};
