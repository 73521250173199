export interface OpenCourse {
    open_course_name: string;
    sessionCode: string;
    industry_partner: string; // unused at the moment, as the response is similar to OpenCourseType
    type: OpenCourseType | null;
}

export enum OpenCourseType {
    // matched case with the Boomi API
    ShortCourseVariant = 'ShortCourseVariant',
    MicroCredentialCourseVariant = 'MicroCredentialCourseVariant',
    TasterCourseVariant = 'TasterCourseVariant',
}
